import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { BiMessage } from "react-icons/bi";
import { MdOutlineMenu } from "react-icons/md";
import { PiSuitcaseLight } from "react-icons/pi";
import { TbMessageCircle } from "react-icons/tb";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  candidateAccept,
  candidateReject,
  checkIfJob,
  candidateSave,
} from "../app/functions.js";
import RejectModal from "../components/modals/RejectModal.jsx";
import { IMAGES } from "../constants/images";
import useNavigateToConversation from "../hooks/useNavigateToConversation.js";

const CandidateCard = ({ matching, data, job, origin, onLike, onMessage }) => {
  const initialData = useSelector((state) => state.initialData);
  const [liked, setLiked] = useState(false);
  const [matched, setMatched] = useState(false);
  const [marked, setMarked] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);

  const { navigateToConversation } = useNavigateToConversation();
  const userData = useSelector((state) => state.user.userData);
  const navigate = useNavigate();

  //TODO: Check if the location and expertise match
  // const isLocationMatch =
  //   Array.isArray(data?.cities) &&
  //   Array.isArray(initialData?.selectedJob.jobCities) &&
  //   initialData.cities.some((loc) => data.jobCities.includes(loc));

  // const isExpertiseMatch =
  //   Array.isArray(data?.expertise) &&
  //   Array.isArray(initialData?.selectedJob.jobExpertise) &&
  //   initialData.selectedJob.jobExpertise.some((expertise) =>
  //     data.expertise.includes(expertise)
  // );

  useEffect(() => {
    if (job) {
      checkIfMatched();

      checkIfMarked();
    }
  }, [job]);

  const checkIfMatched = async () => {
    let ismatched = await checkIfJob(job?.uid, data.uid);
    setMatched(ismatched);
  };
  const checkIfMarked = () => {
    if (data?.saved?.includes(userData?.uid)) {
      setMarked(true);
    }
  };

  const rejectCandidate = () => {
    setShowRejectModal(false);
    candidateReject(
      job.uid,
      data.uid,
      userData.uid,
      data.applicationData.application_id
    );
  };

  const isIncognito = data?.isIncognito ?? true;

  // console.log([isIncognito, data.name, origin]);

  if (data?.rejected?.includes(job?.uid)) {
    return <></>;
  }

  const handleViewCandidate = () => {
    const candidateUid = data.uid;
    navigate(`/candidate-preview/${candidateUid}/${job?.uid ?? ""}`, {
      state: {
        origin: origin,
      },
    });
  };
  return (
    <div className="flex flex-column bg-white rounded-md px-5 py-3 w-100 mb-5 relative text-[#131F2E] ">
      {/* match candidate info */}
      <div>
        <div className="flex items-center gap-x-2 relative">
          <div
            className="flex flex-col md:flex-row items-center w-50 md:w-full gap-2 cursor-pointer "
            onClick={handleViewCandidate}
          >
            {data?.profileImg ? (
              <img
                src={!isIncognito ? data.profileImg : IMAGES.U_ICON}
                className="w-16 h-16 rounded-full border-2 border-green-600"
                alt=""
              />
            ) : (
              <img
                src={IMAGES.U_ICON}
                className="w-16 h-16 rounded-full border-2 border-green-600"
                alt=""
              />
            )}
            <div>
              <p className="font-medium">
                {isIncognito ? (
                  data?.name ? (
                    <>{data.name}</>
                  ) : (
                    <>Anonymous</>
                  )
                ) : (
                  <>
                    {data.name} {data.surname}
                  </>
                )}
              </p>
              <p className="text-xs text-[#808080]">
                {data?.location == "" ? (
                  <>Location not added</>
                ) : (
                  <>{data?.location}</>
                )}
              </p>
            </div>
          </div>
          {/* <div className="flex w-full justify-end absolute top-0 ">
            {!marked ? (
              <>
                <IoBookmarkOutline
                  size={22}
                  className="cursor-pointer "
                  onClick={() => {
                    setMarked(!marked)
                  }}
                />
              </>
            ) : (
              <>
                <IoBookmark
                  size={22}
                  className="cursor-pointer  text-yellow-300"
                  onClick={() => {
                    setMarked(!marked)
                  }}
                />
              </>
            )}
          </div> */}
          {matching && (
            <>
              <div className="flex items-center  justify-center p-2 absolute -top-[60%] -right-[15%] bg-[#FFC83A] rounded-full	  ">
                <img src={IMAGES.MATCH_BLACK} alt="" />
              </div>
            </>
          )}
        </div>
        <div className="mt-3 flex flex-col-reverse md:flex-row  items-center text-[#1B1F87]/50">
          {/* //TODO fix this */}
          {/* <div className="flex flex-col md:flex-row items-center justify-center md:justify-between w-full md:w-50 text-xs ">
            {isLocationMatch && (
              <p className="flex items-center text-xs ">
                <IoMdCheckmark className="mr-1" />
                Location
              </p>
            )}

            {isExpertiseMatch && (
              <p className="flex items-center text-xs ">
                <IoMdCheckmark className="mr-1" />
                Expertise
              </p>
            )}
            <p className="flex items-center text-xs ">
              <IoMdCheckmark className="mr-1" />
              Experience
            </p>
          </div> */}
          <div className="w-full md:w-50 flex justify-center md:justify-end">
            <p className="bg-[#868BFF]/[71%] rounded-full px-3 py-1  text-[#363BB1]/[70%]">
              90% match
            </p>
          </div>
        </div>
      </div>
      {/* match candidate experience */}
      <div className="flex justify-between w-full mt-4 py-3 border-t-2 border-gray-300 h-fit">
        <div>
          <p className="flex flex-col md:flex-row items-center">
            <PiSuitcaseLight className="mr-2" />
            {data?.experience == "" || data?.experience == null ? (
              <span className="text-sm font-semibold">
                Experience not disclosed
              </span>
            ) : (
              <span className="text-sm font-semibold">
                {data?.experience} experience
              </span>
            )}
          </p>
          <p className="flex flex-col md:flex-row items-center">
            <MdOutlineMenu className="mr-2" />
            {data?.expertise == "" || data?.expertise == null ? (
              <span className="text-sm font-semibold">
                Expertise not disclosed
              </span>
            ) : (
              <span className="text-sm font-semibold">{data?.expertise} </span>
            )}
          </p>
        </div>

        <div>
          <p className="flex flex-col md:flex-row items-center">
            <BiMessage className="mr-2" />
            {data?.selectedLanguages == "" ||
            data?.selectedLanguages == null ? (
              <span className="text-sm font-semibold">
                No language specified
              </span>
            ) : (
              <span className="text-sm font-semibold">
                {data?.selectedLanguages?.join(", ")}
              </span>
            )}
          </p>
        </div>
      </div>
      {/* match candidate desc */}
      <div className="flex flex-column justify-center items-center w-full gap-y-2 mb-3 ">
        <div className="overflow-hidden max-w-full w-full">
          <p className="text-justify overflow-hidden overflow-ellipsis  h-[100px] text-[131F2E]/70 ">
            {data?.description}
          </p>
        </div>

        <div className="flex items-center justify-center gap-x-4 w-full md:w-[50%] h-10">
          {data?.applicationData?.cv !== "" &&
            (origin == "matches" || origin == "applicants") && (
              <a
                href={
                  data.applicationData.cv ? data.applicationData.cv : undefined
                }
                target="_blank"
                className="flex flex-column items-center justify-center cursor-pointer  px-4 py-1  bg-[#D9D9D9]/[40%]  rounded-md mx-2"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                >
                  <path
                    d="M32.5 2.5H7.5C6.80859 2.5 6.25 3.05859 6.25 3.75V36.25C6.25 36.9414 6.80859 37.5 7.5 37.5H32.5C33.1914 37.5 33.75 36.9414 33.75 36.25V3.75C33.75 3.05859 33.1914 2.5 32.5 2.5ZM22.3438 5.3125H26.0938V13.5117L24.2773 12.1875L22.3438 13.5703V5.3125ZM9.0625 34.6875V5.3125H20V16.9102C20 17.0391 20.0391 17.168 20.1172 17.2734C20.1646 17.3405 20.2249 17.3974 20.2944 17.4411C20.364 17.4847 20.4416 17.514 20.5226 17.5275C20.6036 17.5409 20.6865 17.5382 20.7664 17.5194C20.8463 17.5006 20.9217 17.4661 20.9883 17.418L24.2617 15.0781L27.4414 17.3984C27.5469 17.4766 27.6758 17.5195 27.8086 17.5195C28.1523 17.5195 28.4336 17.2383 28.4336 16.8945V5.3125H30.9336V34.6875H9.0625Z"
                    fill="#8689D0"
                  />
                  <path
                    d="M26.0937 13.5117V5.3125H22.3437V13.5703L24.2773 12.1875L26.0937 13.5117Z"
                    fill="#E6F7FF"
                  />
                  <path
                    d="M28.4336 5.3125V16.8945C28.4336 17.2383 28.1523 17.5195 27.8086 17.5195C27.6758 17.5195 27.5469 17.4766 27.4414 17.3984L24.2617 15.0781L20.9883 17.418C20.9217 17.4661 20.8463 17.5006 20.7664 17.5194C20.6865 17.5382 20.6036 17.5409 20.5226 17.5275C20.4416 17.514 20.364 17.4847 20.2944 17.4411C20.2249 17.3974 20.1646 17.3405 20.1172 17.2734C20.0391 17.168 20 17.0391 20 16.9102V5.3125H9.0625V34.6875H30.9336V5.3125H28.4336Z"
                    fill="#E6F7FF"
                  />
                </svg>
                <div className="justify-center flex-column column items-center">
                  <span className="text-xs text-[#363BB199] font-bold">CV</span>
                  <span className="text-xs text-[#363BB199] font-bold">
                    &nbsp;
                  </span>
                </div>
              </a>
            )}
          {data.applicationData?.coverLetter !== "" &&
            (origin == "matches" || origin == "applicants") && (
              <a
                href={
                  data.applicationData.coverLetter
                    ? data.applicationData.coverLetter
                    : undefined
                }
                target="_blank"
                className="flex flex-column items-center justify-center cursor-pointer  px-4 py-1  bg-[#D9D9D9]/[40%]  rounded-md mx-2"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="43"
                  height="43"
                  viewBox="0 0 43 43"
                  fill="none"
                >
                  <path
                    d="M22.4238 14.7812V5.71094H9.74219V37.2891H33.2578V16.5449H24.1875C23.7197 16.5449 23.2711 16.3591 22.9404 16.0284C22.6096 15.6976 22.4238 15.249 22.4238 14.7812ZM21.5 28.3027C21.5 28.4875 21.3488 28.6387 21.1641 28.6387H13.4375C13.2527 28.6387 13.1016 28.4875 13.1016 28.3027V26.2871C13.1016 26.1023 13.2527 25.9512 13.4375 25.9512H21.1641C21.3488 25.9512 21.5 26.1023 21.5 26.2871V28.3027ZM29.8984 20.5762V22.5918C29.8984 22.7766 29.7473 22.9277 29.5625 22.9277H13.4375C13.2527 22.9277 13.1016 22.7766 13.1016 22.5918V20.5762C13.1016 20.3914 13.2527 20.2402 13.4375 20.2402H29.5625C29.7473 20.2402 29.8984 20.3914 29.8984 20.5762Z"
                    fill="#E6F7FF"
                  />
                  <path
                    d="M35.8865 12.1189L26.8498 3.08223C26.5979 2.83027 26.2577 2.6875 25.9008 2.6875H8.0625C7.31924 2.6875 6.71875 3.28799 6.71875 4.03125V38.9688C6.71875 39.712 7.31924 40.3125 8.0625 40.3125H34.9375C35.6808 40.3125 36.2812 39.712 36.2812 38.9688V13.0722C36.2812 12.7152 36.1385 12.3709 35.8865 12.1189ZM25.2793 5.78652L33.1822 13.6895H25.2793V5.78652ZM33.2578 37.2891H9.74219V5.71094H22.4238V14.7812C22.4238 15.249 22.6096 15.6976 22.9404 16.0284C23.2711 16.3591 23.7197 16.5449 24.1875 16.5449H33.2578V37.2891Z"
                    fill="#8689D0"
                  />
                  <path
                    d="M13.1016 20.5762V22.5918C13.1016 22.7766 13.2527 22.9277 13.4375 22.9277H29.5625C29.7473 22.9277 29.8984 22.7766 29.8984 22.5918V20.5762C29.8984 20.3914 29.7473 20.2402 29.5625 20.2402H13.4375C13.2527 20.2402 13.1016 20.3914 13.1016 20.5762ZM21.1641 25.9512H13.4375C13.2527 25.9512 13.1016 26.1023 13.1016 26.2871V28.3027C13.1016 28.4875 13.2527 28.6387 13.4375 28.6387H21.1641C21.3488 28.6387 21.5 28.4875 21.5 28.3027V26.2871C21.5 26.1023 21.3488 25.9512 21.1641 25.9512Z"
                    fill="#8689D0"
                  />
                </svg>
                <div className="justify-center flex-column column items-center">
                  <span className="text-xs text-[#363BB199] font-bold">
                    Cover
                  </span>
                  <span className="text-xs text-[#363BB199] font-bold">
                    Letter
                  </span>
                </div>
              </a>
            )}
        </div>

        {/* {!matched && !liked && !rejected && (
          <div className="flex items-center justify-between mt-4 gap-x-3 w-full">
            <Button
              size="sm"
              className="flex items-center gap-x-2 bg-[#ff787863] border-1 border-[#ff7878] text-[#ff7878] px-3 cursor-pointer hover:bg-transparent hover:border-[#ff7878] hover:text-[#ff7878] "
              onClick={() => setShowRejectModal(true)}
            >
              <img src={IMAGES.RED_CROSS} width={14} height={17} alt="" />
              Reject
            </Button>
            <div
              className="flex items-center justify-center text-[#131F2E]/60"
              onClick={() => {
                if (onMessage) {
                  onMessage(data.uid)
                } else {
                  navigateToConversation(data.uid, job.uid)
                }
              }}
            >
              <p className="flex items-center mb-1 cursor-pointer">
                <TbMessageCircle size={20} className="mr-2" />
                Ask a question
              </p>
            </div>
            {(liked || matched) && (
              <>
                <br />
                Candidate Liked
                <div onClick={() => navigateToConversation(data.uid, job.uid)}>
                  Message candidate
                </div>
              </>
            )}
            <Button
              size="sm"
              className="flex items-center gap-x-2 bg-[#cfefca] border-1 border-[#81d674] text-[#81d674] px-3 cursor-pointer hover:bg-transparent hover:border-[#81d674] hover:text-[#81d674] "
              onClick={() => {
                if (origin !== "candidateboard") {
                  candidateAccept(job.uid, data.uid)
                  setLiked(true)
                }

                onLike && onLike()
              }}
            >
              <img src={IMAGES.GREEN_HEART} width={14} height={17} alt="" />
              Like
            </Button>
          </div>
        )} */}
        {/* <div
          className="flex mt-3 items-center justify-center"
          onClick={() => {
            if (onMessage) {
              onMessage(data.uid)
            } else {
              navigateToConversation(data.uid, job.uid)
            }
          }}
        >
          <p className="flex items-center mb-1 cursor-pointer">
            <TbMessageCircle size={20} className="mr-2" />
            Ask a question
          </p>
        </div>
        {(liked || matched) && (
          <>
            <br />
            Candidate Liked
            <div onClick={() => navigateToConversation(data.uid, job.uid)}>
              Message candidate
            </div>
          </>
        )} */}
      </div>
      {!matched && !liked && !data?.applicationData?.accepted && (
        <div className="flex flex-col-reverse md:flex-row items-center justify-between mt-4 gap-x-3 gap-y-2 w-full">
          {origin == "applicants" && (
            <Button
              size="sm"
              className="flex items-center gap-x-2 bg-[#ff787863] border-1 border-[#ff7878] text-[#ff7878] px-3 cursor-pointer hover:bg-transparent hover:border-[#ff7878] hover:text-[#ff7878] "
              onClick={() => setShowRejectModal(true)}
            >
              <img src={IMAGES.RED_CROSS} width={14} height={17} alt="" />
              Reject
            </Button>
          )}
          <div
            className="flex items-center justify-center text-[#131F2E]/60"
            onClick={() => {
              if (onMessage) {
                onMessage(data.uid);
              } else {
                navigateToConversation(data.uid, job.uid);
              }
            }}
          >
            <p className="flex items-center mb-1 cursor-pointer hover:font-bold hover:text-black">
              <TbMessageCircle size={20} className="mr-2" />
              Ask a question
            </p>
          </div>
          {(liked || matched) && (
            <>
              <br />
              Candidate Liked
              <div onClick={() => navigateToConversation(data.uid, job.uid)}>
                Message candidate
              </div>
            </>
          )}
          <Button
            size="sm"
            className="flex items-center gap-x-2 bg-[#cfefca] border-1 border-[#81d674] text-[#81d674] px-3 cursor-pointer hover:bg-transparent hover:border-[#81d674] hover:text-[#81d674] "
            onClick={() => {
              if (origin === "applicants") {
                candidateAccept(job.uid, data.uid);
                setLiked(true);
              } else if (origin !== "candidateboard") {
                candidateSave(userData.uid, data.uid, job.uid);
                setMarked(!marked);
              }

              onLike && onLike();
            }}
          >
            <img src={IMAGES.GREEN_HEART} width={14} height={17} alt="" />
            {origin == "applicants" ? "Accept" : marked ? "Liked" : "Like"}
          </Button>
        </div>
      )}

      {origin == "matches" && (
        <div
          className="flex items-center justify-center text-[#3BAF4F] font-bold  "
          onClick={() => {
            if (onMessage) {
              onMessage(data.uid);
            } else {
              navigateToConversation(data.uid, job.uid);
            }
          }}
        >
          <div className="rounded bg-[#81D67461] px-3 py-1 border-solid border-[#3BAF4F] border-1 hover:bg-[#81D67499]">
            <p className="flex items-center mb-1 cursor-pointer">
              <TbMessageCircle size={20} className="mr-2" />
              Message
            </p>
          </div>
        </div>
      )}

      {/* Render the RejectModal component */}
      <RejectModal
        show={showRejectModal}
        onHide={() => setShowRejectModal(false)}
        onReject={() => {
          rejectCandidate();
          console.log(data.applicationData);
        }}
      />
    </div>
  );
};

export default CandidateCard;
