import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import React, { useState } from "react";
import { AiOutlineUpload } from "react-icons/ai";
import { FiChevronLeft } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CreatableSelect from "react-select/creatable";
import Switch from "react-switch";
import { updateInitialData } from "../../app/initialDataSlice";
import { storage } from "../../config/firebase.js";
import { llmOptions } from "../../constants/Data.js";
import { IMAGES } from "../../constants/images.jsx";
import styles from "./CandidateData.module.css";

const button_text = "Next";
const button_back_text = "Back";

const grades = ["50-65%", "65-75%", "75-85%", "85-100%"];

const CandidateData1 = () => {
  const dispatch = useDispatch();
  const initialData = useSelector((state) => state.initialData);

  const [isEducationNotListed, setIsEducationNotListed] = useState(false);

  const [cv, setCv] = useState("");
  const [coverLetter, setCoverLetter] = useState("");
  const [transcripts, setTranscripts] = useState("");

  const [percentCV, setPercentCV] = useState(0);
  const [percentCL, setPercentCL] = useState(0);
  const [percentT, setPercentT] = useState(0);

  const [fileReady, setFileReady] = useState(true);
  const [filePicked, setIsFilePicked] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(false);

  // Handle file upload event and update state
  const handleChangeCV = (event) => {
    // setCv(event.target.files[0])

    setFileReady(false);
    const file = event.target.files[0];
    if (!file) {
      return;
    } else {
      if (event.target.files[0].size > 10485760) {
        alert("The file is too big! Compress it below 10MB");
      } else {
        // setCv(event.target.files[0])
        setIsFilePicked(true);
        const storageRef = ref(
          storage,
          `cvs/${event.target.files[0].size}${file.name}`
        );
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            console.log(progress);
            setPercentCV(progress);
          },
          (error) => {
            alert(error);
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              setUploadedFile(downloadURL);
              setCv(downloadURL);
              dispatch(updateInitialData({ cv: downloadURL }));
              setFileReady(true);
            });
          }
        );
      }
    }
  };
  const handleChangeCL = (event) => {
    // setCoverLetter(event.target.files[0])

    setFileReady(false);
    const file = event.target.files[0];
    if (!file) {
      return;
    } else {
      if (event.target.files[0].size > 10485760) {
        alert("The file is too big! Compress it below 10MB");
      } else {
        // setCoverLetter(event.target.files[0])
        setIsFilePicked(true);
        const storageRef = ref(
          storage,
          `coverLetters/${event.target.files[0].size}${file.name}`
        );
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            console.log("PRogress" + progress);
            setPercentCL(progress);
          },
          (error) => {
            alert(error);
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              setUploadedFile(downloadURL);
              setCoverLetter(downloadURL);
              dispatch(updateInitialData({ coverLetter: downloadURL }));
              setFileReady(true);
            });
          }
        );
      }
    }
  };
  const handleChangeTranscripts = (event) => {
    // setTranscripts(event.target.files[0])
    setFileReady(false);
    const file = event.target.files[0];
    if (!file) {
      return;
    } else {
      if (event.target.files[0].size > 10485760) {
        alert("The file is too big! Compress it below 10MB");
      } else {
        // setTranscripts(event.target.files[0])
        setIsFilePicked(true);
        const storageRef = ref(
          storage,
          `transcripts/${event.target.files[0].size}${file.name}`
        );
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            // console.log(transcripts)
            setPercentT(progress);
          },
          (error) => {
            alert(error);
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              setUploadedFile(downloadURL);
              setTranscripts(downloadURL);
              dispatch(updateInitialData({ transcripts: downloadURL }));
              setFileReady(true);
            });
          }
        );
      }
    }
  };
  const [activeButton, setActiveButton] = useState("");

  const handleBtnClick = (btn) => {
    setActiveButton(btn);
  };

  const nextWindow = (e) => {
    navigate("/candidatedata2");
  };

  // const getLlmList = (llmOptions) => {
  //   const arrayOfLabels = llmOptions.map((obj) => obj.label)
  //   dispatch(updateInitialData({ master: arrayOfLabels }))
  // }
  const handleChangeLlmList = (selectedOptions) => {
    const newLlmList = selectedOptions
      ? selectedOptions.map((option) => option.label)
      : [];
    dispatch(updateInitialData({ master: newLlmList }));
  };

  // co

  // console.log(llmList)
  const handleEducationNotListedChange = (checked) => {
    setIsEducationNotListed(checked);
  };
  const previousWindow = () => {
    // editData(e)
    navigate("/candidatedata0");
  };

  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <div
      // className="overflow-y-auto"
      >
        {/* Box Container */}
        <div className={styles.boxcontainer}>
          <img
            src={IMAGES.JLFULL}
            className="w-18 h-10 mb-0"
            alt="JustLawyers logo"
          />

          <h3 className="w-[30%] text-left font-bold uppercase text-[#738397]">
            Candidate
          </h3>

          {/* ------------------------ Education Switch ------------------------*/}
          <div className={styles.innercontainer}>
            <p className={styles.p}>Education</p>
            <p className={styles.textreg}>
              Please provide your most relevant education.
            </p>
            <div className="w-full h-[1px] bg-black mb-3"></div>
            <div className={styles.switchContainer}>
              <p className={styles.textreg} style={{ marginRight: 15 }}>
                My education is not on the list.
              </p>

              <Switch
                onChange={handleEducationNotListedChange}
                checked={isEducationNotListed}
                onColor="#86d3ff"
                onHandleColor="#2693e6"
                handleDiameter={15}
                uncheckedIcon={false}
                checkedIcon={false}
                height={15}
                width={40}
              />
            </div>

            {/* ------------------------ University ------------------------*/}
            {!isEducationNotListed && (
              <div className="w-full mt-2">
                <label className="font-bold">University</label>
                <CreatableSelect
                  isClearable
                  placeholder="Select university..."
                  className="basic-single"
                  classNamePrefix="select"
                  defaultValue={""}
                  name="university"
                  // options={unisOptions}
                  options={llmOptions}
                  // onChange={changeUniversity}
                  // value={initialData.university?.map((university) => ({
                  //   label: university,
                  //   value: university,
                  // }))}
                  onChange={(selectedOption) => {
                    const newUniversity = selectedOption
                      ? selectedOption.label
                      : "";
                    dispatch(updateInitialData({ university: newUniversity }));
                  }}
                  value={
                    initialData.university
                      ? {
                          label: initialData.university,
                          value: initialData.university,
                        }
                      : null
                  }
                />
              </div>
            )}

            {/* ------------------------ Average Grades ------------------------*/}

            <p className="font-semibold mb-2">Average Grades</p>
            <div className="flex justify-between w-full my-2">
              {grades.map((grade, index) => (
                <button
                  key={`btn${index + 1}`}
                  className={
                    initialData.grades === grade
                      ? "rounded-full border-1 border-gray-200 px-3 py-2 bg-[#46D6EA]"
                      : "rounded-full border-1 border-gray-200 px-3 py-2 hover:bg-[#46D6EA]"
                  }
                  onClick={(e) => {
                    // handleBtnClick(`btn${index + 1}`);
                    dispatch(updateInitialData({ grades: grade }));
                    // handleBtnData(e);
                  }}
                  value={grade}
                  id={`btn${index + 1}`}
                >
                  {grade}
                </button>
              ))}
            </div>

            {/* ------------------------ Date of graduation ------------------------*/}
            <p className="font-semibold mb-2">Date of graduation</p>

            <input
              type="date"
              className={styles.input}
              onChange={(e) =>
                dispatch(
                  updateInitialData({ dateOfGradutation: e.target.value })
                )
              }
              value={initialData.dateOfGradutation}
            />

            {/* ------------------------ Master of Laws (LL.M.) ------------------------*/}

            <div className="w-full mt-2">
              <label className="font-semibold mb-2">Master of law (LLM)</label>
              <CreatableSelect
                isClearable
                placeholder="Select LLM..."
                defaultValue={[]}
                isMulti
                name="university"
                options={llmOptions}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={handleChangeLlmList}
                value={initialData.master?.map((university) => ({
                  label: university,
                  value: university,
                }))}
              />
            </div>
          
          {/* ------------------------ (Expected) Date of graduation ------------------------*/}
          <p className={styles.p}>Date of graduation (LLM) </p>

          <input
            type="date"
            className={styles.input}
            onChange={(e) =>
              dispatch(updateInitialData({ dateOfGradutationLLM: e.target.value }))
            }
            value={initialData.dateOfGradutationLLM}
          />


            {/* ------------------------ Attachements ------------------------*/}
            <p className="font-semibold mb-1 mt-4">Attachements</p>
            <p className={styles.textreg}>
              Please provide your current or most relevant application
              documents.
            </p>
            {/* cv */}
            <div className="flex justify-between w-100 my-2">
              <label
                for="cv"
                className="flex items-center justify-center bg-[#D8E0EB] rounded-md text-black py-2 cursor-pointer w-100"
                onClick={() => {
                  console.log("load");
                }}
              >
                {/* <img src={download_icon} className="mr-3" /> */}
                <AiOutlineUpload size={22} className="mr-3" />
                Click to upload CV | {percentCV} "% done"
              </label>
              <input
                id="cv"
                type="file"
                name="cv"
                className="invisible w-[10px]"
                onChange={handleChangeCV}
                accept=".pdf, .txt, .doc, .docx"
              />
            </div>
            {/* cover letter */}
            <div className="flex justify-between w-100 my-2">
              <label
                for="coverLetter"
                className="flex items-center justify-center bg-[#D8E0EB] rounded-md text-black py-2 cursor-pointer w-100"
              >
                {/* <img src={download_icon} className="mr-3" /> */}
                <AiOutlineUpload size={22} className="mr-3" />
                Click to Upload Cover Letter | {percentCL} "% done"
              </label>

              <input
                id="coverLetter"
                type="file"
                className="invisible w-[10px]"
                onChange={handleChangeCL}
                accept=".pdf, .txt, .doc, .docx"
              />
            </div>
            {/* transcript */}
            <div className="flex justify-between w-100 my-2">
              <label
                for="transcripts"
                className="flex items-center justify-center bg-[#D8E0EB] rounded-md text-black py-2 cursor-pointer w-100"
              >
                {/* <img src={download_icon} className="mr-3" /> */}
                <AiOutlineUpload size={22} className="mr-3" />
                Click to Upload Transcripts | {percentT} "% done"
              </label>
              <input
                id="transcripts"
                type="file"
                className="invisible w-[10px]"
                onChange={handleChangeTranscripts}
                accept=".pdf, .txt, .doc, .docx"
              />
            </div>
            {/* ------------------------ Back / Next ------------------------*/}
            <div className={styles.bottombar}>
              {/* ------------------------ Back ------------------------*/}

              <button
                className={styles.buttonback}
                onClick={(e) => previousWindow(e)}
              >
                <FiChevronLeft size={22} className="" />

                {button_back_text}
              </button>
              <p className="flex-1 text-center">2/3</p>
              {/* ------------------------ Next ------------------------*/}
              <div className="flex flex-1 items-center justify-between">
                <button
                  className={styles.buttonskip}
                  onClick={(e) => nextWindow(e)}
                >
                  skip
                </button>
                {/* ------------------------ Next ------------------------*/}
                <button
                  className={styles.buttonnext}
                  onClick={(e) => nextWindow(e)}
                >
                  {button_text}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CandidateData1;
