import { createUserWithEmailAndPassword } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { v4 as uuidv4 } from "uuid";
import { auth, db, storage, signOut } from "../config/firebase.js";
import { store } from "./store.js";
import { initialise, login } from "./userRedux/userSlice.js";
import app from "../config/axiosConfig";

export const APP_URL = import.meta.env.VITE_API_URL;

//TODO - change name
export const signInOrLogin = async (user, dispatch, saveData) => {
  try {
    const response = await app.post("signin-or-login", user);

    if (response.status < 200 || response.status > 299) {
      throw new Error("Network response was not ok");
    }

    const data = response.data;
    dispatch(saveData(data));
  } catch (error) {
    console.log(error);
    alert(error.message);
  }
};

export const signInOrLoginEmployer = async (employer, dispatch, saveData) => {
  try {
    const response = await app.post("signin-or-login-employer", { employer });

    if (response.status < 200 || response.status > 299) {
      throw new Error("Network response was not ok");
    }

    const data = response.data;
    dispatch(saveData(data));
  } catch (error) {
    console.log(error);
    alert(error.message);
  }
};

export const fetchFilteredCandidates = async (filters = {}) => {
  const { jobCities = [], jobExpertise = [], jobLanguages = [] } = filters;

  try {
    const response = await app.post("fetch-filtered-candidates", {
      jobCities,
      jobExpertise,
      jobLanguages,
    });

    if (response.status < 200 || response.status > 299) {
      throw new Error("Network response was not ok");
    }

    const data = response.data;
    console.log("Filtered candidates fetched:", data);
    return data;
  } catch (error) {
    console.error("Error fetching filtered candidates:", error);
    throw error;
  }
};

export const fetchFilteredJobs = async (filters = {}) => {
  const { jobTitle, jobCities, jobExpertise, jobLanguages } = filters;

  try {
    const response = await app.post("fetch-filtered-jobs", {
      jobTitle,
      jobCities,
      jobExpertise,
      jobLanguages,
    });

    if (response.status < 200 || response.status > 299) {
      throw new Error("Network response was not ok");
    }

    const data = response.data;
    console.log("Filtered jobs fetched:", data);
    return data;
  } catch (error) {
    console.error("Error fetching filtered jobs:", error);
    throw error;
  }
};

export const fetchAppliedJobs = async (type, user_id) => {
  try {
    const response = await app.post("fetch-applied-job", { type, user_id });

    if (response.status < 200 || response.status > 299) {
      throw new Error("Network response was not ok");
    }

    const data = response.data;
    console.log("Applied jobs fetched:", data);
    return data;
  } catch (error) {
    console.error("Error fetching applied jobs:", error);
    throw error;
  }
};

export const fetchSavedOrRejectedJobs = async (user_id, type) => {
  try {
    const response = await app.post("fetch-saved-or-rejected-jobs", {
      user_id,
      type,
    });

    if (response.status < 200 || response.status > 299) {
      throw new Error("Network response was not ok");
    }

    const data = response.data;
    console.log("Saved or rejected jobs fetched:", data);
    return data;
  } catch (error) {
    console.error("Error fetching saved or rejected jobs:", error);
    throw error;
  }
};

export const fetchSavedCandidates = async (user_id, type) => {
  try {
    const response = await app.post("fetch-saved-candidates", {
      user_id,
      type,
    });

    if (response.status < 200 || response.status > 299) {
      throw new Error("Network response was not ok");
    }

    const data = response.data;
    return data;
  } catch (error) {
    console.error("Error fetching saved candidates:", error);
    throw error;
  }
};

export const initaliseUser = async () => {
  try {
    const data = await fetchUserData();
    if (data != null) {
      const { authData, userData } = data;
      store.dispatch(initialise({ authData, userData }));
    } else {
      store.dispatch(initialise());
    }
  } catch (error) {
    store.dispatch(initialise());
  }
};

export const registerEmployer = async (email, password, employerData) => {
  //TODO - move it to the backend
  const credentials = await createUserWithEmailAndPassword(
    auth,
    email,
    password
  );

  const firmLogo = employerData?.firmLogo[0] ?? null;

  let logoUrl = null;

  if (firmLogo != null) {
    const storageRef = ref(
      storage,
      `/firmLogos/${firmLogo.size}${firmLogo.name}`
    );

    await uploadBytes(storageRef, firmLogo);

    logoUrl = await getDownloadURL(storageRef);
  }

  const employer = {
    name: employerData.firstName,
    email: email,
    surname: employerData.lastName,
    phone: employerData.phoneNumber,
    role: employerData.role,
    companyName: employerData.firmName,
    firmFounded: employerData.firmFoundedYear,
    firmLogo: logoUrl,
    firmLocation: employerData.firmPrimaryLocation,
    selectedLocations: employerData.firmOtherLocations,
    firmWebsite: employerData.firmUrl,
    firmSize: employerData.firmSize,
    aboutCompany: employerData.firmDescription,
    isEmployer: true,
    uid: credentials.user.uid,
  };

  await setDoc(doc(db, "employers", credentials.user.uid), employer);

  //we need to sign out the user after registration because createUserWithEmailAndPassword automatically signs in the user
  signOut(auth);

  return employer;
};

export const verifyEmployer = async (employerData) => {
  try {
    const response = await app.post("api/verify-employer", {
      employerData,
    });

    if (response.status < 200 || response.status > 299) {
      throw new Error("Network response was not ok");
    }
  } catch (error) {
    console.error("Error verifying employer:", error);
    throw error;
  }
};

const fetchUserData = async () => {
  const response = await app.get("users/me");
  return response.data;
};

export const editUser = async (user, dispatch, saveData) => {
  try {
    const response = await app.post("edit-user", { user });

    if (response.status < 200 || response.status > 299) {
      throw new Error("Network response was not ok");
    }

    const updatedUser = response.data;
    dispatch(saveData(updatedUser));

    console.log("User edited successfully");
  } catch (error) {
    console.error("Error editing user:", error);
    throw error;
  }
};

export const createJob = async (jobData) => {
  try {
    const employerData = store.getState().data.value;
    const user = store.getState().user.userData;

    const response = await app.post("create-job", {
      jobData,
      user,
      employerData,
    });

    if (response.status < 200 || response.status > 299) {
      throw new Error("Network response was not ok");
    }
  } catch (error) {
    console.error("Error creating job:", error);
    throw error;
  }
};

export const fetchMyJobs = async (user_id) => {
  try {
    const response = await app.post("fetch-my-jobs", {
      user_id,
    });

    if (response.status < 200 || response.status > 299) {
      throw new Error("Network response was not ok");
    }

    const myJobs = response.data;
    return myJobs;
  } catch (error) {
    console.error("Error fetching user's jobs:", error);
    throw error;
  }
};

export const editJob = async (job) => {
  try {
    const response = await app.post("edit-job", {
      job,
    });

    if (response.status < 200 || response.status > 299) {
      throw new Error("Network response was not ok");
    }

    console.log("Job edited successfully");
  } catch (error) {
    console.error("Error editing job:", error);
    throw error;
  }
};

export const jobApply = async (
  job_id,
  user_id,
  cv,
  coverLetter,
  transcripts,
  employer_id
) => {
  try {
    const response = await app.post("job-apply", {
      job_id,
      user_id,
      cv,
      coverLetter,
      transcripts,
      employer_id,
    });

    if (response.status < 200 || response.status > 299) {
      throw new Error("Network response was not ok");
    }
    sendNotification(employer_id, job_id, "APPLIED");
  } catch (error) {
    console.error("Error applying for job:", error);
    throw new Error("Error applying for job");
  }
};

export const jobSave = async (job_id, user_id) => {
  try {
    const response = await app.post("job-save", {
      job_id,
      user_id,
    });

    if (response.status < 200 || response.status > 299) {
      throw new Error("Network response was not ok");
    }
    // Handle success if needed
  } catch (error) {
    console.error("Error saving job:", error);
    throw new Error("Error saving job");
  }
};

export const candidateSave = async (employer_id, candidate_id, job_id) => {
  try {
    const response = await app.post("candidate-save", {
      employer_id,
      candidate_id,
      job_id,
    });

    if (response.status < 200 || response.status > 299) {
      throw new Error("Network response was not ok");
    }
    // Handle success if needed
  } catch (error) {
    console.error("Error saving candidate:", error);
    throw new Error("Error saving candidate");
  }
};

export const jobReject = async (job_id, user_id) => {
  try {
    const response = await app.post("job-reject", { job_id, user_id });
    if (response.status < 200 || response.status > 299) {
      throw new Error("Network response was not ok");
    }
    // Handle success if needed
  } catch (error) {
    console.error("Error rejecting job:", error);
    throw new Error("Error rejecting job");
  }
};

export const candidateReject = async (
  job_id,
  user_id,
  employer_id,
  application_id
) => {
  try {
    const response = await app.post("candidate-reject", {
      job_id,
      user_id,
      employer_id,
      application_id,
    });

    if (response.status < 200 || response.status > 299) {
      throw new Error("Network response was not ok");
    }
    sendNotification(user_id, job_id, "REJECTED_CANDIDATE");
  } catch (error) {
    console.error("Error rejecting candidate:", error);
    throw new Error("Error rejecting candidate");
  }
};

export const candidateAccept = async (job_id, user_id) => {
  try {
    const response = await app.post("candidate-accept", {
      job_id,
      user_id,
    });
    if (response.status < 200 || response.status > 299) {
      throw new Error("Network response was not ok");
    }

    sendNotification(user_id, job_id, "MATCHED");
  } catch (error) {
    console.error("Error accepting candidate:", error);
    throw new Error("Error accepting candidate");
  }
};

export const fetchApplicantsJobs = async (job_id, type) => {
  try {
    const response = await app.post("fetch-applicants", {
      job_id,
      type,
    });

    if (response.status < 200 || response.status > 299) {
      throw new Error("Network response was not ok");
    }
    const userDataArray = response.data;
    return userDataArray;
  } catch (error) {
    console.error("Error fetching applicants for job:", error);
    throw new Error("Error fetching applicants for job");
  }
};

export const checkJobStatus = async (job_id, user_id, collection_name) => {
  try {
    const response = await app.post("check-job-status", {
      job_id,
      user_id,
      collection_name,
    });

    if (response.status < 200 || response.status > 299) {
      throw new Error("Network response was not ok");
    }
    const jobStatus = response.data;
    return jobStatus;
  } catch (error) {
    console.error("Error checking job status:", error);
    throw new Error("Error checking job status");
  }
};
export const getPotentialCandidates = async () => {
  try {
    const response = await app.post("get-potential-candidates", {});

    if (response.status < 200 || response.status > 299) {
      throw new Error("Network response was not ok");
    }
    const candidatesArray = response.data;
    return candidatesArray;
  } catch (error) {
    console.error("Error fetching potential candidates:", error);
    throw new Error("Error fetching potential candidates");
  }
};

export const checkIfJob = async (job_id, candidate_id) => {
  try {
    const response = await app.post("check-if-job", { job_id, candidate_id });

    if (response.status < 200 || response.status > 299) {
      throw new Error("Network response was not ok");
    }
    const jobExists = response.data;
    return jobExists;
  } catch (error) {
    console.error("Error checking if job exists:", error);
    throw new Error("Error checking if job exists");
  }
};

export const fetchUsers = async () => {
  try {
    const response = await app.post("fetch-users", {});

    if (response.status < 200 || response.status > 299) {
      throw new Error("Network response was not ok");
    }
    const usersArray = response.data;
    return usersArray;
  } catch (error) {
    console.error("Error fetching users:", error);
    throw new Error("Error fetching users");
  }
};

export const fetchAndCreateConversation = async (
  startedChatById,
  candidateId,
  employerId,
  jobId
) => {
  try {
    const response = await app.post("fetch-create-conversation", {
      startedChatById,
      candidateId,
      employerId,
      jobId,
    });

    if (response.status < 200 || response.status > 299) {
      throw new Error("Network response was not ok");
    }
    const conversationData = response.data;
    return conversationData;
  } catch (error) {
    console.error("Error fetching/creating conversation:", error);
    throw new Error("Error fetching/creating conversation");
  }
};

export const toggleIncognito = async (conversationId, incognito, userUid) => {
  try {
    const response = await app.post("toggle-incognito", {
      conversationId,
      incognito,
      userUid,
    });

    if (response.status < 200 || response.status > 299) {
      throw new Error("Network response was not ok");
    }
  } catch (error) {
    console.error("Error toggling incognito:", error);
    throw new Error("Error toggling incognito");
  }
};

export const sendNotification = async (user_id, reference, type) => {
  try {
    const notification_id = uuidv4();
    const notification = {
      notification_id,
      user_id,
      reference,
      type,
      date: new Date().toISOString(),
      read: false,
    };

    await setDoc(doc(db, "notifications", notification_id), notification);
  } catch (error) {
    console.log(error);
  }
};
