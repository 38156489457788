import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import qs from "qs";
import app from "../config/axiosConfig";

const _getJobs = async (queryObject = {}) => {
  const query = qs.stringify({
    ...queryObject,
  });
  const response = await app.get(`api/jobs?${query}`);
  if (response.status < 200 || response.status > 299) {
    throw new Error("Error fetching jobs: " + response.statusText);
  }
  return response.data;
};

const _updateJob = async (job: { uid: string }) => {
  const response = await app.put(`api/jobs/${job.uid}`, job);
  if (response.status < 200 || response.status > 299) {
    throw new Error("Error updating job: " + response.statusText);
  }
  return response.data;
};

const _deleteJob = async (jobId: string) => {
  const response = await app.delete(`api/jobs/${jobId}`);
  if (response.status < 200 || response.status > 299) {
    throw new Error("Error deleting job: " + response.statusText);
  }
  return response.data;
};

const useJobs = (query = {}, enabled = true) => {
  const queryClient = useQueryClient();

  const { data, error, isLoading, isFetching } = useQuery({
    queryKey: ["job", query],
    queryFn: () => _getJobs(query),
    placeholderData: (prevData) => prevData,
    enabled: enabled,
  });

  const { isPending: isUpdating, mutate: updateJob } = useMutation({
    mutationFn: _updateJob,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["job"],
      });
    },
  });

  const { isPending: isDeleting, mutate: deleteJob } = useMutation({
    mutationFn: _deleteJob,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["job"],
      });
    },
  });

  return {
    jobs: data,
    error,
    isDataLoading: isLoading || isFetching || isDeleting || isUpdating,
    deleteJob,
    updateJob,
  };
};

export default useJobs;
