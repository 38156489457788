import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { updateInitialData } from "../../app/initialDataSlice.js";

const Content1 = () => {
  const initialData = useSelector((state) => state.initialData);
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState("");
  return (
    <div className="flex flex-column justify-center items-center bg-white  rounded-md w-full p-3">
      <div className="my-3 w-full">
        <p>Edit profile</p>
      </div>
      <div className="flex flex-column w-full">
        <div className="flex flex-column">
          <Form.Group className="mb-3" controlId="name">
            <Form.Label className="">Name</Form.Label>
            <Form.Control
              required={true}
              type="text"
              placeholder="Enter your name"
              value={initialData.name}
              onChange={(e) => {
                dispatch(updateInitialData({ name: e.target.value }));
              }}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="name">
            <Form.Label className="">Surname</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter your name"
              value={initialData.surname}
              onChange={(e) => {
                dispatch(updateInitialData({ surname: e.target.value }));
              }}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="phone">
            <Form.Label className="">Phone</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter your phone number"
              value={initialData.phone}
              onChange={(e) => {
                dispatch(updateInitialData({ phone: e.target.value }));
              }}
            />
          </Form.Group>
          {errorMessage !== "" && (
            <div className="bg-red-500 mt-2 w-100 px-2 py-1 text-white rounded-md flex-1 text-center">
              <p>{errorMessage?.message}</p>
            </div>
          )}

          <Form.Group className="mb-3" controlId="role">
            <Form.Label className="">Role in the Company</Form.Label>
            <Form.Select
              value={initialData.role}
              onChange={(e) => {
                dispatch(updateInitialData({ role: e.target.value }));
              }}
            >
              <option value="">Select Role</option>
              <option value="owner">COO</option>
              <option value="managing partner">Managing Partner</option>
              <option value="partner">Partner</option>
              <option value="hr">HR</option>
              <option value="other">other</option>
            </Form.Select>
          </Form.Group>
        </div>
      </div>
    </div>
  );
};

export default Content1;
