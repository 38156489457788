import { useState } from "react";
import styles from "./RecruiterCandidatePagePreview.module.css";
import harvey from "../../assets/example_images/harvey.jpg";
import lawfirm_logo from "../../assets/example_images/law-firm-logojpg.jpg";
import { CiLocationOn } from "react-icons/ci";
import { PiSuitcaseLight } from "react-icons/pi";
import { BiMessage } from "react-icons/bi";
import { MdOutlineMenu } from "react-icons/md";
import { PiCaretCircleLeftFill } from "react-icons/pi";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import useUsers from "../../hooks/useUsers";
import { DotLoader } from "react-spinners";
import useCheckIfAnonymous from "../../hooks/useCheckIfAnonymous.js";
import useNavigateToConversation from "../../hooks/useNavigateToConversation.js";
import WhichJobModal from "../../components/modals/WhichJobModal.jsx";

const RecruiterCandidatePagePreview = () => {
  const { navigateToConversation } = useNavigateToConversation();
  const userData = useSelector((state) => state.user.userData);
  const { userUid, jobUid } = useParams();
  const { incognito } = useCheckIfAnonymous(userData.uid, userUid, jobUid);
  const { users, isDataLoading } = useUsers({
    userId: userUid,
    isIncognito: incognito,
  });
  const [candidateToBeMessaged, setCandidateToBeMessaged] = useState(null);

  const user = users?.[0];

  const flipDateString = (dateString) => {
    const parts = dateString.split("-");
    const flippedDateString = parts.reverse().join("-");
    return flippedDateString;
  };

  const handleMessage = (candidateId) => () => {
    setCandidateToBeMessaged(candidateId);
  };

  if (isDataLoading) {
    return <DotLoader color="#868bff" />;
  }

  const isIncognito = incognito;

  return (
    <>
      <div
        className={
          (styles.container, "overflow-y-auto flex items-center justify-center")
        }
      >
        <div
          className={
            (styles.contentsection,
            "flex flex-column items-center justify-center w-[80%] lg:w-[75%]")
          }
        >
          {/* Title */}
          <div
            className="flex items-center text-white mt-5 mb-2 mx-3"
            style={{ width: "115%" }}
          >
            <PiCaretCircleLeftFill
              size={38}
              className="mr-2 text-blue-400 cursor-pointer duration-75	 hover:scale-125"
              onClick={() => window.history.back()}
            />
            Back
          </div>
          {/* Top Bar */}
          <div className={styles.topbar}>
            {/* <img src={harvey} className={styles.imgprofile} /> */}

            {user?.profileImg ? (
              <>
                <img
                  src={!isIncognito ? user.profileImg : harvey}
                  alt="user image"
                  className={styles.imgprofile}
                />
              </>
            ) : (
              <>
                <img
                  src={harvey}
                  alt="user image"
                  className={styles.imgprofile}
                />
              </>
            )}

            {/* Top bar section */}
            <div className={styles.widesection}>
              <div className={styles.topbarsection}>
                <div className="flex justify-between">
                  <div>
                    {user?.name == "" || !user?.name ? (
                      <p className="bigText ">Anonymous</p>
                    ) : (
                      <p className="bigText">
                        {origin == "matches" ||
                        origin == "applicants" ||
                        !isIncognito ? (
                          user?.name === "" || !user?.name ? (
                            <>Anonymous</>
                          ) : (
                            <>
                              {" "}
                              {user?.name} {user?.surname}
                            </>
                          )
                        ) : (
                          <> {user?.name ?? "Anonymous"}</>
                        )}
                      </p>
                    )}
                    {!user?.location ? (
                      <p className="flex flex-col lg:flex-row gap-y-1  items-center smallText">
                        <CiLocationOn size={20} className="mr-2" />
                        Candidate location not added
                      </p>
                    ) : user?.location ? (
                      <p className="flex flex-col lg:flex-row gap-y-1  items-center smallText">
                        <CiLocationOn size={20} className="mr-2" />

                        {user?.location}
                      </p>
                    ) : (
                      <p className="flex flex-col lg:flex-row gap-y-1  items-center smallText">
                        <CiLocationOn size={20} className="mr-2" />
                        No location added
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className={styles.topbarsection}>
                <p className="flex flex-col lg:flex-row gap-y-1 items-center smallText">
                  <PiSuitcaseLight size={20} className="mr-2" />
                  {user?.experience ? (
                    <>
                      <p>{user?.experience} of experience</p>
                    </>
                  ) : (
                    <>
                      <p>No experience </p>
                    </>
                  )}
                </p>
                <p className="flex flex-col lg:flex-row gap-y-1 items-center smallText">
                  <BiMessage size={20} className="mr-2" />
                  {user?.selectedLanguages[0] ? (
                    <>{user?.selectedLanguages?.join(" | ")}</>
                  ) : (
                    <>
                      <p>No language is added</p>
                    </>
                  )}
                </p>

                <p className="flex flex-col lg:flex-row gap-y-1 items-center smallText">
                  <MdOutlineMenu size={20} className="mr-2" />
                  {user?.expertise ? (
                    <>{user?.expertise?.join(" | ")}</>
                  ) : (
                    <>
                      <p>No expertise is added</p>
                    </>
                  )}
                </p>
              </div>
              <div className={styles.topbarsectionright}>
                <div className="flex">
                  <div>
                    <button className={styles.greenbtn}>
                      <p className={styles.thicktextbtn}>
                        •{" "}
                        {user?.searchingStatus
                          ? user?.searchingStatus
                          : "Casually looking"}
                      </p>
                    </button>
                  </div>
                </div>

                <p
                  className="smallText cursor-pointer"
                  onClick={handleMessage(user.uid)}
                >
                  Send a message
                </p>
              </div>
            </div>
          </div>
          {/* Medium section */}
          <div
            className={
              (styles.personalmidsection, "flex flex-col lg:flex-row w-full ")
            }
            style={{ width: "115%" }}
          >
            <div className={styles.largesection}>
              {/* About me section */}
              <div className={styles.abtme}>
                <div className="flex justify-between mb-1 mt-3">
                  <h2 className={styles.titletext}>About Candidate</h2>
                </div>
                {user?.description ? (
                  <p className="text-white">{user?.description}</p>
                ) : (
                  <>
                    <p className="text-white">No description is added</p>
                  </>
                )}

                <br />
              </div>
              {/* My Experience section */}
              <div className={styles.experienceSingleSection}>
                <div className="flex items-center justify-between">
                  <h2 className={styles.titletext}>Experience</h2>
                </div>
                {user?.employmentHistory ? (
                  <>
                    {user.employmentHistory.length == 0 ? (
                      <>
                        <div>no information added</div>
                      </>
                    ) : (
                      <>
                        {user?.employmentHistory?.map((element, index) => (
                          <ExperienceComponent data={element} key={index} />
                        ))}
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <p className="smallText">no relevant experience is added</p>
                  </>
                )}
              </div>
              {/* my Education */}
              <div className={styles.abtme}>
                <div className="flex justify-between mb-4">
                  <h2 className={styles.titletext}>Candidate Education</h2>
                </div>
                {user?.university ? (
                  <>
                    <div className="flex flex-col lg:flex-row items-start lg:items-center justify-between">
                      <p className="text-white">
                        University Name: {user.university}
                      </p>
                      <p className="text-white"> Grades: {user.grades}</p>
                      {user.dateOfGradutation != null && (
                        <p className="text-white">
                          Date of Graduation:{" "}
                          {flipDateString(user.dateOfGradutation)}
                        </p>
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    <p className="text-white">No Education is added </p>
                  </>
                )}
                {user?.master &&
                user.master.length > 0 &&
                Array.isArray(user?.master) ? (
                  <>
                    <p className="text-white  mt-2 font-bold">
                      Master of Laws (LL.M.) Certificates
                    </p>
                    {user.master?.map((item, index) => (
                      <div className="flex items-center" key={index}>
                        <ul className="px-4 list-disc">
                          <li className="text-white">{item}</li>
                        </ul>
                      </div>
                    ))}
                  </>
                ) : (
                  <>
                    <p className="text-white">No Master education is added </p>
                  </>
                )}

                <br />
              </div>
            </div>
            <div className={styles.leftsection}>
              <div className={styles.documents}>
                <div className="flex items-center justify-between">
                  <h2 className={styles.titletext}>Expertise</h2>
                </div>

                {user?.expertise?.map((expertise, i) => (
                  <button key={i} className={styles.buttonSkill}>
                    {expertise}
                  </button>
                ))}
              </div>
            </div>
          </div>

          <br />
          <br />
        </div>
      </div>
      <WhichJobModal
        show={Boolean(candidateToBeMessaged)}
        onClose={() => setCandidateToBeMessaged(null)}
        onJobSelect={(jobId) => {
          navigateToConversation(candidateToBeMessaged, jobId);
        }}
      >
        Select the job you want to message the candidate about:
      </WhichJobModal>
    </>
  );
};

const ExperienceComponent = ({ data }) => {
  return (
    <div className={styles.experienceSingleSection}>
      <img className={styles.lawfirm_logo} src={lawfirm_logo} />
      <div className={styles.experiencetextcontainer}>
        <p className="mediumText">{data?.position_title} </p>
        <div className="row space-between">
          {data?.employmentstart == "" ? (
            <p>Name of the company </p>
          ) : (
            <p>{data?.lawfirmname?.label} </p>
          )}
          <div className="row">
            <p className={styles.graytext}>1 year 2 months </p>
            {data?.employmentstart == "" ? (
              <p className={styles.graytext}>Edit this entry to get dates</p>
            ) : (
              <p className={styles.graytext}>
                {data?.employmentstart} to {data?.employmentend}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecruiterCandidatePagePreview;
