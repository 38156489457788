import { useNavigate } from "react-router-dom";
import styles from "./CandidateComplete.module.css";

const CandidateComplete = () => {
  const navigate = useNavigate();

  const handleCompleteProfile = () => {
    navigate("/candidatedata0");
  };

  const handelStartLooking = () => {
    navigate("/candidatedata4");
  };

  return (
    <div className="flex justify-center items-center h-lvh py-[100px] text-black overflow-y-auto">
      <>
        <div className={styles.boxcontainer}>
          <div className="flex flex-column items-center w-full my-3">
            <h1 className="text-5xl font-bold">Next Steps</h1>
            <p className="my-3 w-75">
              It's now time to complete your profile to start the matching
              process or start browsing oportunities.{" "}
            </p>
            <button
              className="bg-[#46D6EA]  py-2 rounded-md font-bold  w-[60%] border-1 border-[#46D6EA] cursor-pointer hover:bg-transparent"
              onClick={() => handleCompleteProfile()}
            >
              Complete your profile{" "}
            </button>
            <button
              className="bg-white  py-2 my-3 rounded-md font-bold w-[60%]  border-1 border-[#46D6EA] cursor-pointer hover:bg-[#46d5ea]"
              onClick={() => handelStartLooking()}
            >
              Start looking{" "}
            </button>
          </div>
        </div>
      </>
    </div>
  );
};

export default CandidateComplete;
