import React, { useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { PiDotOutlineFill } from "react-icons/pi";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DotLoader } from "react-spinners";

import { userSelector } from "../../app/userRedux/userSlice.js";
import RecruiterHomeJobCard from "../../components/RecruiterHomeJobCard.jsx";
import { E_JOB_STATUS } from "../../constants/enums";
import { IMAGES } from "../../constants/images.jsx";
import useFetchConversations from "../../hooks/useFetchConversations.js";
import useJobs from "../../hooks/useJobs";
import NavbarRec from "../../utils/NavBarRec";
import Conversation from "../ChatRoom/Conversation.jsx";
import useActiveConverstationId from "../ChatRoom/useActiveConverstationId.js";

const customStyles = {
  control: (provided) => ({
    ...provided,
    backgroundColor: "transparent",
    borderRight: "none",
    border: "none",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "white", // Change text color here
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "white", // Change placeholder color here
  }),
};
const customComponents = {
  IndicatorSeparator: () => null, // Remove IndicatorSeparator component
};

const sortConversations = (conversationA, conversationB) => {
  const lastMessageAtDateA = conversationA.lastMessageAt?.toDate();
  const lastMessageAtDateB = conversationB.lastMessageAt?.toDate();

  if (lastMessageAtDateA !== undefined && lastMessageAtDateB === undefined) {
    return -1;
  } else if (
    lastMessageAtDateA === undefined &&
    lastMessageAtDateB !== undefined
  ) {
    return 1;
  }

  return lastMessageAtDateB - lastMessageAtDateA;
};
const image1 =
  "https://images.unsplash.com/photo-1567532939604-b6b5b0db2604?q=80&w=1587&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D";
const image2 =
  "https://images.unsplash.com/photo-1573497019940-1c28c88b4f3e?q=80&w=1587&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D";
const image3 =
  "https://images.unsplash.com/photo-1544168190-79c17527004f?q=80&w=1588&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D";

const HomeRecruiter = () => {
  const [showModal, setShowModal] = useState(false);
  const handleShowModal = () => {
    setShowModal(true);
  };

  const initialData = useSelector((state) => state.initialData);

  const [matchesLength, setMatchesLength] = useState(0);
  const [applicantsLength, setApplicantsLength] = useState(0);
  const [likedCandidatesLength, setLikedCandidatesLength] = useState(0);
  const [totalUnreadCount, setTotalUnreadCount] = useState(0);
  const { jobs } = useJobs({ userId: initialData.uid });

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const employer = useSelector((state) => state.data.value);
  const navigate = useNavigate();

  const handleConversationClick = (conversationId) => async () => {
    setActiveConversationId(conversationId);
  };

  const user = useSelector(userSelector);
  const { conversations, loadingConversations } = useFetchConversations();
  const { activeConversationId, setActiveConversationId } =
    useActiveConverstationId(conversations);

  const activeConversation = useMemo(() => {
    if (loadingConversations || activeConversationId === null) {
      return;
    }

    return conversations.find(
      (conversation) => conversation.id === activeConversationId
    );
  }, [conversations, loadingConversations, activeConversationId]);

  const groupedConversations = conversations?.reduce((groups, conversation) => {
    const { aboutJob } = conversation; // Extract aboutJob from the conversation

    // Extract the job title from the aboutJob object
    const jobTitle = aboutJob?.jobTitle;

    if (jobTitle) {
      if (!groups[jobTitle]) {
        groups[jobTitle] = [];
      }
      groups[jobTitle].push(conversation);
    } else {
      // Handle cases where jobTitle is not available
      console.error("Job title not found for conversation:", conversation);
    }

    return groups;
  }, {});

  const sortedConversations = conversations?.toSorted(sortConversations);

  const activeJobsCount = jobs?.filter(
    (job) => job.jobStatus === E_JOB_STATUS.active
  ).length;
  const draftJobsCount = jobs?.filter(
    (job) => job.jobStatus === E_JOB_STATUS.draft
  ).length;
  const archiveJobsCount = jobs?.filter(
    (job) => job.jobStatus === E_JOB_STATUS.archive
  ).length;

  return (
    <>
      <NavbarRec />
      <div className="flex flex-column  items-center h-lvh text-white overflow-y-auto">
        <div className="flex flex-col-reverse lg:flex-row my-5 mx-3 w-[90%] gap-x-5">
          {/* left */}
          <section className="flex flex-column w-full lg:w-[67%]">
            {/* upper */}
            <div>
              <div className="grid grid-cols-3 gap-2 lg:flex lg:justify-between">
                <div className="p-2 bg-[#1C2E45] w-full items-center justify-center flex flex-col lg:w-[18%] rounded-md">
                  <div>
                    <h1 className="font-bold text-4xl text-yellow-400">
                      {jobs?.length}
                    </h1>
                  </div>
                  <div className="flex justify-between mt-2 text-xs text-center lg:text-start">
                    <p>Jobs Listed</p>
                    <p className="text-green-400">{""}</p>
                  </div>
                </div>
                <div className="p-2 bg-[#1C2E45] w-full items-center justify-center flex flex-col lg:w-[18%] rounded-md">
                  <div>
                    <h1 className="font-bold text-4xl text-yellow-400">
                      {matchesLength}
                    </h1>
                  </div>
                  <div className="flex justify-between mt-2 text-xs text-center lg:text-start">
                    <p>Matches</p>
                    <p className="text-green-400">{""}</p>
                  </div>
                </div>
                <div className="p-2 bg-[#1C2E45] w-full items-center justify-center flex flex-col lg:w-[18%] rounded-md">
                  <div>
                    <h1 className="font-bold text-4xl text-yellow-400">
                      {applicantsLength}
                    </h1>
                  </div>
                  <div className="flex justify-between mt-2 text-xs text-center lg:text-start ">
                    <p>Applicants</p>
                    {/* <p className="text-green-400">+6.3%</p> */}
                  </div>
                </div>
                <div className="p-2 bg-[#1C2E45] w-full items-center justify-center flex flex-col lg:w-[18%] rounded-md">
                  <div>
                    <h1 className="font-bold text-4xl text-yellow-400">
                      {likedCandidatesLength}
                    </h1>
                  </div>
                  <div className="flex justify-between mt-2 text-xs text-center lg:text-start">
                    <p>Liked Candidates</p>
                    <p className="text-green-400">{""}</p>
                  </div>
                </div>
                <div className="p-2 bg-[#1C2E45] w-full items-center justify-center flex flex-col lg:w-[18%] rounded-md">
                  <div>
                    <h1 className="font-bold text-4xl text-yellow-400">
                      {totalUnreadCount}
                    </h1>
                  </div>
                  <div className="flex justify-between mt-2 text-xs text-center lg:text-start">
                    <p>Messages</p>
                    <p className="text-green-400">{""}</p>
                  </div>
                </div>
              </div>
            </div>
            {/* lower */}
            <div>
              <div className="flex-auto bg-[#374B64] rounded-md">
                <div className="flex flex-col md:flex-row justify-between mx-4 my-3">
                  <div className="my-3">
                    <p className="text-yellow-400 font-bold">Latest Jobs</p>
                  </div>
                  <div className="grid grid-cols-2 gap-y-1 md:flex items-center gap-x-1">
                    <p className="flex items-center h-6 rounded-full  bg-[#B3FFA7] text-[#228F34] border-1 border-[#228F34] text-xs pl-1 pr-3 py-1">
                      <PiDotOutlineFill size={22} /> {activeJobsCount} Active
                    </p>
                    <p className="flex items-center h-6 rounded-full  bg-[#E0AD27] text-[#785E1C] border-1 border-[#785E1C] text-xs pl-1 pr-3 py-1">
                      <PiDotOutlineFill size={22} /> {draftJobsCount} Draft
                    </p>
                    <p className="flex items-center h-6 rounded-full  bg-[#FFD7D7] text-[#CD2828] border-1 border-[#CD2828] text-xs pl-1 pr-3 py-1">
                      {" "}
                      <PiDotOutlineFill size={22} /> {archiveJobsCount} Closed
                    </p>
                    <button
                      className="h-6 rounded-md bg-[#46D6EA] text-black text-xs px-3 py-1 cursor-pointer  hover:bg-[#46d7ea6a]"
                      onClick={() => navigate("/JobBoardRecruiter")}
                    >
                      View All
                    </button>
                  </div>
                </div>
                <div className="mx-4">
                  {/* jobs table head */}
                  <div className="flex items-center text-xs/5 md:text-sm text-gray-400   text-sm text-gray-400">
                    <p className="flex-1">Job Name</p>
                    <p className="flex-1">Date Posted</p>
                    <p className="flex-1">Applicants</p>
                    <p className="flex-1">Matches</p>
                    <p className="flex-1">Messages</p>
                    <p className="flex-2">Status</p>
                  </div>
                  {/* jobs list */}
                  <div className="flex flex-column gap-y-2 text-xs md:text-sm mt-2">
                    {jobs?.map((job, index) => (
                      <RecruiterHomeJobCard
                        key={job.uid}
                        jobData={job}
                        isLast={index === jobs.length - 1} // Check if it's the last item
                        setParentMatchesLength={setMatchesLength}
                        setParentApplicantsLength={setApplicantsLength}
                        setParentLikedCandidatesLength={
                          setLikedCandidatesLength
                        }
                        setParentTotalUnreadCount={setTotalUnreadCount}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* right */}
          <section className="flex flex-column w-full lg:w-[33%] mb-3">
            {/* upper */}
            <div className="flex w-100 items-center bg-[#374B64] px-2 py-3 rounded-md ">
              <div className="flex-2 mr-5">
                {employer?.firmLogo ? (
                  <>
                    <img
                      src={employer?.firmLogo}
                      alt="firm logo"
                      className="border-2 border-yellow-400 rounded-full w-20 h-20"
                    />
                  </>
                ) : (
                  <>
                    <img
                      src={IMAGES.FIRM_LOGO}
                      alt="firm logo"
                      className="border-2 border-yellow-400 rounded-full w-20 h-20"
                    />
                  </>
                )}
              </div>
              <div className="flex-1 text-xs w-100">
                <div>
                  <p className="font-bold">{employer?.companyName}</p>
                </div>
                <div className="flex items-center justify-between mt-2 mr-3">
                  <div>
                    <p className="font-bold">Subscription - Free</p>
                    <p className="text-yellow-400">Expires : 11-01-2024</p>
                  </div>
                  <div>
                    <button
                      // onClick={()=> navigate('/RecruiterProfile')}
                      onClick={() =>
                        console.log(initialData.showUserCreatedModal)
                      }
                      className="bg-[#46D6EA] border-1 border-[#46D6EA] text-black px-3 py-1 rounded-md flex items-center cursor-pointer hover:bg-white hover:text-[#46D6EA]"
                    >
                      Manage
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* lower */}
            <div className="flex-auto w-100 mt-3 rounded-md hidden md:block">
              {/* upper part */}
              <div
                className="bg-[#374B64] mb-3 p-3 rounded-md"
                onClick={() => navigate("/chatroom")}
              >
                <div>
                  <h1 className="text-yellow-400 font-bold">Messages</h1>
                </div>
                {loadingConversations ? (
                  <DotLoader color="#ffffff" size={20} />
                ) : (
                  Object.entries(groupedConversations).map(
                    ([job, conversationsInJob]) => (
                      <div key={job}>
                        <h2 className="m-2">{job}</h2>
                        {conversationsInJob.map((conversation) => (
                          <Conversation
                            key={conversation.id}
                            conversation={conversation}
                            activeConversationId={activeConversationId}
                            onClick={handleConversationClick(conversation.id)}
                          />
                        ))}
                      </div>
                    )
                  )
                )}
              </div>
              {/* lower part */}
            </div>
          </section>
        </div>
      </div>
      {initialData.showUserCreatedModal && (
        <Modal>
          <h1>Welcome</h1>
        </Modal>
      )}
    </>
  );
};

export default HomeRecruiter;
