import { Button, Form } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import CreatableSelect from "react-select/creatable";
import {
  belgiumCitiesOptions,
  expertiseOptions,
  languagesOptions,
} from "../constants/Data";
import { IMAGES } from "../constants/images";
import { useEffect } from "react";
import MultiRangeSlider from "multi-range-slider-react";

const customStyles = {
  control: (provided) => ({
    ...provided,
    backgroundColor: "transparent",
    borderRight: "none",
    border: "none",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "white", // Change text color here
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "white", // Change placeholder color here
  }),
};

const customComponents = {
  IndicatorSeparator: () => null, // Remove IndicatorSeparator component
};

const SearchBar = ({ onClear, onSearch }) => {
  const {
    control,
    handleSubmit,
    setValue,
    formState: { isSubmitting },
    register,
    reset,
  } = useForm({
    defaultValues: {
      jobTitle: localStorage.getItem("jobTitle") || "",
      jobCities: JSON.parse(localStorage.getItem("jobCities")) || [],
      jobExpertise: JSON.parse(localStorage.getItem("jobExpertise")) || [],
      jobLanguages: JSON.parse(localStorage.getItem("jobLanguages")) || [],
    },
  });

  const handleSearch = handleSubmit(async (formData) => {
    // Save form values to localStorage
    localStorage.setItem("jobTitle", formData.jobTitle);
    localStorage.setItem("jobCities", JSON.stringify(formData.jobCities));
    localStorage.setItem("jobExpertise", JSON.stringify(formData.jobExpertise));
    localStorage.setItem("jobLanguages", JSON.stringify(formData.jobLanguages));

    await onSearch(formData);
  });

  const handleClearSearch = () => {
    reset();
    onClear();
    localStorage.setItem("jobTitle", JSON.stringify(""));
    localStorage.setItem("jobCities", JSON.stringify([]));
    localStorage.setItem("jobExpertise", JSON.stringify([]));
    localStorage.setItem("jobLanguages", JSON.stringify([]));
  };

  useEffect(() => {
    // const savedJobTitle = localStorage.getItem('jobTitle') || '';
    const savedJobCities = JSON.parse(localStorage.getItem("jobCities")) || [];
    const savedJobExpertise =
      JSON.parse(localStorage.getItem("jobExpertise")) || [];
    const savedJobLanguages =
      JSON.parse(localStorage.getItem("jobLanguages")) || [];

    // setValue('jobTitle', savedJobTitle);
    setValue("jobCities", savedJobCities);
    setValue("jobExpertise", savedJobExpertise);
    setValue("jobLanguages", savedJobLanguages);

    let filtersPredefined = {
      // jobTitle: savedJobTitle,
      jobCities: savedJobCities,
      jobExpertise: savedJobExpertise,
      jobLanguages: savedJobLanguages,
    };
    setTimeout(() => {
      console.log("Getting predef filters");

      onSearch(filtersPredefined);
    }, 500);
  }, [setValue]);

  return (
    <div className="mb-2 mt-4 text-start bg-[#374B64] px-4 py-4 rounded-md">
      <div className="flex items-center gap-x-3 justify-between">
        <div className="flex flex-col items-start md:items-center md:flex-row  gap-x-3 ">
          <Controller
            className="w-full border-r-2 border-gray-400 pr-3 "
            name="jobCities"
            control={control}
            render={({ field }) => (
              <CreatableSelect
                {...field}
                isClearable
                placeholder="Location"
                isMulti
                // defaultValue={[]}
                components={customComponents}
                className="basic-multi-select text-black w-100"
                name="cities"
                options={belgiumCitiesOptions}
                classNamePrefix="select"
                styles={customStyles}
                onChange={(selectedOptions) => {
                  const formattedValue = selectedOptions.map(
                    (option) => option.label
                  );
                  field.onChange(formattedValue);
                }}
                value={
                  field.value
                    ? field.value.map((label) => ({ label, value: label }))
                    : []
                }
              />
            )}
          />

          <Controller
            className="w-full border-r-2 border-gray-400 pr-3"
            name="jobExpertise"
            control={control}
            render={({ field }) => (
              <CreatableSelect
                {...field}
                isClearable
                placeholder="Expertise"
                isMulti
                className="basic-multi-select text-black w-100"
                styles={customStyles}
                components={customComponents}
                classNamePrefix="select"
                options={expertiseOptions}
                onChange={(selectedOptions) => {
                  const formattedValue = selectedOptions.map(
                    (option) => option.label
                  );
                  field.onChange(formattedValue);
                }}
                value={
                  field.value
                    ? field.value.map((label) => ({ label, value: label }))
                    : []
                }
              />
            )}
          />
          <Controller
            className="w-full border-r-2 border-gray-400 pr-3"
            name="jobLanguages"
            control={control}
            render={({ field }) => (
              <CreatableSelect
                {...field}
                isClearable
                placeholder="Languages"
                isMulti
                className="basic-multi-select text-black w-100"
                styles={customStyles}
                components={customComponents}
                classNamePrefix="select"
                defaultValue={""}
                name="Languages"
                options={languagesOptions}
                onChange={(selectedOptions) => {
                  const formattedValue = selectedOptions.map(
                    (option) => option.label
                  );
                  field.onChange(formattedValue);
                }}
                value={
                  field.value
                    ? field.value.map((label) => ({ label, value: label }))
                    : []
                }
              />
            )}
          />
          {/* <Controller
            name="jobExperience"
            control={control}
            render={({ field: { onChange, value, ...field } }) => {
              // const handleChange = (value) => {
              //   onChange({ min: value.minValue, max: value.maxValue })
              // }

              return (
                <MultiRangeSlider
                  {...field}
                  // onInput={handleChange}
                  min={0}
                  max={7}
                  step={1}
                  label={false}
                  ruler={false}
                  // minValue={value.min}
                  // maxValue={value.max}
                  className="border-none shadow-none z-0"
                  thumbLeftColor="blue"
                  thumbRightColor="blue"
                  barInnerColor="blue"
                />
              )
            }}
          /> */}
        </div>
        <div className="flex items-center justify-between px-2  flex-col-reverse md:flex-row">
          <div
            className="flex items-center cursor-pointer mx-3 my-2"
            onClick={handleClearSearch}
          >
            Clear All
            <img src={IMAGES.TRASH_CAN} alt="filter" className="h-4 ml-2" />
          </div>

          <Button
            className="bg-[#46D6EA] border-1 border-[#46D6EA] text-white px-4 hover:bg-white hover:border-[#46D6EA] font-bold my-2"
            onClick={handleSearch}
            disabled={isSubmitting}
          >
            Search
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SearchBar;
