import { useLocation, useNavigate } from "react-router-dom";
import styles from "./CandidateSignupVerify.module.css";

const CandidateSignupVerify = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  return (
    <div className="flex justify-center items-center h-lvh py-[100px] text-black overflow-y-auto">
      <div className={styles.boxcontainer}>
        <div className="flex flex-column items-center w-full my-3">
          <div className="text-center">
            <h1 className="font-bold text-3xl">Please verify your email</h1>
          </div>
          <div className="text-center my-3">
            <p>
              You are almost there. We sent an email to <br />{" "}
              <span>{state.email}</span>
            </p>
            <p className="my-3">
              Please check you inbox and click on the link in
              <br /> that email to complete your signup.{" "}
            </p>{" "}
            <p className="my-3">This might take a few minutes.</p>
            <div className="text-center my-3">
              <button
                className="bg-[#46D6EA]  py-2 px-5 rounded-md font-bold border-1 border-[#46D6EA] cursor-pointer hover:bg-transparent"
                onClick={() => navigate("/")}
              >
                Back to home
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CandidateSignupVerify;
