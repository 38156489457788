import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { LuDot } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import { doc, updateDoc, getDoc } from "firebase/firestore";
import { db } from "../config/firebase.js";

export default function NotificationComponent({ notification }) {
  const [companyName, setCompanyName] = useState("");
  const [jobTitle, setJobTitle] = useState("");

  useEffect(() => {
    checkCompanyName();
  }, []);

  const checkCompanyName = async () => {
    if (notification.type === "LIKED_PROFILE") {
      notification.reference;

      const [jobSnapshot] = await Promise.all([
        getDoc(doc(db, "jobs", notification.reference)),
      ]);
      setCompanyName(jobSnapshot.data().companyName);
    } else if (notification.type === "APPLIED") {
      notification.reference;
      const [jobSnapshot] = await Promise.all([
        getDoc(doc(db, "jobs", notification.reference)),
      ]);
      console.log(jobSnapshot.data());
      setJobTitle(jobSnapshot.data().jobTitle);
    } else if (notification.type === "REJECTED_CANDIDATE") {
      notification.reference;
      const [jobSnapshot] = await Promise.all([
        getDoc(doc(db, "jobs", notification.reference)),
      ]);
      console.log(jobSnapshot.data());
      setJobTitle(jobSnapshot.data().jobTitle);
    }
  };
  const notificationRead = (notification_id) => {
    let notificationDocument = doc(db, "notifications", notification_id);
    updateDoc(notificationDocument, { read: true });
  };
  const navigate = useNavigate();

  return (
    <div
      className={`flex items-center justify-between cursor-pointer h-[42px] ${
        notification?.read ? "text-white" : ""
      }`}
      onClick={() => {
        if (notification.type === "APPLIED") {
          navigate(`/jobcardpage/${notification.reference}`, {
            state: {
              defaultTab: "Applicants",
            },
          });
        } else {
          navigate(`/candidatejobdetailspage/${notification.reference}`);
        }
        notificationRead(notification.notification_id);
      }}
    >
      {notification.type === "LIKED_PROFILE" &&
        `${companyName} likes your profile!`}
      {notification.type === "MATCHED" && "New match!"}
      {notification.type === "REJECTED_CANDIDATE" &&
        `Your application was rejected for ${jobTitle}`}
      {notification.type === "APPLIED" && `New application for ${jobTitle}`}
      {!notification?.read && <LuDot size={42} />}
    </div>
  );
}
