import classNames from "classnames";
import React, { useState } from "react";
import { Container, Navbar, Offcanvas } from "react-bootstrap";
import { Link, NavLink, useNavigate } from "react-router-dom";

import { store } from "../app/store.js";
import { logout } from "../app/userRedux/userSlice.js";
import MessagesNotification from "../components/MessagesNotification.jsx";
import NotificationDropdown from "../components/NotificationDropdown.jsx";
import UserDropdown from "../components/UserDropdown";
import { IMAGES } from "../constants/images";
import useLogout from "../hooks/useLogout";
import styles from "./Navbar.module.css";

const NavBarItem = ({ children, to }) => {
  return (
    <NavLink
      to={to}
      className={({ isActive }) =>
        classNames(styles.link, { [styles.activeCandidate]: isActive })
      }
    >
      {children}
    </NavLink>
  );
};

const NavBar = () => {
  const { logoutUser } = useLogout();
  const navigate = useNavigate();
  const [showMenu, setShowMenu] = useState(false);

  const handleMenuOpen = () => {
    setShowMenu(true);
  };

  const handleMenuClose = () => {
    setShowMenu(false);
  };

  const handleItemClick = () => {
    setShowMenu(false); // Close the menu when an item is clicked
  };

  const handleEditUser = () => {
    // Add logic for handling Edit User action
    console.log("Edit User clicked");
  };

  const handleSignOut = async () => {
    logoutUser(null, {
      onSuccess: () => {
        store.dispatch(logout());
      },
      onError: (error) => {
        console.log("error", error);
      },
    });
  };

  return (
    <>
      <div className={styles.navbar}>
        <Link to="/jobboard" className={styles.logo}>
          <img
            className="logo_css cursor-pointer"
            src={IMAGES.JLFULL}
            alt="logo"
          />
        </Link>
        <div className={styles.links}>
          <NavBarItem to="/jobboard">Home</NavBarItem>

          <NavBarItem to="/mymatches">My Matches</NavBarItem>
          <div className="flex flex-row justify-center items-center  ">
            <NavBarItem to="/chatroom">Messages</NavBarItem>
            <MessagesNotification />
          </div>

          <NavBarItem to="/userprofile">My Profile</NavBarItem>
          <div className="justify-center align-items-center flex">
            <NotificationDropdown />

            <UserDropdown
              handleEditUser={handleEditUser}
              handleSignout={handleSignOut}
              navigate={navigate}
            />
          </div>
        </div>
      </div>
      <Navbar
        expand="lg"
        className="inline-block custom:hidden bg-body-tertiary mb-3 w-full"
      >
        <Container fluid>
          <Link to="/" className={styles.logo}>
            <img
              className="logo_css cursor-pointer"
              src={IMAGES.JLFULL}
              alt="logo"
            />
          </Link>

          <Navbar.Toggle
            aria-controls={`offcanvasNavbar-expand`}
            onClick={handleMenuOpen}
            className="bg-white"
          />
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand`}
            aria-labelledby={`offcanvasNavbarLabel-expand`}
            placement="end"
            onHide={handleMenuClose}
            show={showMenu}
            className="w-1/2"
            style={{ width: "50%" }}
          >
            <Offcanvas.Header className="flex justify-center">
              <Offcanvas.Title>
                <Link
                  to="/jobboard"
                  className={styles.logo}
                  onClick={handleItemClick}
                >
                  <img
                    className="cursor-pointer h-[25px]"
                    src={IMAGES.JLFULL}
                    alt="logo"
                  />
                </Link>
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className="">
              <div className="flex flex-col justify-center items-center gap-y-3">
                <NavBarItem to="/jobboard" onClick={handleItemClick}>
                  <p className="text-xl">Home</p>
                  {/* <p className="text-xl">Job Board</p> */}
                </NavBarItem>
                <NavBarItem to="/mymatches" onClick={handleItemClick}>
                  <p className="text-xl">My Matches</p>
                </NavBarItem>
                <NavBarItem to="/chatroom" onClick={handleItemClick}>
                  <p className="text-xl">Messages</p>
                  <MessagesNotification />
                </NavBarItem>
                <NavBarItem to="/userprofile" onClick={handleItemClick}>
                  <p className="text-xl">My Profile</p>
                </NavBarItem>
                <UserDropdown
                  handleEditUser={handleEditUser}
                  handleSignout={handleSignOut}
                  navigate={navigate}
                  type="responsive"
                />
              </div>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </>
  );
};

export default NavBar;
