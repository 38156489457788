import React, { useState, useEffect, useMemo } from "react";
import { IMAGES } from "../../constants/images.jsx";
import { Form, Tab, Tabs } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {
  fetchAppliedJobs,
  fetchSavedOrRejectedJobs,
} from "../../app/functions.js";
import CandidateJobBoardCard from "../../components/CandidateJobBoardCard";
import { useSelector } from "react-redux";

import useFetchMyApplications from "../../hooks/useFetchMyApplications.js";

// import MatchModal from "../../components/MatchModal.jsx"
import { DotLoader } from "react-spinners";
import useFetchLikedJobs from "../../hooks/useFetchLikedJobs.js";

const customStyles = {
  control: (provided) => ({
    ...provided,
    backgroundColor: "transparent",
    borderRight: "none",
    border: "none",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "white", // Change text color here
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "white", // Change placeholder color here
  }),
};
const customComponents = {
  IndicatorSeparator: () => null, // Remove IndicatorSeparator component
};

const CandidateMatches = () => {
  const [loading, setLoading] = useState(false);
  const [jobsData, setJobsData] = useState([]);
  const [savedJobsData, setSavedJobsData] = useState([]);
  const [matchedJobsData, setMatchedJobsData] = useState([]);
  const [rejectedJobsData, setRejectedJobsData] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const [activeTab, setActiveTab] = useState("Matches");

  const initialData = useSelector((state) => state.initialData);

  const tabClass = (tabKey) => {
    return tabKey === activeTab
      ? " text-white border-b-[2px] border-[#868BFF] pb-3 font-bold"
      : "";
  };
  const handleTabSelect = (key) => {
    setActiveTab(key);
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const { applications, loadingApplications } = useFetchMyApplications();
  const { matchesApplications, loadingMatchesApplications } =
    useFetchMyApplications(null, "matches");
  const { jobsLiked, jobsAreLoading } = useFetchLikedJobs(initialData.uid);

  useEffect(() => {
    getSavedJobs();
    getRejectedJobs();
  }, []);

  const getRejectedJobs = async () => {
    const uid = initialData.uid;
    setLoading(true);
    let data = await fetchSavedOrRejectedJobs(uid, "rejectedby");
    setRejectedJobsData(data);
    setLoading(false);
  };
  const getSavedJobs = async () => {
    const uid = initialData.uid;
    setLoading(true);
    let data = await fetchSavedOrRejectedJobs(uid, "savedby");
    setSavedJobsData(data);
    setLoading(false);
  };

  const test = () => {
    console.log("The applications are ", applications);
  };

  // if (loadingApplications) {
  //   return <DotLoader color="#ffffff" size={20} />
  // }
  return (
    <div className="flex justify-center text-white overflow-y-auto">
      <div className="flex flex-column items-center my-1 md:my-10 w-screen">
        <div className="w-[90%] md:w-[70%] mb-3 mt-4">
          <h1 className="font-bold text-start ">My Matches</h1>
        </div>
        <div className="w-[90%] md:w-[70%] my-2">
          <div>
            <Tabs
              // defaultActiveKey={defaultTab}
              defaultActiveKey="Matches"
              variant=""
              // id="fill-tab-example"
              id="noanim-tab-example"
              //   transition={false}
              className="flex flex-col md:flex-row mb-3 border-none "
              onSelect={handleTabSelect}
              // fill
            >
              {/* Matches */}
              <Tab
                eventKey="Matches"
                title={
                  <span
                    className={`pt-4 mr-3 text-white ${tabClass("Matches")}`}
                  >
                    Matched
                  </span>
                }
              >
                <div className="flex flex-column mt-2">
                  {/* Jobs parts */}
                  <div className="mt-3">
                    <div className="mb-4">
                      <p>
                        <span className="font-bold">
                          {matchesApplications?.length} jobs
                        </span>{" "}
                        match your criteria
                      </p>
                    </div>
                    {/* Jobs cards container */}
                    <div className="grid grid-cols-1 lg:grid-cols-3 gap-x-5 text-black relative">
                      {/* Job card */}
                      {loading ? (
                        <DotLoader color="#868bff" />
                      ) : (
                        matchesApplications?.map((job) => (
                          <CandidateJobBoardCard
                            key={job.id}
                            position={job.position}
                            job={job}
                            origin={"matches"}
                          />
                        ))
                      )}
                    </div>
                  </div>
                </div>
              </Tab>
              {/* Saved */}
              <Tab
                eventKey="Saved"
                title={
                  <span className={`pt-4 mr-3 text-white ${tabClass("Saved")}`}>
                    Liked
                  </span>
                }
              >
                <div className="flex flex-column mt-2">
                  {/* Jobs parts */}
                  <div className="mt-3">
                    <div className="mb-4">
                      <p>
                        <span className="font-bold">
                          {savedJobsData?.length} jobs
                        </span>{" "}
                        match your criteria
                      </p>
                    </div>
                    {/* jobs cards container */}
                    <div className="grid grid-cols-1 lg:grid-cols-3 gap-x-5 text-black relative">
                      {/* job card */}
                      {loading ? (
                        <DotLoader color="#868bff" />
                      ) : (
                        savedJobsData?.map((job) => (
                          <CandidateJobBoardCard
                            key={job.id}
                            position={job.position}
                            job={job}
                          />
                        ))
                      )}
                    </div>
                  </div>
                </div>
              </Tab>

              {/*Applied */}
              <Tab
                eventKey="Applied"
                title={
                  <span
                    className={`pt-4 mr-3 text-white ${tabClass("Applied")}`}
                  >
                    Applied
                  </span>
                }
              >
                <div className="flex flex-column mt-2">
                  {/* Jobs parts */}
                  <div className="mt-3">
                    <div className="mb-4">
                      <p>
                        <span className="font-bold" onClick={() => test()}>
                          {applications?.length} jobs
                        </span>{" "}
                        match your criteria
                      </p>
                    </div>
                    {/* candidates cards container */}
                    <div className="grid grid-cols-1 lg:grid-cols-3 gap-x-5 text-black relative">
                      {/* Job card */}

                      {loading ? (
                        <DotLoader color="#868bff" />
                      ) : (
                        applications?.map((job) => (
                          <CandidateJobBoardCard
                            key={job.id}
                            position={job.position}
                            job={job}
                          />
                        ))
                      )}
                    </div>
                  </div>
                </div>
              </Tab>
              {/* Rejected */}
              <Tab
                eventKey="Rejected"
                title={
                  <span
                    className={`pt-4 mr-3 text-white ${tabClass("Rejected")}`}
                  >
                    Rejected
                  </span>
                }
              >
                <div className="flex flex-column mt-2 relative">
                  {/* Jobs parts */}
                  <div className="mt-3">
                    <div className="mb-4">
                      <p>
                        <span className="font-bold">
                          {rejectedJobsData?.length} jobs
                        </span>{" "}
                        match your criteria
                      </p>
                    </div>
                    {/* jobs cards container */}
                    <div className="grid grid-cols-1 lg:grid-cols-3 gap-x-5 text-black relative gap-y-5">
                      {/* job card */}
                      {rejectedJobsData?.map((job) => (
                        <CandidateJobBoardCard
                          key={job.id}
                          position={job.position}
                          job={job}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
      {/* <MatchModal show={showMatchModal} handleClose={handleCloseMatchModal} />
      <MessageNotificationModal
        show={showMessageModal}
        handleClose={handleCloseMessageModal}
      /> */}
    </div>
  );
};

export default CandidateMatches;
