import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { isCandidate as isCandidateSelector } from "../app/userRedux/userSlice";
import Navbar from "../utils/NavBar";
import NavbarRec from "../utils/NavBarRec";

const Layout = ({ children }) => {
  const isCandidate = useSelector(isCandidateSelector);

  return (
    <>
      {isCandidate ? <Navbar /> : <NavbarRec />}
      {children}
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
