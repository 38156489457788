import ProptTypes from "prop-types";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { isGuest, isCandidate } from "../../app/userRedux/userSlice";

const EmployerAuthGuard = ({ children }) => {
  const userIsGuest = useSelector(isGuest);
  const userIsCandidate = useSelector(isCandidate);

  if (userIsGuest || userIsCandidate) {
    return <Navigate to="/" replace />;
  }

  return children;
};

EmployerAuthGuard.propTypes = {
  children: ProptTypes.node,
};

export default EmployerAuthGuard;
