import ProptTypes from "prop-types";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

import { isGuest } from "../../app/userRedux/userSlice";

const AuthGuard = ({ children }) => {
  const userIsGuest = useSelector(isGuest);

  if (userIsGuest) {
    return <Navigate to="/" replace />;
  }

  return children;
};

AuthGuard.propTypes = {
  children: ProptTypes.node,
};

export default AuthGuard;
