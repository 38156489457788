// Import the styles for the editor
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import React, { useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import ReactQuill from "react-quill";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CreatableSelect from "react-select/creatable";

import { updateInitialData } from "../../app/initialDataSlice";
import download_icon from "../../assets/icons/Upload.svg";
import { storage } from "../../config/firebase.js";
import { belgiumCitiesOptions } from "../../constants/Data.js";
import { IMAGES } from "../../constants/images.jsx";
import "react-quill/dist/quill.snow.css";

const tooltipContent =
  "Provide general information about your firm. Candidates will see this alongside your job opportunities.";

const Content2 = () => {
  const dispatch = useDispatch();
  const initialData = useSelector((state) => state.initialData);
  const [firmLogo, setFirmLogo] = useState("");

  const [percentLOGO, setPercentLOGO] = useState(0);
  const [fileReady, setFileReady] = useState(true);
  const [filePicked, setIsFilePicked] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(false);

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
      ["clean"],
    ],
  };
  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "list",
    "bullet",
    "link",
    "image",
  ];

  const handleCollapsibleClick = (id) => {
    setOpenCollapsible((prev) => (prev === id ? null : id));
  };

  const navigate = useNavigate();

  const updateEmployeesList = (index, property, newValue) => {
    try {
      dispatch(
        updateInitialData({
          employeesList: [
            ...initialData.employmentHistory.slice(0, index),
            {
              ...initialData.employmentHistory[index],
              [property]: newValue,
            },
            ...initialData.employmentHistory.slice(index + 1),
          ],
        })
      );
      console.log(initialData.employmentHistory);
    } catch (error) {
      console.log(error);
      alert(error);
    }
  };

  const handleUpdateEmploymentItem = (index) => {
    // Clone the existing employmentHistory array
    const updatedEmploymentHistory = [...initialData.employeesList];

    // If the index is greater than or equal to the length, add a new object
    if (index >= updatedEmploymentHistory.length) {
      updatedEmploymentHistory.push({
        employeeFullName: "",
        employeeTitle: "",
        employeeEmail: "",
      });
    }
    // Dispatch the updated data to Redux
    dispatch(updateInitialData({ employeesList: updatedEmploymentHistory }));
  };

  const handleChangeLocations = (selectedOptions) => {
    const newLanguages = selectedOptions
      ? selectedOptions.map((option) => option.label)
      : [];
    dispatch(updateInitialData({ selectedLocations: newLanguages }));
  };
  // Handle file upload event and update state
  const handleChangeLOGO = (event) => {
    // setFirmLogo(event.target.files[0])
    event.preventDefault();
    setFileReady(false);
    const file = event.target.files[0];
    if (!file) {
      return;
    } else {
      if (event.target.files[0].size > 10485760) {
        alert("The file is too big! Compress it below 10MB");
      } else {
        // setProfileImg(event.target.files[0])
        setIsFilePicked(true);
        const storageRef = ref(storage, `/firmLogos/${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            // console.log(event.target.files[0])
            // console.log(profileImg)
            setPercentLOGO(progress);
          },
          (error) => {
            alert(error);
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              setUploadedFile(downloadURL);
              setFirmLogo(downloadURL);
              dispatch(updateInitialData({ firmLogo: downloadURL }));
              setFileReady(true);
            });
          }
        );
      }
    }
  };

  return (
    <div className="flex flex-column justify-center  items-center bg-white   rounded-md w-full p-3">
      <div className="flex flex-column w-full ">
        <Form.Group className="mb-3" controlId="companyName">
          <Form.Label className="">Firm Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter firm name"
            value={initialData.companyName}
            onChange={(e) => {
              dispatch(updateInitialData({ companyName: e.target.value }));
            }}
          />
          <Form.Label className=" mt-2">Founded in</Form.Label>
          <Form.Control
            type="number"
            placeholder="Enter the firm founding year"
            value={initialData.firmFounded}
            onChange={(e) => {
              dispatch(updateInitialData({ firmFounded: e.target.value }));
            }}
          />
        </Form.Group>
        <p className="my-2 ">Firm logo</p>
        <div className="flex justify-between w-100 my-1">
          <label
            for="profileImg"
            className="flex items-center justify-start px-1 bg-[#374b64] rounded-md text-white py-2 cursor-pointer w-100"
          >
            <img src={download_icon} className="mr-3" />
            Click to Upload company Logo | {percentLOGO} "% done"
          </label>
          <Form.Control
            id="profileImg"
            type="file"
            name="profileImg"
            className="invisible w-[10px]"
            onChange={handleChangeLOGO}
            accept=".png, .jpg, .jpeg"
          />
        </div>

        <div className="w-full mt-2">
          <label className="my-2">Primary Firm Location </label>
          <CreatableSelect
            isClearable
            className="basic-single"
            classNamePrefix="select"
            defaultValue={""}
            name="Primary Firm Location"
            options={belgiumCitiesOptions}
            onChange={(selectedOption) => {
              const newLocation = selectedOption ? selectedOption.label : "";
              dispatch(updateInitialData({ firmLocation: newLocation }));
            }}
            value={
              initialData.firmLocation
                ? {
                    label: initialData.firmLocation,
                    value: initialData.firmLocation,
                  }
                : null
            }
          />
        </div>
        <div className="w-full mt-2">
          <label className="my-2">Firm Locations</label>
          <CreatableSelect
            isClearable
            defaultValue={[]}
            name="languages"
            options={belgiumCitiesOptions}
            isMulti
            className="basic-multi-select"
            classNamePrefix="select"
            value={initialData?.selectedLocations?.map((location) => ({
              label: location,
              value: location,
            }))}
            onChange={handleChangeLocations}
          />
        </div>
        <Form.Group className="mb-3" controlId="companyName">
          <Form.Label className="">Firm Website</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Firm website URL"
            value={initialData.firmWebsite}
            onChange={(e) => {
              dispatch(updateInitialData({ firmWebsite: e.target.value }));
            }}
          />
        </Form.Group>
        <Form.Group className="mb-3 mt-1" controlId="role">
          <Form.Label className="">Company size</Form.Label>
          <Form.Select
            value={initialData.firmSize}
            onChange={(e) => {
              dispatch(updateInitialData({ firmSize: e.target.value }));
            }}
          >
            <option value="">Select size</option>
            <option value="0-10">0-10 employees</option>
            <option value="10-100">10-100 employees</option>
            <option value="100-500">100-500 employees</option>
            <option value="500+">500+ employees</option>
          </Form.Select>
        </Form.Group>
        <div
          className="w-full border-gray-400 pt-2 mb-1"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <p className=" mb-2">Company description &#8205; </p>
          <OverlayTrigger
            placement="right"
            overlay={
              <Tooltip placement="top" className="in" id="tooltip-top">
                {tooltipContent}
              </Tooltip>
            }
          >
            <img src={IMAGES.QUESTIONMARK} height={17.5} width={17.5} />
          </OverlayTrigger>
        </div>

        <div className="column">
          <ReactQuill
            value={initialData.aboutCompany}
            onChange={(value) =>
              dispatch(updateInitialData({ aboutCompany: value }))
            }
            modules={modules}
            formats={formats}
            placeholder="Write a description of the company you are recruiting for."
          />
        </div>
      </div>
    </div>
  );
};

export default Content2;
