import { isToday, isYesterday } from "date-fns";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { checkJobStatus, fetchSavedCandidates } from "../app/functions";
import { E_JOB_STATUS } from "../constants/enums";
import { IMAGES } from "../constants/images";
import useFetchConversationsForJob from "../hooks/useFetchConversationsForJob";
import useFetchMyApplications from "../hooks/useFetchMyApplications";
import useJobs from "../hooks/useJobs";
import EditJobModal from "./editJobModal/EditJobModal";
import JobCardDropdown from "./JobCardDropdown/JobCardDropdown";

export default function MyJobCard({ handleShowJobPage, jobData }) {
  const navigate = useNavigate();
  const { deleteJob, updateJob } = useJobs();
  const [showModal, setShowModal] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  const user = useSelector((state) => state.user.userData);
  const user_id = user.uid;

  const handelJobOverview = () => {
    navigate(`/joboverview/${jobData.uid}`);
  };

  const [likedCandidatesLength, setLikedCandidatesLength] = useState(null);
  const [applicantsLength, setApplicantsLength] = useState(null);
  const [usersLength, setUsersLength] = useState(null);

  const { applications, loadingApplications } = useFetchMyApplications(
    jobData.uid
  );
  const { matchesApplications, loadingMatchesApplications } =
    useFetchMyApplications(jobData.uid, "matches");

  useEffect(() => {
    getLikedCandidatesLength();
    checkJobStatus(jobData.uid, user_id, "users")
      .then((status) => setUsersLength(status))
      .catch((error) => console.error("Error checking job status:", error));
  }, [jobData.uid, user_id]);

  const getLikedCandidatesLength = async () => {
    let data = await fetchSavedCandidates(user_id, "saved");
    setLikedCandidatesLength(data.length);
  };

  const { unreadCounts } = useFetchConversationsForJob(jobData.uid);

  const totalUnreadCount = Object.values(unreadCounts).reduce(
    (acc, count) => acc + count,
    0
  );

  const formatDate = (dateString) => {
    const formattedDate = new Date(dateString);

    // Check if the date is today or yesterday
    if (isToday(formattedDate)) {
      return "Today";
    } else if (isYesterday(formattedDate)) {
      return "Yesterday";
    } else {
      // If not today or yesterday, display the actual date
      const options = { year: "numeric", month: "long", day: "numeric" };
      return formattedDate.toLocaleDateString(undefined, options);
    }
  };

  const handleStatusChange = (status, jobId) => {
    updateJob({ uid: jobId, job: { jobStatus: status } });
  };

  return (
    <div className="flex items-center justify-center rounded-md bg-white text-black hover:border-yellow-400 mb-1 hover:border-2">
      {/* left side */}
      <div
        className="flex  lg:flex-row w-full md:w-[45%] p-3 cursor-pointer flex-row sm:flex-col "
        onClick={handelJobOverview}
      >
        <div className="flex justify-start items-center ml-4 lg:m-0 md:block ">
          <img
            src={jobData?.firmLogo ?? IMAGES.FIRM_LOGO}
            alt="firm logo"
            className="rounded-full border-1 border-gray-500 mb-2 md:m-0 w-12 h-12 md:w-16 md:h-16"
          />
        </div>
        <div className="flex flex-column text-sm  justify-center  ml-5 mr-5 w-[100px] ">
          <h2 className="font-bold">{jobData.jobTitle}</h2>
          <p className="text-gray-500 text-xs mt-2">
            Posted <span>{formatDate(jobData?.createdAt)}</span>
          </p>
        </div>
        <div className="flex flex-col lg:flex-row gap-y-2">
          <div className="flex flex-column text-xs  justify-center ml-0 md:ml-5 text-center md:text-start ">
            <p className="flex flex-col md:flex-row gap-y-1 items-center">
              <img
                src={IMAGES.LOCATION}
                alt="location icon"
                className="w-4 mr-2"
              />

              {jobData?.jobCities.map((city, index) => (
                <span key={index} className="min-w-fit	">
                  {index > 0 && " / "} {city}
                </span>
              ))}
            </p>
            <p className="flex flex-col md:flex-row gap-y-1 items-center mt-2">
              <img
                src={IMAGES.SUITCASE}
                alt="location icon"
                className="w-4 h-5 mr-2"
              />
              <span className="min-w-fit	">
                {jobData.jobExperienceMin} - {jobData.jobExperienceMax} years
              </span>
            </p>
          </div>
          <div className="hidden md:flex flex-column text-xs  justify-center ml-5 ">
            <p className="flex items-center">
              <img
                src={IMAGES.MESSAGE}
                alt="location icon"
                className="w-4 mr-2"
              />

              {jobData?.jobLanguages.map((language, index) => (
                <span key={index} className="min-w-fit	">
                  {index > 0 && " / "} {language}
                </span>
              ))}
            </p>
            <p className="flex items-center mt-2">
              <img
                src={IMAGES.BUGERMENU}
                alt="location icon"
                className="w-4 h-2 mr-2"
              />

              {jobData?.jobExpertise.map((expertie, index) => (
                <span key={index} className="min-w-fit	">
                  {index > 0 && " / "} {expertie}
                </span>
              ))}
            </p>
          </div>
        </div>
      </div>
      {/* right side */}
      <div className="grid grid-rows-2 grid-flow-col lg:flex lg:justify-between w-full md:w-[60%] text-center p-3 hidden lg:block">
        {/* potential Matches */}
        <div
          className="text-black  w-[15%]  flex flex-column justify-center items-center cursor-pointer "
          onClick={() => handleShowJobPage("Potential")}
        >
          <h1 className="font-bold text-4xl mb-2">{usersLength}</h1>
          <p className="text-xs">Potential Matches</p>
        </div>
        {/*  applicants */}
        <div
          className="text-black  w-[15%]  flex flex-column justify-center items-center cursor-pointer"
          onClick={() => handleShowJobPage("Applicants")}
        >
          <h1 className="font-bold text-4xl mb-2">
            {applications?.length ? applications?.length : 0}
          </h1>
          <p className="text-xs">Applicants</p>
        </div>
        {/* Liked applicants */}
        <div
          className="text-black  w-[15%]  flex flex-column justify-center items-center cursor-pointer"
          onClick={() => handleShowJobPage("Liked")}
        >
          <h1 className="font-bold text-4xl mb-2">{likedCandidatesLength}</h1>
          <p className="text-xs">Liked candidates</p>
        </div>
        {/* Matching applicants */}
        <div
          className="text-black  w-[15%]  flex flex-column justify-center items-center cursor-pointer"
          onClick={() => handleShowJobPage("Matches")}
        >
          <h1 className="font-bold text-4xl mb-2">
            {matchesApplications?.length ? matchesApplications?.length : 0}
          </h1>
          <p className="text-xs">Matches</p>
        </div>

        <div
          className="text-[#FFC83A]  w-[15%]  flex flex-column justify-center items-center cursor-pointer"
          onClick={() => navigate("/chatroom")}
        >
          <h1 className="font-bold text-4xl mb-2">{totalUnreadCount}</h1>
          <p className="text-xs">Messages</p>
        </div>
        <div className="text-gray-400  w-[10%] flex flex-column justify-center items-center">
          <JobCardDropdown
            handleEdit={handleShow}
            handleDelete={() => deleteJob(jobData.uid)}
            handleStatusChange={(status) =>
              handleStatusChange(status, jobData.uid)
            }
            isActive={jobData.jobStatus === E_JOB_STATUS.active}
            isArchived={jobData.jobStatus === E_JOB_STATUS.archive}
          />

          <EditJobModal
            showModal={showModal}
            handleClose={handleClose}
            job={jobData}
          />
        </div>
      </div>
    </div>
  );
}
