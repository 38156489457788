import classNames from "classnames";
import React, { useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { IMAGES } from "../constants/images";
import styles from "./Navbar.module.css";
import { Button, Navbar, Container, Offcanvas } from "react-bootstrap";
import { Link as LinkScroll } from "react-scroll";

const links = [
  {
    id: 1,
    link: "For Candidate",
  },
  {
    id: 2,
    link: "For Law Firm",
  },
  {
    id: 3,
    link: "FAQ",
  },
  //   {
  //     id: 4,
  //     link: "our mission",
  //   },
  {
    id: 5,
    link: "contact",
  },
];

const NavBarItem = ({ children, to }) => {
  return (
    <NavLink
      to={to}
      className={({ isActive }) =>
        classNames(styles.link, { [styles.activeCandidate]: isActive })
      }
    >
      {children}
    </NavLink>
  );
};

const NavbarLanding = () => {
  const [showMenu, setShowMenu] = useState(false);

  const handleMenuOpen = () => {
    setShowMenu(true);
  };

  const handleMenuClose = () => {
    setShowMenu(false);
  };

  const handleItemClick = () => {
    setShowMenu(false); // Close the menu when an item is clicked
  };

  const navigate = useNavigate();

  return (
    <>
      <div className={styles.navbarLanding}>
        <Link to="/" className={styles.logo}>
          <img
            className="logo_css cursor-pointer"
            src={IMAGES.JL_MIXED}
            alt="logo"
          />
        </Link>
        <div className={styles.links}>
          <ul className="flex items-center">
            {links.map(({ id, link }) => {
              return (
                <a href="/" key={id}>
                  <li className="px-2 cursor-pointer capitalize text-white duration-200">
                    <LinkScroll
                      to={link}
                      smooth
                      duration={500}
                      onClick={() => navigate("/")}
                    >
                      {link}
                    </LinkScroll>
                  </li>
                </a>
              );
            })}
          </ul>

          <NavBarItem to="/ourmission">Our Misson</NavBarItem>
          <NavBarItem to="/auth/login">Login</NavBarItem>
          <Button
            className="bg-[#46d6ea] border-1 border-[#46d6ea] text-black text-center px-4 py-1 rounded-full"
            onClick={() => navigate("/auth/register")}
          >
            Sign up
          </Button>
        </div>
      </div>

      <Navbar
        expand="lg"
        className="inline-block custom:hidden bg-[#131F2E] mb-3 w-full "
      >
        <Container fluid>
          <Navbar.Brand href="/">
            <Link to="/" className={styles.logo}>
              <img
                className="logo_css cursor-pointer"
                src={IMAGES.JL_MIXED}
                alt="logo"
              />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls={`offcanvasNavbar-expand`}
            onClick={handleMenuOpen}
            className="bg-white"
          />
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand`}
            aria-labelledby={`offcanvasNavbarLabel-expand`}
            placement="end"
            onHide={handleMenuClose}
            show={showMenu}
          >
            <Offcanvas.Header
              closeButton
              className="flex justify-center bg-[#131F2E] text-white"
            >
              <Offcanvas.Title
              // id={`offcanvasNavbarLabel-expand`}
              >
                <Link to="/" className={styles.logo}>
                  <img
                    className="logo_css cursor-pointer"
                    src={IMAGES.JL_MIXED}
                    alt="logo"
                  />
                </Link>
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className="bg-[#131F2E]">
              <div className="flex flex-col items-center justify-center text-white gap-y-3">
                <ul className="flex items-center flex-col gap-y-3">
                  {links.map(({ id, link }) => {
                    return (
                      <li
                        key={id}
                        className="px-2 cursor-pointer capitalize text-white duration-200"
                      >
                        <LinkScroll
                          to={link}
                          smooth
                          duration={500}
                          onClick={() => {
                            handleItemClick();
                            navigate("/");
                          }}
                        >
                          {link}
                        </LinkScroll>
                      </li>
                    );
                  })}
                </ul>

                <NavBarItem to="/ourmission" onClick={handleItemClick}>
                  Our Misson
                </NavBarItem>
                <NavBarItem to="/auth/login" onClick={handleItemClick}>
                  Login
                </NavBarItem>
                <Button
                  className="bg-[#46d6ea] border-1 border-[#46d6ea] text-black text-center px-4 py-1 rounded-full"
                  onClick={() => navigate("/auth/register")}
                >
                  Sign up
                </Button>
              </div>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </>
  );
};

export default NavbarLanding;
