// images.jsx
import bgcandidate from "../assets/BackgroundImages/backgroundcandidate.png";
import BookMark from "../assets/BackgroundImages/bookmark.svg";
import chatBG from "../assets/BackgroundImages/chatBG.png";
import FilterImg from "../assets/BackgroundImages/filter.png";
import landingchoice from "../assets/BackgroundImages/landingchoice.png";
import lastBG from "../assets/BackgroundImages/lastBg.png";
import Tick from "../assets/BackgroundImages/tick.svg";
import Trashcan from "../assets/BackgroundImages/trash.svg";
import JLFullLogo from "../assets/LogoImages/Just-Lawyers-full-color.png";
import JLFullLogoMixed from "../assets/LogoImages/Just-Lawyers-full-mixed.png";
import JLFullLogoWhite from "../assets/LogoImages/Just-Lawyers-full-white.png";
import JLLogo from "../assets/LogoImages/Just-Lawyers-logo-mark.png";
import RecLogo from "../assets/LogoImages/akdLogo.png";
import BigLogo from "../assets/example_images/bigLogo.png";
import Envelope from "../assets/example_images/envelope.png";
import userIcon from "../assets/example_images/harvey.jpg";
import Hooray from "../assets/example_images/hoory.png";
import LandingPageLady from "../assets/example_images/imageLady.png";
import LandingSection3 from "../assets/example_images/landing_section_3.png";
import FirmLogo from "../assets/example_images/law-firm-logojpg.jpg";
import Altius from "../assets/firms_logos/Altius.jpg";
import Crowell from "../assets/firms_logos/Crowell.jpg";
import Lydian from "../assets/firms_logos/Lydian.png";
import McDermott from "../assets/firms_logos/McDermott_Will_&_Emery.jpg";
import Akd from "../assets/firms_logos/akd.jpg";
import simmons from "../assets/firms_logos/simmons.png";
import Vbb from "../assets/firms_logos/van_bael__bellis_logo 1.jpg";
import ArrowDown from "../assets/icons/ArrowDown.png";
import ArrowToRight from "../assets/icons/ArrowToRight.png";
import ArrowUp from "../assets/icons/ArrowUp.png";
import ArrowYellow from "../assets/icons/ArrowYellow.png";
import Bell from "../assets/icons/Bell.png";
import CharacterMission from "../assets/icons/CharacterMission.png";
import Clip from "../assets/icons/Clip.png";
import ClipBlack from "../assets/icons/ClipBlack.png";
import FirmMission from "../assets/icons/FirmMission.png";
import LoadedFile from "../assets/icons/LoadedFile.png";
import UploadFile from "../assets/icons/UploadFile.png";
import bugermenu from "../assets/icons/bugermenu.png";
import filetext from "../assets/icons/file-text.svg";
import green_heart from "../assets/icons/green_heart.png";
import GreenPerson from "../assets/icons/greenperson.png";
import grey_message from "../assets/icons/grey_message.png";
import Incognito from "../assets/icons/incognito.png";
import LineYellow from "../assets/icons/LineYellow.png";
import location from "../assets/icons/location.png";
import MatchBlack from "../assets/icons/matchBlack.png";
import Message from "../assets/icons/message.png";
import questionmark from "../assets/icons/question-circle.png";
import red_cross from "../assets/icons/red_cross.png";
import suitcase from "../assets/icons/suitcase.png";
import logo_candidate from "../assets/logo_candidate.png";
import appStore from "../assets/stores/appstore.png";
import GPlay from "../assets/stores/googleplay.png";

export const IMAGES = {
  BG: bgcandidate,
  // SEARCH: searchImage,
  SEARCH: lastBG,
  LOGO_CANDIDATE: logo_candidate,
  BUGERMENU: bugermenu,
  GREEN_HEART: green_heart,
  GREY_MESSAGE: grey_message,
  LOCATION: location,
  RED_CROSS: red_cross,
  SUITCASE: suitcase,
  LANDING_CHOICE: landingchoice,
  FIRM_LOGO: FirmLogo,
  TICK: Tick,
  BOOK_MARK: BookMark,
  MESSAGE: Message,
  FILTER_IMAGE: FilterImg,
  TRASH_CAN: Trashcan,
  CONE: Hooray,
  REC_LOGO: RecLogo,
  ENVELOPE: Envelope,
  JLFULL: JLFullLogo,
  JL_LOGO: JLLogo,
  JL_WHITE: JLFullLogoWhite,
  JL_MIXED: JLFullLogoMixed,
  QUESTIONMARK: questionmark,
  U_ICON: userIcon,
  R_ARROW: ArrowToRight,
  BOOK: UploadFile,
  CONTAINER: LoadedFile,
  F_TEXT: filetext,
  A_UP: ArrowUp,
  A_DOWN: ArrowDown,
  A_YELLOW: ArrowYellow,
  L_YELLOW: LineYellow,
  CLIP: Clip,
  CHAT_BG: chatBG,
  CLIP_BLACK: ClipBlack,
  MATCH_BLACK: MatchBlack,
  INCOG_ICON: Incognito,
  GREEN_PER: GreenPerson,
  BELL: Bell,
  BIG_LOGO: BigLogo,
  AKD: Akd,
  ALTIUS: Altius,
  CROWELL: Crowell,
  LYDIAN: Lydian,
  MCDERMOTT: McDermott,
  VBB: Vbb,
  SIMMONS: simmons,
  L_SECTION_3: LandingSection3,
  L_LADY: LandingPageLady,
  APP_STORE: appStore,
  G_PLAY: GPlay,
  M_CHARACTER: CharacterMission,
  M_FIRM: FirmMission,
};
