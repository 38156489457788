import React, { useMemo, useState } from "react";
import { Badge, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { BiMessage } from "react-icons/bi";
import { CiLocationOn } from "react-icons/ci";
import { HiQuestionMarkCircle } from "react-icons/hi2";
import { IoMdCheckmark } from "react-icons/io";
import { MdOutlineMenu } from "react-icons/md";
import { PiSuitcaseLight } from "react-icons/pi";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DotLoader } from "react-spinners";
import { toggleIncognito as updateIncognito } from "../../app/functions.js";
import { userSelector } from "../../app/userRedux/userSlice.js";
import IncognitoModal from "../../components/IncognitoModal.jsx";
import Layout from "../../components/Layout.jsx";
import { IMAGES } from "../../constants/images.jsx";
import useFetchConversations from "../../hooks/useFetchConversations.js";
import Conversation from "./Conversation.jsx";
import Messages from "./Messages.jsx";
import useActiveConverstationId from "./useActiveConverstationId.js";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";

const backgroundImageStyle = {
  backgroundImage: `url(${IMAGES.CHAT_BG})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
};
const tooltipContent = "Incongnito mode is on ";

const sortConversations = (conversationA, conversationB) => {
  const lastMessageAtDateA = conversationA.lastMessageAt?.toDate();
  const lastMessageAtDateB = conversationB.lastMessageAt?.toDate();

  if (lastMessageAtDateA !== undefined && lastMessageAtDateB === undefined) {
    return -1;
  } else if (
    lastMessageAtDateA === undefined &&
    lastMessageAtDateB !== undefined
  ) {
    return 1;
  }

  return lastMessageAtDateB - lastMessageAtDateA;
};

const ChatRoom = () => {
  const navigate = useNavigate();
  const user = useSelector(userSelector);
  const { conversations, loadingConversations } = useFetchConversations();
  const { activeConversationId, setActiveConversationId } =
    useActiveConverstationId(conversations);
  const [show, setShow] = useState(false);

  const activeConversation = useMemo(() => {
    if (loadingConversations || activeConversationId === null) {
      return;
    }

    return conversations.find(
      (conversation) => conversation.id === activeConversationId
    );
  }, [conversations, loadingConversations, activeConversationId]);

  const messagingWith = useMemo(() => {
    return activeConversation?.conversationPartner;
  }, [activeConversation]);

  const aboutJob = useMemo(() => {
    return activeConversation?.aboutJob;
  }, [activeConversation]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleConversationClick = (conversationId) => async () => {
    setActiveConversationId(conversationId);
  };

  const updateIncognitoStatus = async (incognito) => {
    if (activeConversation.incognito !== incognito) {
      await updateIncognito(activeConversationId, incognito, user.uid);
    }
  };

  const groupedConversations = conversations?.reduce((groups, conversation) => {
    const { aboutJob } = conversation; // Extract aboutJob from the conversation

    // Extract the job title from the aboutJob object
    const jobTitle = aboutJob?.jobTitle;

    if (jobTitle) {
      if (!groups[jobTitle]) {
        groups[jobTitle] = [];
      }
      groups[jobTitle].push(conversation);
    } else {
      // Handle cases where jobTitle is not available
      console.error("Job title not found for conversation:", conversation);
    }

    return groups;
  }, {});

  const sortedConversations = conversations?.toSorted(sortConversations);

  return (
    <Layout>
      <div className="flex flex-column  items-center h-[92vh] text-white overflow-hidden overscroll-none bg-[#253549] cursor-pointer">
        <div className="flex w-full h-full">
          {/* left */}
          <div className="hidden md:flex flex-column w-[22%] bg-[#131F2E]  py-1">
            {/* search section */}
            <div className="mt-3 w-full ">
              <h1 className="font-bold text-lg mb-2 mx-2">Conversations</h1>

              {/* <div className="flex items-center relative w-full">
                <BiSearch size={32} className="text-gray-400 absolute pl-3 " />

                <input
                  type="text"
                  className="w-full px-4 py-2 mr-2 rounded-lg text-black"
                  placeholder="  Type here"
                />
              </div> */}
            </div>
            <div className="flex flex-column w-full mt-3 text-xs overflow-auto">
              {/* job1 */}
              <div className="  border-gray-300 pb-2">
                {/* border-b-[1px] */}
                {/* <h2 className="font-bold mb-2">Job Name 1 </h2> */}

                {/* contact cards */}
                {loadingConversations ? (
                  <DotLoader color="#ffffff" size={20} />
                ) : user.isEmployer ? (
                  Object.entries(groupedConversations).map(
                    ([job, conversationsInJob]) => (
                      <div key={job}>
                        <h2 className="m-2">{job}</h2>
                        {conversationsInJob.map((conversation) => (
                          <Conversation
                            key={conversation.id}
                            conversation={conversation}
                            activeConversationId={activeConversationId}
                            onClick={handleConversationClick(conversation.id)}
                          />
                        ))}
                      </div>
                    )
                  )
                ) : (
                  sortedConversations.map((conversation) => (
                    <Conversation
                      key={conversation.id}
                      conversation={conversation}
                      activeConversationId={activeConversationId}
                      onClick={handleConversationClick(conversation.id)}
                    />
                  ))
                )}
              </div>
            </div>
          </div>

          {/* mid */}
          <div
            className="flex flex-column items-center w-full md:w-[56%] relative"
            style={backgroundImageStyle}
          >
            {/* top chat screen */}
            {messagingWith && (
              <div className="flex items-center w-100 h-16 bg-[#1E2C3E] px-2 z-50">
                <img
                  src={
                    !messagingWith?.isEmployer
                      ? activeConversation?.incognito
                        ? IMAGES.U_ICON
                        : messagingWith?.profileImg
                          ? messagingWith?.profileImg
                          : IMAGES.U_ICON
                      : messagingWith?.firmLogo
                        ? messagingWith?.firmLogo
                        : IMAGES.FIRM_LOGO
                  }
                  className="w-12 h-12 rounded-full"
                  alt=""
                />
                <div className="ml-2">
                  <p className="font-bold pl-2">
                    {!messagingWith?.isEmployer
                      ? messagingWith?.userData?.name ||
                        !activeConversation?.incognito
                        ? messagingWith?.name + " " + messagingWith?.surname
                        : messagingWith?.name
                      : aboutJob?.jobTitle}
                  </p>
                  {/* <p className="flex items-center text-sm text-green-400">
                  <BsDot size={22} className="" />
                  Online
                </p> */}
                </div>
              </div>
            )}
            {!user.isEmployer && (
              <>
                <div
                  className={`flex items-center justify-center  z-50 py-2 rounded-md absolute top-[4rem] lg:top-[2rem] px-2 cursor-pointer ${
                    activeConversation?.incognito
                      ? `bg-[#949494]`
                      : `bg-[#B3FFA7]`
                  }`}
                  onClick={() => handleShow()}
                >
                  <div className="flex justify-around items-center w-full text-[#D8E0EB] ">
                    <img
                      src={
                        activeConversation?.incognito
                          ? IMAGES.INCOG_ICON
                          : IMAGES.GREEN_PER
                      }
                    />

                    <p
                      className={`font-bold pl-2 mr-2 ${
                        activeConversation?.incognito
                          ? `text-[#fff]`
                          : `text-[#228F34] `
                      }`}
                    >
                      {activeConversation?.incognito
                        ? "Incognito "
                        : "Public  "}
                    </p>
                    {activeConversation?.incognito && (
                      <OverlayTrigger
                        placement="bottom"
                        overlay={
                          <Tooltip
                            placement="bottom"
                            className="in "
                            id="tooltip-bottom"
                          >
                            {tooltipContent}
                          </Tooltip>
                        }
                      >
                        <p className="mr-2">
                          <HiQuestionMarkCircle />
                        </p>
                      </OverlayTrigger>
                    )}
                    <Form.Check // prettier-ignore
                      checked={activeConversation?.incognito}
                      type="switch"
                      id="custom-switch"
                      style={{ cursor: "pointer" }}
                      onChange={() => {
                        handleShow();
                      }}
                    />
                  </div>
                </div>
              </>
            )}
            <IncognitoModal
              show={show}
              handleClose={handleClose}
              setIncognitoMode={updateIncognitoStatus}
              isIncognito={activeConversation?.incognito}
            />
            {/* mid chat screen */}
            {/* <div className="flex items-center flex-column justify-center w-full h-100">
              <div className="flex items-center justify-center flex-column h-[90%] w-full">
                <div className="flex items-center justify-center flex-column">
                  <img src={IMAGES.JL_WHITE} className="h-7" />
                  <p className="w-75 text-center mt-1">
                    Select a chat to start messaging
                  </p>
                </div>
              </div>
              <div className="flex flex-column items-center w-full h-[20%] mb-3 px-3">
                <div className="flex w-full gap-x-3">
                  <div className="w-50 bg-[#131F2E] p-2 rounded-md border-[1px] border-gray-200">
                    <p className="text-[#46D6EA]">Ask for an interview</p>
                    <p className="text-[#7C7C7C] text-xs">
                      Request their availabilities for you to schedule an
                      interview
                    </p>
                  </div>
                  <div className="w-50 bg-[#131F2E] p-2 rounded-md border-[1px] border-gray-200">
                    <p className="text-[#46D6EA]">Frequently Sent Message</p>
                    <p className="text-[#7C7C7C] text-xs">
                      Frequently Sent Message Description
                    </p>
                  </div>
                </div>
                <div className="flex w-full gap-x-3 mt-2">
                  <div className="w-50 bg-[#131F2E] p-2 rounded-md border-[1px] border-gray-200">
                    <p className="text-[#46D6EA]">Frequently Sent Message</p>
                    <p className="text-[#7C7C7C] text-xs">
                      Frequently Sent Message Description
                    </p>
                  </div>
                  <div className="w-50 bg-[#131F2E] p-2 rounded-md border-[1px] border-gray-200">
                    <p className="text-[#46D6EA]">Frequently Sent Message</p>
                    <p className="text-[#7C7C7C] text-xs">
                      Frequently Sent Message Description
                    </p>
                  </div>
                </div>
              </div>
              <div className="">
                <p>message</p>
              </div>
            </div> */}
            {/* messeges bar */}
            {/* <div className="flex items-center w-full mb-3 px-3 gap-x-3">
              <Form.Control
                type="text"
                placeholder="Type a message"
                className="w-full"
              />
              <Button className="bg-[#46D6EA] border-1 border-[#46D6EA] text-black px-4 hover:bg-white hover:border-[#46D6EA] ">
                Send
              </Button>
            </div> */}
            {!messagingWith && (
              <div className="flex items-center justify-center flex-column h-[90%] w-full">
                <div className="flex items-center justify-center flex-column">
                  <img src={IMAGES.JL_WHITE} className="h-7" alt="" />
                  <p className="w-75 text-center mt-1 text-white">
                    Select a chat to start messaging
                  </p>
                </div>
              </div>
            )}

            {activeConversationId && (
              <Messages
                converstationId={activeConversationId}
                key={activeConversationId}
              />
            )}
          </div>
          {/* right */}
          {messagingWith && (
            <div
              className="hidden md:flex flex-column items-center w-[22%] bg-[#131F2E] px-3 py-1"
              onClick={() => {
                if (user.isEmployer) {
                  navigate(
                    `/candidate-preview/${messagingWith.uid}/${aboutJob?.uid ?? ""}`,
                    {
                      state: {
                        origin: origin,
                      },
                    }
                  );
                } else {
                  navigate(`/candidatejobdetailspage/${aboutJob?.uid}`);
                }
              }}
            >
              {/* upper part */}
              <div className="flex flex-column items-center justify-center  w-[90%] px-3 mt-4 border-b-[1px]  border-gray-300 pb-3">
                {/* firm logo */}
                <div className="hidden md:inline-block">
                  <img
                    src={
                      !messagingWith?.isEmployer
                        ? activeConversation?.incognito
                          ? IMAGES.U_ICON
                          : messagingWith?.profileImg
                            ? messagingWith?.profileImg
                            : IMAGES.U_ICON
                        : messagingWith?.firmLogo
                          ? messagingWith?.firmLogo
                          : IMAGES.FIRM_LOGO
                    }
                    className="md:w-16 md:h-16 lg:w-24 lg:w-24 lg:h-24 border-2 border-yellow-400 rounded-full"
                    alt=""
                  />
                </div>
                {/* firm info */}
                <div className="mt-3 text-xs lg:text-sm items-center flex flex-col">
                  <h3>
                    {!messagingWith?.isEmployer
                      ? messagingWith?.userData?.name ||
                        !activeConversation?.incognito
                        ? messagingWith?.name + " " + messagingWith?.surname
                        : messagingWith?.name
                      : aboutJob?.jobTitle}
                  </h3>
                  <p
                    className="flex items-center mt-2"
                    onClick={() => console.log(aboutJob)}
                  >
                    <CiLocationOn size={22} className="mr-2" />
                    Brussels
                  </p>
                </div>
              </div>
              {/* lower part */}
              <div className=" w-[90%] ">
                {/* position */}
                <div className="flex flex-column items-center justify-center text-center mt-4">
                  <div className="flex flex-col  lg:flex-row text-xs lg:text-sm">
                    <p className="font-normal">Regarding:&nbsp;</p>
                    <p className="font-bold">{aboutJob?.jobTitle}</p>
                  </div>

                  <p className="text-xs my-1">
                    {messagingWith?.isEmployer
                      ? ""
                      : JSON.stringify(messagingWith?.createdAt)}
                  </p>
                  <Badge
                    bg=""
                    className="bg-[#868BFF] text-black lg:w-[40%] rounded-full flex items-center justify-center"
                  >
                    90% match
                  </Badge>
                  <div className="flex flex-col lg:flex-row items-start lg:items-center justify-between mt-3 w-[90%] border-b-[1px]  border-gray-300 pb-3">
                    <p className="flex items-center text-xs">
                      <IoMdCheckmark className="mr-1" />
                      Position
                    </p>
                    <p className="flex items-center text-xs">
                      <IoMdCheckmark className="mr-1" />
                      Expertise
                    </p>
                    <p className="flex items-center text-xs">
                      <IoMdCheckmark className="mr-1" />
                      Experience
                    </p>
                  </div>
                </div>
                {/* icons */}
                <div className="flex flex-column  mt-3  border-b-[1px]  border-gray-300 pb-3">
                  <div className="grid grid-cols-1 md:grid-cols-2 justify-around text-center items-center">
                    <p className="flex flex-column justify-center items-center text-xs ">
                      <CiLocationOn size={22} className="mb-2" />

                      {user.isEmployer
                        ? messagingWith?.cities
                          ? messagingWith?.cities?.join(", ")
                          : "No location"
                        : aboutJob?.jobCities?.join(", ")}
                    </p>
                    <p className="flex flex-column justify-center items-center text-xs ">
                      <PiSuitcaseLight size={22} className="mb-2" />
                      {user.isEmployer &&
                      messagingWith?.jobExperienceMin !== undefined
                        ? messagingWith?.jobExperienceMin +
                          " - " +
                          messagingWith?.jobExperienceMax +
                          " years"
                        : aboutJob?.jobExperienceMin +
                          " - " +
                          aboutJob?.jobExperienceMax +
                          " years"}
                    </p>
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-2 justify-around items-center text-center mt-2">
                    <p className="flex flex-column justify-center items-center text-xs ">
                      <BiMessage size={22} className="mb-2" />
                      {/* {messagingWith?.jobLanguages?.join(" / ")} */}
                      {}
                      {user.isEmployer
                        ? messagingWith?.selectedLanguages
                          ? messagingWith?.selectedLanguages?.join(" / ")
                          : "No languages"
                        : aboutJob?.jobLanguages?.join(" / ")}
                    </p>
                    <p className="flex flex-column justify-center items-center text-xs ">
                      <MdOutlineMenu size={22} className="mb-2" />

                      {}
                      {user.isEmployer
                        ? messagingWith?.expertise
                          ? messagingWith?.expertise?.join(", ")
                          : "No expertise"
                        : aboutJob?.jobExpertise?.join(", ")}
                    </p>
                  </div>
                </div>
                {/* description */}
                <div className="flex flex-column items-center mt-3 p-3 text-white">
                  <p className="text-xs font-bold">
                    {user.isEmployer ? "About User" : "About Job"}
                  </p>
                  <div
                    className="text-justify mt-2 text-xs text-white"
                    dangerouslySetInnerHTML={
                      user.isEmployer
                        ? {
                            __html: messagingWith?.description,
                          }
                        : {
                            __html: aboutJob?.jobDescription,
                          }
                    }
                  >
                    {/* {messagingWith?.jobDescription} */}
                  </div>
                  {/* <Badge
                  bg=""
                  className="flex items-center bg-[#46D6EA] text-black rounded-md p-2 mt-4"
                >
                  Lean more{" "}
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip
                        placement="right"
                        className="in "
                        id="tooltip-right"
                      >
                      </Tooltip>
                    }
                  >
                    <LuInfo className="ml-2" />
                  </OverlayTrigger>
                </Badge> */}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default ChatRoom;
