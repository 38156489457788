import { useMutation } from "@tanstack/react-query";
// @ts-ignore
import app from "../config/axiosConfig";

const _logoutUser = async () => {
  try {
    const response = await app.post(`api/logout`);

    if (response.status < 200 || response.status > 299) {
      throw new Error(response.data.error);
    }

    return response.data;
  } catch (error: any) {
    throw error.response.data.error;
  }
};
const useLogout = () => {
  const {
    data,
    error,
    isPending: isLoading,
    mutate: logoutUser,
  } = useMutation({
    mutationFn: _logoutUser,
  });

  return { data, error, isLoading, logoutUser };
};

export default useLogout;
