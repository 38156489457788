import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import qs from "qs";
import app from "../config/axiosConfig";

const _getUsers = async (queryObject = {}) => {
  const query = qs.stringify({
    ...queryObject,
  });
  const response = await app.get(`api/users?${query}`);
  if (response.status < 200 || response.status > 299) {
    throw new Error("Error fetching users: " + response.statusText);
  }
  return response.data;
};

const _updateUser = async (user: { uid: string }) => {
  const response = await app.put(`api/users/${user.uid}`, user);
  if (response.status < 200 || response.status > 299) {
    throw new Error("Error updating user: " + response.statusText);
  }
  return response.data;
};

const _deleteUser = async (userId: string) => {
  const response = await app.delete(`api/users/${userId}`);
  if (response.status < 200 || response.status > 299) {
    throw new Error("Error deleting user: " + response.statusText);
  }
  return response.data;
};

const useUsers = (query = {}, enabled = true) => {
  const queryClient = useQueryClient();

  const { data, error, isLoading } = useQuery({
    queryKey: ["user", query],
    queryFn: () => _getUsers(query),
    enabled: enabled,
  });

  const { isPending: isUpdating, mutate: updateUser } = useMutation({
    mutationFn: _updateUser,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["user"],
      });
    },
  });

  const { isPending: isDeleting, mutate: deleteUser } = useMutation({
    mutationFn: _deleteUser,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["user"],
      });
    },
  });

  return {
    users: data,
    error,
    isDataLoading: isLoading || isDeleting || isUpdating,
    deleteUser,
    updateUser,
  };
};

export default useUsers;
