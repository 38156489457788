// ModalCandidateData.jsx

import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import styles from "./CandidateData.module.css";

import Content1 from "./Content1.jsx";
import Content2 from "./Content2.jsx";
import Content3 from "./Content3.jsx";
import Content4 from "./Content4.jsx";

import { saveData } from "../../app/dataSlice.js";
import { useSelector, useDispatch } from "react-redux";
import { editUser } from "../../app/functions.js";

const ModalCandidateData = ({ show, handleClose }) => {
  const dispatch = useDispatch();
  const initialData = useSelector((state) => state.initialData);
  const [page, setPage] = useState("Page1");

  const save = async () => {
    const data = initialData;
    const uid = initialData.uid;
    saveData(uid, data);

    await editUser(data, dispatch, saveData);
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header
        closeButton
        className="w-full "
        style={{ borderTop: "7px solid #868BFF" }}
      >
        <Row className="w-full">
          <Row>
            {page === "Page1" && <Modal.Title>Candidate Details</Modal.Title>}
            {page === "Page2" && <Modal.Title>Education</Modal.Title>}
            {page === "Page3" && <Modal.Title>Experience</Modal.Title>}
            {page === "Page4" && <Modal.Title>Job Search</Modal.Title>}
          </Row>
          <Row className="flex items-center justify-between">
            <Col className="flex justify-between mt-2">
              <Button
                className="text-xs"
                variant={page === "Page1" ? "primary" : "secondary"}
                onClick={() => setPage("Page1")}
              >
                <p className="font-bold text-black">Candidate details</p>
              </Button>

              {/* Button for Section 2 */}

              <Button
                className="text-xs"
                variant={page === "Page2" ? "primary" : "secondary"}
                onClick={() => setPage("Page2")}
              >
                <p className="font-bold text-black">Education</p>
              </Button>

              {/* Button for Section 3 */}

              <Button
                className="text-xs"
                variant={page === "Page3" ? "primary" : "secondary"}
                onClick={() => setPage("Page3")}
              >
                <p className="font-bold text-black">Experience</p>
              </Button>
              {/* Button for Section 4 */}

              <Button
                className="text-xs"
                variant={page === "Page4" ? "primary" : "secondary"}
                onClick={() => setPage("Page4")}
              >
                <p className="font-bold text-black">Job Search</p>
              </Button>
            </Col>
          </Row>
        </Row>
      </Modal.Header>
      <Modal.Body className={styles.modalbod}>
        {page === "Page1" && <Content1 />}
        {page === "Page2" && <Content2 />}
        {page === "Page3" && <Content3 />}
        {page === "Page4" && <Content4 />}
      </Modal.Body>
      <Modal.Footer>
        {/* Button for Section 1 */}
        {/* <Button
          variant={page === "Page1" ? "primary" : "secondary"}
          onClick={() => setPage("Page1")}
        >
          <p className="font-bold text-black">Candidate details</p>
        </Button> */}

        {/* Button for Section 2 */}
        {/* <Button
          variant={page === "Page2" ? "primary" : "secondary"}
          onClick={() => setPage("Page2")}
        >
          <p className="font-bold text-black">Education</p>
        </Button> */}

        {/* Button for Section 3 */}
        {/* <Button
          variant={page === "Page3" ? "primary" : "secondary"}
          onClick={() => setPage("Page3")}
        >
          <p className="font-bold text-black">Experience</p>
        </Button> */}
        <Row className="flex items-center justify-between w-full">
          <Col>
            {page === "Page1" && <Modal.Title>Candidate Details</Modal.Title>}
            {page === "Page2" && <Modal.Title>Education</Modal.Title>}
            {page === "Page3" && <Modal.Title>Experience</Modal.Title>}
            {page === "Page4" && <Modal.Title>Job Search</Modal.Title>}
          </Col>
          <Col className="flex justify-end">
            {/* Close button */}

            <Button variant="primary" onClick={save}>
              <p className="font-bold text-blue-500">Save</p>
            </Button>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalCandidateData;
