import {
  collection,
  doc,
  getDocs,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../config/firebase";

const useFetchConversationsForJob = (jobId) => {
  const [conversations, setConversations] = useState([]);
  const [unreadCounts, setUnreadCounts] = useState({});

  useEffect(() => {
    const fetchUnreadMessagesCount = async (conversationId) => {
      const messagesRef = collection(
        db,
        "conversations",
        conversationId,
        "messages"
      );
      const q = query(messagesRef, where("read", "==", false));
      const querySnapshot = await getDocs(q);
      return querySnapshot.size; // Number of unread messages
    };

    const unsubscribe = onSnapshot(
      query(
        collection(db, "conversations"),
        where("job", "==", doc(db, "jobs", jobId))
      ),
      async (snapshot) => {
        const fetchedConversations = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        const unreadCountsPromises = fetchedConversations.map(
          async (conversation) => {
            const unreadCount = await fetchUnreadMessagesCount(conversation.id);
            return { [conversation.id]: unreadCount };
          }
        );

        Promise.all(unreadCountsPromises).then((counts) => {
          const unreadCountsObj = counts.reduce(
            (acc, curr) => ({ ...acc, ...curr }),
            {}
          );
          setUnreadCounts(unreadCountsObj);
        });

        setConversations(fetchedConversations);
      }
    );

    return unsubscribe;
  }, [jobId]);

  return { unreadCounts, loadingConversations: conversations.length === 0 };
};

export default useFetchConversationsForJob;
