import React, { useState } from "react";
import { DotLoader } from "react-spinners";
import { candidateSave } from "../../app/functions.js";
import CandidateCard from "../../components/CandidateCard.jsx";
import Layout from "../../components/Layout.jsx";
import WhichJobModal from "../../components/modals/WhichJobModal.jsx";
import useNavigateToConversation from "../../hooks/useNavigateToConversation.js";
import SearchBar from "../../components/SearchBar.jsx";
import useUsers from "../../hooks/useUsers";
import { useSelector } from "react-redux";

const RecruiterCandidatesPage = () => {
  const [candidateToBeLiked, setCandidateToBeLiked] = useState(null);
  const [candidateToBeMessaged, setCandidateToBeMessaged] = useState(null);
  const { navigateToConversation } = useNavigateToConversation();
  const [filters, setFilters] = useState({});
  const { users, isDataLoading } = useUsers(filters);
  const userData = useSelector((state) => state.user.userData);

  const handleLike = (candidateId) => () => {
    setCandidateToBeLiked(candidateId);
  };

  const handleMessage = (candidateId) => () => {
    setCandidateToBeMessaged(candidateId);
  };

  return (
    <>
      <Layout>
        <div className="flex flex-column  text-white overflow-y-auto relative z-0 ">
          <div className="flex flex-column items-center my-5 mx-3 w-[90%] md:w-75 gap-x-5 ">
            <div className="mb-2 mt-4 text-start flex-start w-full lg:w-[70%] ">
              <h1 className="font-bold">Search for a job</h1>
              <SearchBar onSearch={setFilters} onClear={setFilters} />
            </div>
            <div className="flex flex-col justify-center gap-y-2 p-0 w-full lg:w-[70%] mt-4">
              <p>
                <span className="font-bold">{users?.length}</span> match your
                criteria
              </p>
              <div className="grid grid-cols-1 lg:grid-cols-2  gap-x-5 text-black relative w-full mt-4 align-center">
                {isDataLoading ? (
                  <DotLoader color="#868bff" />
                ) : (
                  users?.map((candidate, i) => (
                    <CandidateCard
                      key={i}
                      data={candidate}
                      origin="candidateboard"
                      onLike={handleLike(candidate.id)}
                      onMessage={handleMessage(candidate.id)}
                    />
                  ))
                )}
              </div>
            </div>
          </div>
        </div>
      </Layout>
      <WhichJobModal
        show={Boolean(candidateToBeLiked)}
        onClose={() => setCandidateToBeLiked(null)}
        onJobSelect={async (jobId) => {
          await candidateSave(userData.uid, candidateToBeLiked, jobId);
        }}
      >
        Select the job you want to like the candidate for:
      </WhichJobModal>
      <WhichJobModal
        show={Boolean(candidateToBeMessaged)}
        onClose={() => setCandidateToBeMessaged(null)}
        onJobSelect={(jobId) => {
          navigateToConversation(candidateToBeMessaged, jobId);
        }}
      >
        Select the job you want to message the candidate about:
      </WhichJobModal>
    </>
  );
};

export default RecruiterCandidatesPage;
