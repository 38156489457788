import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import React, { useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { AiOutlineUpload } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CreatableSelect from "react-select/creatable";
import { updateInitialData } from "../../app/initialDataSlice";
import { storage } from "../../config/firebase.js";
import { citiesOptions, languagesOptions } from "../../constants/Data.js";
import { IMAGES } from "../../constants/images.jsx";
import styles from "./CandidateData.module.css";

const button_text = "Next";

const CandidateData0 = () => {
  const dispatch = useDispatch();
  const initialData = useSelector((state) => state.initialData);

  const [profileImg, setProfileImg] = useState("");
  const [percentPI, setPercentPI] = useState(0);
  const [fileReady, setFileReady] = useState(true);
  const [filePicked, setIsFilePicked] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(false);
  const [firstNameValidation, setFirstNameValidation] = useState(false);
  const [lastNameValidation, setLastNameValidation] = useState(false);
  const [validated, setValidated] = useState(false);
  const activeClass =
    "bg-[#868BFF] py-2 md:py-1 rounded-md w-[45%] md:w-full border-1 border-[#868BFF] cursor-pointer ";
  const casualClass =
    "py-2 md:py-1 rounded-md w-[45%] md:w-full border-1 border-[#868BFF] cursor-pointer ";

  const handleChangePI = (event) => {
    event.preventDefault();
    setFileReady(false);
    const file = event.target.files[0];
    if (!file) {
      return;
    } else {
      if (event.target.files[0].size > 10485760) {
        alert("The file is too big! Compress it below 10MB");
      } else {
        setIsFilePicked(true);
        const storageRef = ref(
          storage,
          `profileImgs/${event.target.files[0].size}${file.name}`
        );
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            setPercentPI(progress);
          },
          (error) => {
            alert(error);
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              setUploadedFile(downloadURL);
              setProfileImg(downloadURL);
              dispatch(updateInitialData({ profileImg: downloadURL }));
              setFileReady(true);
            });
          }
        );
      }
    }
  };

  const handleChangeLanguages = (selectedOptions) => {
    const newLanguages = selectedOptions
      ? selectedOptions.map((option) => option.label)
      : [];
    dispatch(updateInitialData({ selectedLanguages: newLanguages }));
  };

  const handleChangeBonusLanguages = (selectedOptions) => {
    const newLanguages = selectedOptions
      ? selectedOptions.map((option) => option.label)
      : [];
    dispatch(updateInitialData({ selectedBonusLanguages: newLanguages }));
  };

  const skipWindow = (e) => {
    navigate("/candidatedata1");
  };
  const nextWindow = (e) => {
    // validation check
    if (!initialData.name) {
      setFirstNameValidation(true);
      setTimeout(() => {
        setFirstNameValidation(false);
      }, 3000);
      return;
    }
    if (!initialData.surname) {
      setLastNameValidation(true);
      setTimeout(() => {
        setLastNameValidation(false);
      }, 3000);
      return;
    }

    navigate("/candidatedata1");
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };

  const navigate = useNavigate();

  const tooltipContent =
    "Select your job-seeking status: Actively looking or Casually looking. When you (potentially) match with a job, law firms will see your current status. You can change this whenever you want.";

  return (
    <div className="flex flex-column items-center h-screen text-white overflow-y-auto relative z-0">
      {/* Box Container */}
      <div className={styles.boxcontainer}>
        <img
          src={IMAGES.JLFULL}
          className="w-18 h-10 mb-0"
          alt="JustLawyers logo"
        />

        <h3 className="w-[30%] text-left font-bold uppercase text-[#738397]">
          Candidate
        </h3>
        {/* <p>Candidate Details</p> */}
        <div className={styles.innercontainer}>
          <div className="text-start mt-1 ">
            <p>
              Fill out your profile to unlock personalized matches tailored just
              for you!
            </p>
            {/* <p className=" my-2">Let's start with personal details</p> */}
            <p className=" text-gray-500 my-1">
              Don’t worry! Law firms won’t have access to this information
              unless you give your permission.
            </p>
          </div>
          <p className={styles.p}>First Name*</p>

          <input
            className={styles.input}
            placeholder="Type first name"
            value={initialData.name}
            required
            onChange={(e) =>
              dispatch(updateInitialData({ name: e.target.value }))
            }
          />
          {firstNameValidation && (
            <>
              <p className="text-red-400">Please enter your first name</p>
            </>
          )}
          <p className={styles.p}>Last Name*</p>
          <input
            className={styles.input}
            placeholder="Type last name"
            value={initialData.surname}
            required
            onChange={(e) =>
              dispatch(updateInitialData({ surname: e.target.value }))
            }
          />

          {lastNameValidation && (
            <>
              <p className="text-red-400">Please enter your last name</p>
            </>
          )}
          <div className="w-full mt-2">
            <label>City</label>
            <CreatableSelect
              isClearable
              className="basic-single"
              classNamePrefix="select"
              defaultValue={""}
              name="location"
              options={citiesOptions}
              onChange={(selectedOption) => {
                const newLocation = selectedOption ? selectedOption.label : "";
                dispatch(updateInitialData({ location: newLocation }));
              }}
              value={
                initialData.location
                  ? { label: initialData.location, value: initialData.location }
                  : null
              }
            />
          </div>

          <div className="w-full mt-2">
            <label>Languages</label>
            <CreatableSelect
              isClearable
              defaultValue={[]}
              isMulti
              name="languages"
              options={languagesOptions}
              className="basic-multi-select"
              classNamePrefix="select"
              value={initialData?.selectedLanguages?.map((lang) => ({
                label: lang,
                value: lang,
              }))}
              onChange={handleChangeLanguages}
            />
          </div>

          {/* Optional languages */}
          <div className="flex flex-column mt-2 w-100">
            <p>Secondary Languages</p>
            <div className="w-full mb-2">
              <CreatableSelect
                isClearable
                className="basic-multi-select"
                classNamePrefix="select"
                defaultValue={[]}
                isMulti
                placeholder="Select Languages"
                name="languages"
                options={languagesOptions}
                value={
                  initialData.selectedBonusLanguages
                    ? initialData.selectedBonusLanguages.map((lang) => ({
                        label: lang,
                        value: lang,
                      }))
                    : null
                }
                onChange={handleChangeBonusLanguages}
              />
            </div>
          </div>

          <p className={styles.p}>Profile Image*</p>
          <div className="flex justify-between w-100 my-1">
            <label
              for="profileImg"
              className="flex items-center justify-center bg-[#D8E0EB] rounded-md text-black py-2 cursor-pointer w-100"
            >
              {/* <img src={download_icon} className="mr-3" /> */}
              <AiOutlineUpload size={22} className="mr-3" />
              Click to upload profile Image | {percentPI} "% done"
            </label>
            <input
              id="profileImg"
              type="file"
              name="profileImg"
              className="invisible w-[10px]"
              onChange={handleChangePI}
              accept=".png, .jpg, .jpeg"
            />
          </div>

          <p className={styles.p}>About me</p>
          <textarea
            className={styles.input}
            placeholder="Write a description"
            value={initialData.description}
            onChange={(e) =>
              dispatch(updateInitialData({ description: e.target.value }))
            }
          />

          <div className="mt-3 w-full">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <p className="text-start">What is your status? &#8205; </p>
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip placement="right" className="in" id="tooltip-right">
                    {tooltipContent}
                  </Tooltip>
                }
              >
                <img src={IMAGES.QUESTIONMARK} height={17.5} width={17.5} />
              </OverlayTrigger>
            </div>
            <div className="flex justify-between gap-x-2 mt-2 text-sm">
              <button
                className={
                  initialData.searchingStatus === "actively looking"
                    ? activeClass
                    : casualClass
                }
                onClick={() => {
                  dispatch(
                    updateInitialData({ searchingStatus: "actively looking" })
                  );
                }}
              >
                Actively looking
              </button>
              <button
                className={
                  initialData.searchingStatus === "actively looking"
                    ? casualClass
                    : activeClass
                }
                onClick={() => {
                  dispatch(
                    updateInitialData({ searchingStatus: "casually looking" })
                  );
                }}
              >
                Casually looking
              </button>
            </div>
          </div>

          {/* ------------------------ Next Page ------------------------*/}
          <div className={styles.bottombar}>
            <button
              className={styles.buttonskip}
              onClick={(e) => skipWindow(e)}
            >
              Skip
            </button>
            <p className="flex-1 text-center">1/3</p>

            <button
              className={styles.buttonnext}
              onClick={(e) => nextWindow(e)}
            >
              {button_text}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CandidateData0;
