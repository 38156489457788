import { IMAGES } from "../constants/images.jsx";
import { CiLocationOn } from "react-icons/ci";
import { PiSuitcaseLight } from "react-icons/pi";

const RecruiterProfileJobCard = ({ jobData }) => {
  return (
    <div className="flex flex-col md:flex-row justify-center md:justify-between mt-3 text-white border-b-[.8px] border-[#131F2E]  px-4 py-3">
      <div className="flex justify-between items-center gap-x-5">
        <div>
          <img
            src={jobData.firmLogo ? jobData.firmLogo : IMAGES.FIRM_LOGO}
            alt="firm logo"
            className="w-12 h-12 md:w-16 md:h-16 rounded-full border-1 border-gray-400"
          />
        </div>
        <div>
          <p className="font-bold">{jobData.jobTitle}</p>
          <p className="text-xs text-gray-400 mt-1">
            Poster <span>15</span> days ago
          </p>
        </div>
      </div>
      <div className=" mr-6 w-full md:w-[15%]">
        <p className="flex items-center mt-2">
          <CiLocationOn className="mr-2" />
          {jobData?.jobCities}
        </p>
        <p className="flex items-center ">
          <PiSuitcaseLight className="mr-2" />
          {jobData?.jobExperienceMin}-{jobData?.jobExperienceMax} years
        </p>
      </div>
    </div>
  );
};

export default RecruiterProfileJobCard;
