import React, { useState } from "react";
import Modal from "react-bootstrap/Modal"; // import { Modal, Button } from "react-bootstrap"
import Row from "react-bootstrap/Row";
import { IMAGES } from "../../constants/images.jsx";
import ApplicationPreview from "./ApplicationPreview.jsx";
import Form from "react-bootstrap/Form";

import { updateInitialData } from "../../app/initialDataSlice.js";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "../../config/firebase.js";

const ModalCandidateJobApply = ({
  showModal,
  handleClose,
  setApplied,
  job_id,
}) => {
  const dispatch = useDispatch();
  const initialData = useSelector((state) => state.initialData);

  const [nextPage, setNextPage] = useState(false);
  const [cvNew, setCVNew] = useState(false);
  const [cv, setCv] = useState("");
  const [coverLetter, setCoverLetter] = useState("");

  const [newCoverLetter, setNewCoverLetter] = useState(false);

  const [percentCV, setPercentCV] = useState(0);
  const [percentCL, setPercentCL] = useState(0);
  const [percentT, setPercentT] = useState(0);

  const [fileReady, setFileReady] = useState(true);
  const [filePicked, setIsFilePicked] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(false);

  const handelUploadCV = (event) => {
    setCVNew(true);
    console.log("uploadCV");
    handleChangeCV(event);
  };

  const handelUploadCL = (event) => {
    setNewCoverLetter(true);
    console.log("uploadCL");
    handleChangeCL(event);
  };

  const handleChangeCV = (event) => {
    // setCv(event.target.files[0])

    setFileReady(false);
    const file = event.target.files[0];
    if (!file) {
      return;
    } else {
      if (event.target.files[0].size > 10485760) {
        alert("The file is too big! Compress it below 10MB");
      } else {
        // setCv(event.target.files[0])
        setIsFilePicked(true);
        const storageRef = ref(
          storage,
          `cvs/${event.target.files[0].size}${file.name}`
        );
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            // console.log(progress)
            setPercentCV(progress);
          },
          (error) => {
            alert(error);
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              setUploadedFile(downloadURL);
              setCv(file.name);
              dispatch(updateInitialData({ cv: downloadURL }));
              dispatch(updateInitialData({ cvName: file.name }));
              setFileReady(true);
            });
          }
        );
      }
    }
  };

  const handleChangeCL = (event) => {
    // setCoverLetter(event.target.files[0])

    setFileReady(false);
    const file = event.target.files[0];
    if (!file) {
      return;
    } else {
      if (event.target.files[0].size > 10485760) {
        alert("The file is too big! Compress it below 10MB");
      } else {
        // setCoverLetter(event)
        setIsFilePicked(true);
        const storageRef = ref(
          storage,
          `coverLetters/${event.target.files[0].size}${file.name}`
        );
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            // console.log(progress)
            setPercentCL(progress);
          },
          (error) => {
            alert(error);
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              setUploadedFile(downloadURL);
              setCoverLetter(file.name);
              dispatch(updateInitialData({ coverLetter: downloadURL }));
              dispatch(updateInitialData({ clName: file.name }));

              setFileReady(true);
            });
          }
        );
      }
    }
  };

  return (
    <>
      <Modal
        show={showModal}
        onHide={handleClose}
        className="flex justify-center items-center text-black"
        centered
      >
        <Modal.Body className="flex justify-center items-center">
          <Row className="flex flex-column justify-center bg-white rounded-md p-3">
            {nextPage ? (
              <>
                <ApplicationPreview
                  backBtn={() => setNextPage(false)}
                  handleClose={handleClose}
                  setApplied={(e) => setApplied(e)}
                />
              </>
            ) : (
              <>
                <Row className="text-black text-center mt-3">
                  <p className="font-bold text-2xl mb-1">Ready To Apply?</p>
                  <p>Pick how you want to apply below</p>
                </Row>
                {/* attachments options */}
                <Row className="flex flex-column items-center my-5 ">
                  {/* upper */}
                  <Row className="flex justify-around ">
                    <Row className="flex justify-center items-center border-b-2 border-gray-300 p-1  w-[133px] ">
                      <Row
                        className={`flex flex-column items-center text-center p-1 cursor-pointer rounded-md w-[133px] hover:scale-105 flex-1 ${
                          cvNew ? "border-2 bg-[#F1F1F5]" : ""
                        }`}
                      >
                        <label
                          className="flex flex-column items-center gap-y-2 cursor-pointer "
                          for="newCV"
                        >
                          <img className="" src={IMAGES.BOOK} />
                          {cv == "" ? (
                            <>
                              Upload a new <br />
                              CV
                            </>
                          ) : (
                            <>
                              {cv.substring(0, 8)}
                              {cv.length > 8 && "..."}
                            </>
                          )}
                        </label>
                        <Form.Control
                          type="file"
                          id="newCV"
                          className="invisible"
                          onChange={handelUploadCV}
                          style={{ display: "none" }} // Alternatively, you can use inline style
                        />
                      </Row>
                    </Row>
                    <Row className="flex justify-center items-center border-b-2 border-gray-300 p-1  w-[133px] ">
                      <Row
                        className={`flex flex-column items-center text-center p-1 cursor-pointer rounded-md w-[133px] hover:scale-105 flex-1 ${
                          newCoverLetter ? "border-2 bg-[#F1F1F5]" : ""
                        }`}
                      >
                        <Form.Control
                          type="file"
                          id="newCL"
                          className="invisible"
                          onChange={handelUploadCL}
                          style={{ display: "none" }} // Alternatively, you can use inline style
                        />
                        <label
                          className="flex flex-column items-center gap-y-2  cursor-pointer "
                          for="newCL"
                        >
                          <img className="" src={IMAGES.BOOK} />
                          {coverLetter == "" ? (
                            <>
                              Upload Cover <br /> Letter
                            </>
                          ) : (
                            <>
                              {coverLetter.substring(0, 8)}
                              {coverLetter.length > 8 && "..."}
                            </>
                          )}
                        </label>
                      </Row>
                    </Row>
                  </Row>
                  {/* lower */}
                  <Row className="flex justify-around mt-4">
                    <Row
                      className={`flex flex-column items-center justify-center text-center p-1 gap-y-2 cursor-pointer rounded-md w-[32%] hover:scale-105
                      ${!cvNew ? "bg-[#F1F1F5]" : ""}`}
                      onClick={() => setCVNew(false)}
                    >
                      <img className="h-12 w-16" src={IMAGES.CONTAINER} />
                      <p>
                        Use the profile <br />
                        saved CV
                      </p>
                    </Row>
                    <Row
                      className={`flex flex-column items-center justify-center text-center p-1 cursor-pointer rounded-md w-[32%] hover:scale-105
                      ${!newCoverLetter ? "" : ""}`}
                      onClick={() => {
                        setNewCoverLetter(false);
                        dispatch(updateInitialData({ coverLetter: "" }));
                        setNextPage(true);
                      }}
                    >
                      <img className="h-6 w-16" src={IMAGES.R_ARROW} />
                      <p className="mt-2">Skip</p>
                    </Row>
                  </Row>
                </Row>
                {/* buttons */}
                <Row className="flex flex-column items-center gap-y-3 mb-3">
                  <button
                    className="bg-[#46D6EA] w-75 py-2 rounded-md font-bold  border-1 border-[#46D6EA] cursor-pointer hover:bg-transparent"
                    onClick={() => setNextPage(true)}
                  >
                    Click to continue
                  </button>
                  <button
                    className="  py-2 rounded-md w-75 font-bold  border-1 border-black cursor-pointer hover:bg-transparent"
                    onClick={handleClose}
                  >
                    Cancel
                  </button>
                </Row>
              </>
            )}
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalCandidateJobApply;
