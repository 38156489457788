import styles from "./CandidateData.module.css";
import { Modal, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { updateInitialData } from "../../app/initialDataSlice";
import { saveData } from "../../app/dataSlice.js";
import { editUser } from "../../app/functions.js";

const CandidateAboutModal = ({ show, handleClose }) => {
  const initialData = useSelector((state) => state.initialData);
  const dispatch = useDispatch();
  const save = async () => {
    const data = initialData;
    const uid = initialData.uid;
    saveData(uid, data);
    await editUser(data, dispatch, saveData);
    handleClose();
  };
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header>
        <Modal.Title>Personal info</Modal.Title>
      </Modal.Header>
      <Modal.Body className={styles.modalbod}>
        <div className={styles.innercontainer}>
          <div className="w-full">
            <p className={styles.p}>About me</p>
            <textarea
              className={styles.input}
              placeholder="Write a description"
              value={initialData.description}
              onChange={(e) =>
                dispatch(updateInitialData({ description: e.target.value }))
              }
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="flex items-center justify-between">
        <Button
          variant=""
          className="border-[1px] border-black"
          onClick={handleClose}
        >
          Close
        </Button>
        <Button variant="" className="bg-[#46D6EA] font-bold" onClick={save}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CandidateAboutModal;
