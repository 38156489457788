import { AiOutlinePlus } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { DotLoader } from "react-spinners";
import Layout from "../../components/Layout";
import MyJobCard from "../../components/MyJobCard";
import useJobs from "../../hooks/useJobs";
import { useSelector } from "react-redux";

const JobBoardRecruiter = () => {
  const initialData = useSelector((state) => state.initialData);
  const navigate = useNavigate();
  const { jobs, isDataLoading } = useJobs({ userId: initialData.uid });

  const handleCreateJob = () => {
    navigate("/createJob");
  };

  const handleShowJobPage = (job, page) => {
    navigate(`/jobcardpage/${job.uid}`, {
      state: {
        defaultTab: page,
      },
    });
  };

  return (
    <Layout>
      <div className="flex flex-column items-center text-white">
        <div className="flex flex-column my-5 mx-3 w-[90%] gap-x-5">
          <div className="flex justify-between items-center w-full">
            <div>
              <h2 className="font-bold">My Jobs</h2>
              {!isDataLoading && (
                <p>
                  You have <span>{jobs.length}</span> active jobs
                </p>
              )}
            </div>
            <div>
              <button
                className="flex items-center bg-yellow-400 py-1 px-3 rounded-md border-1 border-yellow-400 text-black font-bold hover:bg-transparent hover:text-yellow-400"
                onClick={handleCreateJob}
              >
                <AiOutlinePlus className="mr-2" />
                New Job
              </button>
            </div>
          </div>
          {isDataLoading ? (
            <DotLoader color="#868bff" />
          ) : (
            jobs.map((job) => (
              <div key={job.uid} className="mt-4 h-max lg:h-20 mb-2">
                <MyJobCard
                  handleShowJobPage={(tab) => handleShowJobPage(job, tab)}
                  jobData={job}
                />
              </div>
            ))
          )}
        </div>
      </div>
    </Layout>
  );
};

export default JobBoardRecruiter;
