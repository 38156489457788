import React, { useState } from "react";
import { AiFillEdit } from "react-icons/ai";
import { BsArrowRightShort } from "react-icons/bs";
import { CiLocationOn } from "react-icons/ci";
import { IoMdCheckmark } from "react-icons/io";
import { PiCaretCircleLeftFill } from "react-icons/pi";
import { Link, useParams } from "react-router-dom";
import EditJobModal from "../../components/editJobModal/EditJobModal.jsx";
import { IMAGES } from "../../constants/images.jsx";
import useFetchJob from "../../hooks/useFetchJob.js";
import NavbarRec from "../../utils/NavBarRec";

const JobOverview = () => {
  const [showModal, setShowModal] = useState(false);
  const { id } = useParams();

  const { job, jobIsLoading } = useFetchJob(id);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  return (
    <>
      <NavbarRec />
      <div className="flex flex-column items-center text-white overflow-y-scroll relative">
        <div className="flex items-center mt-1 md:mt-5 mb-2 mx-0 lg:mx-3 w-[120%] md:w-[110%] lg:w-[70%] md:px-0 lg:px-5">
          <div className=" px-5 ml-0 md:ml-[1.25rem] lg:ml-[3.25rem]">
            <Link to="/JobBoardRecruiter" className="flex items-center">
              <PiCaretCircleLeftFill
                size={38}
                className="mr-2 text-blue-400 cursor-pointer duration-75	 hover:scale-105"
              />
              Back to All Jobs
            </Link>
          </div>
        </div>
        <div className="flex flex-column gap-y-3 items-center justify-center mt-2 mx-3 w-[120%] lg:w-[70%] text-[#131F2E] gap-x-5 ">
          {!jobIsLoading && (
            <>
              <div className=" w-75 bg-white rounded-md text-black h-fit mr-2   ">
                {/* background image */}
                <div className=" relative ">
                  <img
                    src="https://images.unsplash.com/photo-1471039497385-b6d6ba609f9c?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    className="w-full h-[200px] rounded-t-md"
                  />
                  {/* firm logo */}
                  <div className="flex justify-center md:justify-start w-full absolute  -bottom-10 px-4">
                    <img
                      src={job.firmLogo ?? IMAGES.FIRM_LOGO}
                      className="w-24 h-24 border-3 border-white rounded-full"
                    />
                  </div>
                  {/* edit icon */}
                  <div className="absolute text-white top-5 right-4">
                    <AiFillEdit
                      size={24}
                      className="cursor-pointer"
                      onClick={handleShow}
                    />
                  </div>
                </div>

                {/* job info */}
                <div className="px-3 lg:px-5 py-2 mt-5 ">
                  <div className="flex flex-col md:flex-row justify-between items-center">
                    <div>
                      {" "}
                      <p className="font-bold">{job.jobTitle}</p>
                      <p className="flex items-center">
                        <CiLocationOn className="mr-2" />
                        {job.jobCities.join(" / ")}
                      </p>
                    </div>

                    <p className="flex items-center gap-x-1 text-xs">
                      View all{" "}
                      <span className="font-bold flex items-center cursor-pointer">
                        analytics <BsArrowRightShort />
                      </span>
                    </p>
                  </div>
                  <div className="flex flex-col md:flex-row justify-end gap-x-2 text-xs mt-3 text-[#1B1F87]/50">
                    {job.jobExpertise.map((label, index) => (
                      <p className="flex items-center" key={index}>
                        <IoMdCheckmark className="mr-1" />
                        {label}
                      </p>
                    ))}
                  </div>
                </div>
                {/* Job Description */}
                <div className="px-3 lg:px-5 py-2 border-t-2 border-gray-200 my-2">
                  <h4 className="text-[#131F2E]/50 uppercase my-2 font-bold">
                    full description
                  </h4>
                  <div
                    className="list-disc"
                    dangerouslySetInnerHTML={{
                      __html: job?.jobDescription,
                    }}
                  />
                </div>
              </div>
              <div className="w-75 bg-[#F0F0F4] rounded-md text-black h-fit mr-2   ">
                {/* Job Requirement */}
                <div className="px-3 lg:px-5 py-2 my-2 ">
                  <h4 className="text-[#131F2E]/50 uppercase my-2 font-bold">
                    REQUIREMENTS
                  </h4>
                  <p className="text-justify">
                    <ul className="list-disc px-4">
                      <li>Expertise: {job.jobExpertise.join(", ")}</li>
                      <li>Minimum experience: {job.jobExperienceMin}</li>
                      <li>Maximum experience: {job.jobExperienceMax}</li>
                      <li>Required languages: {job.jobLanguages.join(", ")}</li>
                      <li>
                        Bonus languages: {job.jobBonusLanguages.join(", ")}
                      </li>
                    </ul>
                  </p>
                </div>
                {/* additional info */}
                <div className="px-3 lg:px-5 py-2 border-t-2 border-gray-200 my-2 ">
                  <h4 className="text-[#131F2E]/50 uppercase my-2 font-bold">
                    additional information
                  </h4>
                  <div
                    className="list-disc"
                    dangerouslySetInnerHTML={{
                      __html: job?.jobAdditionalDescription,
                    }}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {!jobIsLoading && (
        <EditJobModal
          showModal={showModal}
          handleClose={handleClose}
          job={job}
        />
      )}
    </>
  );
};

export default JobOverview;
