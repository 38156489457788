import React, { useState } from "react";
import styles from "./CandidateData.module.css";
import { Modal, Button } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { IMAGES } from "../../constants/images.jsx";
import download_icon from "../../assets/icons/Upload.svg";
import { storage } from "../../config/firebase.js";
import { useDispatch, useSelector } from "react-redux";
import { updateInitialData } from "../../app/initialDataSlice";
import { saveData } from "../../app/dataSlice.js";
import { editUser } from "../../app/functions.js";

import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

const CandidateDocsModal = ({ show, handleClose }) => {
  const [cv, setCv] = useState("");
  const [coverLetter, setCoverLetter] = useState("");
  const [transcripts, setTranscripts] = useState("");

  const [percentCV, setPercentCV] = useState(0);
  const [percentCL, setPercentCL] = useState(0);
  const [percentT, setPercentT] = useState(0);

  const [fileReady, setFileReady] = useState(true);
  const [filePicked, setIsFilePicked] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(false);

  const { state } = useLocation();
  const loggedUser = useSelector((state) => state.data.value);
  const initialData = useSelector((state) => state.initialData);
  const dispatch = useDispatch();

  const handleChangeCV = (event) => {
    // setCv(event.target.files[0])

    setFileReady(false);
    const file = event.target.files[0];
    if (!file) {
      return;
    } else {
      if (event.target.files[0].size > 10485760) {
        alert("The file is too big! Compress it below 10MB");
      } else {
        // setCv(event.target.files[0])
        setIsFilePicked(true);
        const storageRef = ref(
          storage,
          `cvs/${event.target.files[0].size}${file.name}`
        );
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            // console.log(progress)
            setPercentCV(progress);
          },
          (error) => {
            alert(error);
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              setUploadedFile(downloadURL);
              setCv(downloadURL);
              dispatch(updateInitialData({ cv: downloadURL }));
              setFileReady(true);
            });
          }
        );
      }
    }
  };
  const handleChangeCL = (event) => {
    // setCoverLetter(event.target.files[0])

    setFileReady(false);
    const file = event.target.files[0];
    if (!file) {
      return;
    } else {
      if (event.target.files[0].size > 10485760) {
        alert("The file is too big! Compress it below 10MB");
      } else {
        // setCoverLetter(event.target.files[0])
        setIsFilePicked(true);
        const storageRef = ref(
          storage,
          `coverLetters/${event.target.files[0].size}${file.name}`
        );
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            // console.log(progress)
            setPercentCL(progress);
          },
          (error) => {
            alert(error);
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              setUploadedFile(downloadURL);
              setCoverLetter(downloadURL);
              dispatch(updateInitialData({ coverLetter: downloadURL }));
              setFileReady(true);
            });
          }
        );
      }
    }
  };
  const handleChangeTranscripts = (event) => {
    // setTranscripts(event.target.files[0])
    setFileReady(false);
    const file = event.target.files[0];
    if (!file) {
      return;
    } else {
      if (event.target.files[0].size > 10485760) {
        alert("The file is too big! Compress it below 10MB");
      } else {
        // setTranscripts(event.target.files[0])
        setIsFilePicked(true);
        const storageRef = ref(
          storage,
          `transcripts/${event.target.files[0].size}${file.name}`
        );
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            // console.log(transcripts)
            setPercentT(progress);
          },
          (error) => {
            alert(error);
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              setUploadedFile(downloadURL);
              setTranscripts(downloadURL);
              dispatch(updateInitialData({ transcripts: downloadURL }));
              setFileReady(true);
            });
          }
        );
      }
    }
  };
  const save = async () => {
    const data = initialData;
    const uid = initialData.uid;
    saveData(uid, data);

    await editUser(data, dispatch, saveData);
    handleClose();
  };
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header>
        <Modal.Title>Uploaded Documents</Modal.Title>
      </Modal.Header>
      <Modal.Body className={styles.modalbod}>
        <div className={styles.innercontainer}>
          <div className="w-full">
            <p className={styles.textreg}>
              Please provide your current or most relevant application
              documents.
            </p>
            {/* cv */}
            <div className="flex justify-between w-100 my-2">
              <label
                for="cv"
                className="flex items-center justify-center bg-[#374b64] rounded-md text-white py-2 cursor-pointer w-100"
                onClick={() => {
                  console.log("load");
                }}
              >
                <img src={download_icon} className="mr-3" />
                Click to upload CV | {percentCV} "% done"
              </label>
              <input
                id="cv"
                type="file"
                name="cv"
                className="invisible w-[10px]"
                onChange={handleChangeCV}
                accept=".pdf, .txt, .doc, .docx"
              />
            </div>
            {/* cover letter */}
            <div className="flex justify-between w-100 my-2">
              <label
                for="coverLetter"
                className="flex items-center justify-center bg-[#374b64] rounded-md text-white py-2 cursor-pointer w-100"
              >
                <img src={download_icon} className="mr-3" />
                Click to Upload Cover Letter | {percentCL} "% done"
              </label>

              <input
                id="coverLetter"
                type="file"
                className="invisible w-[10px]"
                onChange={handleChangeCL}
                accept=".pdf, .txt, .doc, .docx"
              />
            </div>
            {/* transcript */}
            <div className="flex justify-between w-100 my-2">
              <label
                for="transcripts"
                className="flex items-center justify-center bg-[#374b64] rounded-md text-white py-2 cursor-pointer w-100"
              >
                <img src={download_icon} className="mr-3" />
                Click to Upload Transcripts | {percentT} "% done"
              </label>
              <input
                id="transcripts"
                type="file"
                className="invisible w-[10px]"
                onChange={handleChangeTranscripts}
                accept=".pdf, .txt, .doc, .docx"
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="flex items-center justify-between">
        <Button
          variant=""
          className="border-[1px] border-black"
          onClick={handleClose}
        >
          Close
        </Button>
        <Button variant="" className="bg-[#46D6EA] font-bold" onClick={save}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CandidateDocsModal;
