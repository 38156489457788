import { Alert } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { PiCaretCircleLeftFill } from "react-icons/pi";
import { Link } from "react-router-dom";
import { DotLoader } from "react-spinners";
import { IMAGES } from "../../../constants/images";
import styles from "./LoginPage.module.css";
import useLogin from "../../../hooks/useLogin";
import { store } from "../../../app/store";
import { login } from "../../../app/userRedux/userSlice.js";

const LoginPage = () => {
  const { loginUser } = useLogin();

  const {
    formState: { errors, isSubmitting },
    handleSubmit: handleFormSubmit,
    register,
    setError,
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const handleSubmit = handleFormSubmit(async (formData) => {
    loginUser(formData, {
      onSuccess: ({ authData, userData }) => {
        store.dispatch(login({ authData, userData }));
      },
      onError: (error) => {
        if (
          [
            "auth/invalid-email",
            "auth/missing-password",
            "auth/invalid-credential",
          ].includes(error)
        ) {
          setError("root.authServerError", {
            type: "invalidCredentials",
            message: "Invalid credentials. Try again.",
          });
        } else {
          setError("root.authServerError", {
            type: "invalidCredentials",
            message: error,
          });
        }
      },
    });
  });

  return (
    <div className={styles.container}>
      <div className={styles.boxcontainerBtn}>
        <Link to="/" className="flex items-center text-white">
          <PiCaretCircleLeftFill
            size={38}
            className="mr-2 text-blue-400 cursor-pointer duration-75 hover:scale-110"
          />
          <p className=""> Back to landing page</p>
        </Link>
      </div>
      <div className={styles.boxcontainer}>
        <img
          src={IMAGES.JLFULL}
          className="w-18 h-10 mb-8"
          alt="JustLawyers logo"
        />
        <div className={styles.innercontainer}>
          <p className="text-left mb-2">Log in</p>
          <form onSubmit={handleSubmit}>
            {errors.root?.authServerError && (
              <Alert variant="danger">
                {errors.root.authServerError.message}
              </Alert>
            )}
            <div>
              <input
                type="text"
                id="email"
                placeholder="Email"
                className={styles.input}
                {...register("email")}
              />
            </div>
            <div>
              <input
                type="password"
                id="password"
                placeholder="Password"
                className={styles.input}
                {...register("password")}
              />
            </div>
            <button
              type="submit"
              disabled={isSubmitting}
              className={styles.button}
            >
              {isSubmitting ? (
                <DotLoader color="#ffffff" size={20} />
              ) : (
                <>Log in</>
              )}
            </button>
          </form>
        </div>
        <Link to="/auth/register" className={styles.createAccount}>
          <p>Create an account?</p>
        </Link>
      </div>
    </div>
  );
};

export default LoginPage;
