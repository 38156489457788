import { createSlice } from "@reduxjs/toolkit";
import { E_JOB_STATUS } from "../constants/enums";
import { initialise, login, logout } from "./userRedux/userSlice";

const initialState = {
  uid: null,
  email: "",
  name: "",
  surname: "",
  location: "",
  searchingStatus: "",
  profileImg: "",
  selectedLanguages: [],
  selectedBonusLanguages: [],
  queryLoca: "",
  queryFirmType: "",
  queryExpertise: "",
  queryAnnual: "",
  queryNotMatching: "",
  sliderValue: "",
  cities: [],
  annualRemuneration: "",
  annualRemunerationMin: "",
  annualRemunerationMax: "",
  expertise: [],
  grades: "",
  dateOfGradutation: "",
  dateOfGradutationLLM: "",
  university: [],
  master: [],
  cv: "",
  coverLetter: "",
  transcripts: "",
  employmentHistory: [
    {
      lawfirmname: "",
      positiontitle: "",
      employmentend: "",
      employmentstart: "",
      remuneration: "",
      stillEmployed: false,
      notOnTheList: false,
    },
  ],
  tomatch: [],
  nottomatch: [],
  description: "",
  experience: "",
  applied: [],
  matches: [],
  saved: [],
  rejected: [],

  // Here data unique to recruiters:
  companyName: "",
  role: "",
  phone: "",
  firmLogo: "",
  firmFounded: "",
  employeesList: [
    {
      employeeFullName: "",
      employeeTitle: "",
      employeeEmail: "",
    },
  ],
  aboutCompany: "",
  aboutEmployee: "",
  firmLocation: "",
  selectedLocations: [],
  firmWebsite: "",
  firmSize: "",

  companyFounded: "",
  jobTitle: "",
  jobCities: [],
  jobBudget: "",
  jobStatus: E_JOB_STATUS.active,
  minJobBudget: "",
  maxJobBudget: "",
  jobDescription: "",
  jobAdditionalDescription: "",
  jobExperienceMin: 0,
  jobExperienceMax: 7,
  jobExpertise: [],
  jobLanguages: [],
  jobBonusLanguages: [],
  cvName: "",
  clName: "",
  isEmployer: false,

  // Below are the previewed items

  selectedJob: {},
  showUserCreatedModal: false,
};

const initialDataSlice = createSlice({
  name: "initialData",
  initialState,
  reducers: {
    updateInitialData: (state, action) => {
      // Assuming action.payload is an object containing updated data
      return { ...state, ...action.payload };
    },
    resetInitialData: (state) => {
      return initialState; // Reset state to initial state
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(initialise, (state, action) => {
        return action.payload
          ? { ...state, ...action.payload.userData }
          : state;
      })
      .addCase(login, (state, action) => ({
        ...state,
        ...action.payload.userData,
      }))
      .addCase(logout, () => initialState),
});

export const { updateInitialData, resetInitialData } = initialDataSlice.actions;

export const isUIDSetInitialData = (state) => state.initialData.uid != null;

export default initialDataSlice.reducer;
