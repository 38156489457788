import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Collapsible from "react-collapsible";
import { useNavigate } from "react-router-dom";
import Switch from "react-switch";
import { IMAGES } from "../../constants/images.jsx";
import styles from "./CandidateData.module.css";
import axios from "axios";
import { FiChevronLeft } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import CreatableSelect from "react-select/creatable";
import { updateInitialData } from "../../app/initialDataSlice";
import { firmsOptions } from "../../constants/Data.js";

const button_text = "Next";
const button_back_text = "Back";

const CandidateData2 = () => {
  const dispatch = useDispatch();
  const initialData = useSelector((state) => state.initialData);

  const [noWorkExperience, setNoWorkExperience] = useState(false);
  const [openCollapsible, setOpenCollapsible] = useState(null);

  const handleCollapsibleClick = (id) => {
    setOpenCollapsible((prev) => (prev === id ? null : id));
  };
  const handleUpdateEmploymentItem = (index) => {
    // Clone the existing employmentHistory array
    const updatedEmploymentHistory = [...initialData.employmentHistory];

    // If the index is greater than or equal to the length, add a new object
    if (index >= updatedEmploymentHistory.length) {
      updatedEmploymentHistory.push({
        lawfirmname: "",
        positiontitle: "",
        employmentend: "",
        employmentstart: "",
        remuneration: "",
        stillEmployed: false,
        notOnTheList: false,
      });
    }
    // Dispatch the updated data to Redux
    dispatch(
      updateInitialData({ employmentHistory: updatedEmploymentHistory })
    );
  };

  const handleDeleteEmploymentItem = (index) => {
    const updatedEmploymentHistory = [...initialData.employmentHistory];

    const newArray = updatedEmploymentHistory.filter(
      (element) => element !== updatedEmploymentHistory[index]
    );

    // console.log(newArray)
    dispatch(updateInitialData({ employmentHistory: newArray }));
  };

  const updateEmploymentItem = (index, property, newValue) => {
    try {
      // if (property === "employmentstart" || property === "employmentend") {
      // }

      dispatch(
        updateInitialData({
          employmentHistory: [
            ...initialData.employmentHistory.slice(0, index),
            {
              ...initialData.employmentHistory[index],
              [property]: newValue,
            },
            ...initialData.employmentHistory.slice(index + 1),
          ],
        })
      );
      console.log(initialData.employmentHistory);
    } catch (error) {
      console.log("hlo");
      console.log(error);
      alert(error);
    }
  };

  const nextWindow = (e) => {
    navigate("/candidatedata4");
  };
  const previousWindow = () => {
    navigate("/candidatedata1");
  };

  const [queryLaw, setQueryLaw] = useState("");
  const [lawfirmList, setLawfirmList] = useState([]);

  const getLawList = async (name) => {
    setQueryLaw(name);

    if (name.length < 1) {
      // Don't fetch suggestions if the query is too short
      setLawfirmList([]);
      return;
    }

    try {
      const response = await axios.get(
        "http://universities.hipolabs.com/search",
        {
          params: { name },
        }
      );

      // Limit the number of suggestions to 5
      const limitedData = response.data.slice(0, 2);
      setLawfirmList(limitedData);
    } catch (error) {
      console.error("Error fetching universities:", error);
    }
  };

  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      {/* Box Container */}
      <div className={styles.boxcontainer} style={{ marginTop: "0px" }}>
        <img
          src={IMAGES.JLFULL}
          className="w-18 h-10 mb-0"
          alt="JustLawyers logo"
        />
        <h3 className="w-[30%] text-left font-bold uppercase text-[#738397]">
          Candidate
        </h3>
        {/* ------------------------ Experience ------------------------*/}
        <div className={styles.innercontainer}>
          <p className={styles.p}>Experience</p>
          <p className={styles.textreg}>
            Please provide your current or most recent law firm work experience.{" "}
          </p>

          {/* ------------------------ Experience Switch ------------------------*/}
          <div className={styles.switchContainer}>
            <p className={styles.textreg} style={{ marginRight: 15 }}>
              I don't have any work experience
            </p>

            <Switch
              onChange={(e) => setNoWorkExperience(e)}
              checked={noWorkExperience}
              onColor="#86d3ff"
              onHandleColor="#2693e6"
              handleDiameter={15}
              uncheckedIcon={false}
              checkedIcon={false}
              height={15}
              width={40}
            />
          </div>

          {/* ------------------------ Experience List ------------------------*/}

          <div className={styles.experienceList}>
            {!noWorkExperience && (
              <>
                {initialData?.employmentHistory?.map((element, i) => (
                  /* ------------------------ Collabsible Example (Make into a component) ------------------------*/
                  <div className="flex w-full items-center justify-between">
                    <Collapsible
                      // className={!noWorkExperience ? "d-block" : "d-none"}
                      trigger={
                        <div className="w-100">
                          <b>Experience {i + 1}</b>
                          {/* <RiDeleteBin6Line
                            // src={IMAGES.TRASH_CAN}
                            className="ml-2 h-5 w-5 cursor-pointer z-20"
                            onClick={() => handleDeleteEmploymentItem(i)}
                          /> */}
                        </div>
                      }
                      open={openCollapsible === `collapsible${i}`}
                      onOpening={() => {
                        handleCollapsibleClick(`collapsible${i}`);
                        handleCollapsibleClick(`collapsible${i + 1}`);
                        handleCollapsibleClick(`collapsible${i - 1}`);
                      }}
                    >
                      <div
                        // className={styles.switchContainer}
                        className="w-100"
                      >
                        {/* ------------------------ My law firm is not on the list  ------------------------*/}
                        <p
                          className={styles.textreg}
                          style={{ marginRight: 15 }}
                        >
                          My law firm is not on the list
                        </p>
                        <Form>
                          <Form.Check // prettier-ignore
                            type="switch"
                            id="custom-switch"
                            // label="My law firm is not on the list"
                            onChange={(e) =>
                              updateEmploymentItem(
                                i,
                                "notOnTheList",
                                e.target.checked
                              )
                            }
                            checked={
                              initialData.employmentHistory[i].notOnTheList
                            }
                          />
                        </Form>
                      </div>
                      {/* ------------------------ Law Firm Name  ------------------------*/}
                      <div className="w-full">
                        {/* low firm list candidate doesn't want to work with */}
                        <div className=" my-2px-2 py-1 rounded-md">
                          <p>Law Firm Name</p>
                          <CreatableSelect
                            isClearable
                            placeholder="Select Law Firm..."
                            defaultValue={[]}
                            name="type"
                            options={firmsOptions}
                            className="basic-select"
                            classNamePrefix="select"
                            onChange={(e) =>
                              updateEmploymentItem(i, "lawfirmname", e)
                            }
                            value={initialData.employmentHistory[i].lawfirmname}
                          />
                        </div>
                      </div>
                      {/* ------------------------ Position title  ------------------------*/}
                      <p className={styles.p}>Position title</p>
                      <input
                        className={styles.input}
                        placeholder="Select Position"
                        onChange={(e) =>
                          updateEmploymentItem(
                            i,
                            "positiontitle",
                            e.target.value
                          )
                        }
                        value={initialData.employmentHistory[i].positiontitle}
                      />
                      {/* ------------------------ Still Employed Switch ------------------------*/}
                      <div className={styles.switchContainer}>
                        <p
                          className={styles.textreg}
                          style={{ marginRight: 15 }}
                        >
                          Still Employed
                        </p>

                        <Form>
                          <Form.Check // prettier-ignore
                            type="switch"
                            id="custom-switch"
                            // label="Still Employer"
                            onChange={(e) =>
                              updateEmploymentItem(
                                i,
                                "stillEmployed",
                                e.target.checked
                              )
                            }
                            checked={
                              initialData.employmentHistory[i].stillEmployed
                            }
                          />
                        </Form>
                      </div>
                      {/* ------------------------ Employment Start Date  ------------------------*/}
                      <p className={styles.p}>Employment Start Date</p>
                      <Form.Control
                        type="date"
                        className={styles.input}
                        placeholder="Select Date"
                        onChange={(e) =>
                          updateEmploymentItem(
                            i,
                            "employmentstart",
                            e.target.value
                          )
                        }
                        value={initialData.employmentHistory[i].employmentstart}
                      />
                      {/* ------------------------ Employment End Date  ------------------------*/}
                      <p className={styles.p}>Employment End Date</p>

                      <Form.Control
                        type="date"
                        disabled={
                          initialData.employmentHistory[i].stillEmployed
                            ? true
                            : false
                        }
                        className={styles.input}
                        placeholder="Select Date"
                        onChange={(e) =>
                          updateEmploymentItem(
                            i,
                            "employmentend",
                            e.target.value
                          )
                        }
                        value={initialData.employmentHistory[i].employmentend}
                      />
                    </Collapsible>
                    <RiDeleteBin6Line
                      size={24}
                      className="ml-2 cursor-pointer "
                      onClick={() => handleDeleteEmploymentItem(i)}
                    />
                  </div>
                ))}

                {/* ------------------------ Add Experience Button  ------------------------*/}

                <div
                  className={styles.buttonaddexp}
                  // className={
                  //   !noWorkExperience ? `${styles.buttonaddexp}` : "d-none"
                  // }
                  onClick={() =>
                    handleUpdateEmploymentItem(
                      initialData.employmentHistory.length
                    )
                  }
                >
                  <b>Add Past Experience...</b>
                  <b style={{ fontSize: 24 }}>+</b>
                </div>
              </>
            )}
          </div>

          {/* ------------------------ Back / Next ------------------------*/}
          <div className={styles.bottombar}>
            {/* ------------------------ Back ------------------------*/}
            <button className={styles.buttonback} onClick={previousWindow}>
              <FiChevronLeft size={22} />
              {button_back_text}
            </button>
            <p className="flex-1 text-center">3/3</p>
            {/* ------------------------ Next ------------------------*/}
            <div className="flex flex-1 items-center justify-between">
              <button className={styles.buttonskip} onClick={nextWindow}>
                skip
              </button>
              {/* ------------------------ Next ------------------------*/}
              <button className={styles.buttonnext} onClick={nextWindow}>
                {button_text}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CandidateData2;
