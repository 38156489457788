import { configureStore } from "@reduxjs/toolkit";
import dataReducer from "./dataSlice.js";
import initialDataReducer from "./initialDataSlice.js";
import userReducer from "./userRedux/userSlice.js";

export const store = configureStore({
  reducer: {
    data: dataReducer,
    user: userReducer,
    initialData: initialDataReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});
