import { useMutation } from "@tanstack/react-query";

// @ts-ignore
import app from "../config/axiosConfig";

const _loginUser = async (userData: { email: string; password: string }) => {
  try {
    const response = await app.post(`api/login`, userData);

    if (response.status < 200 || response.status > 299) {
      throw new Error(response.data.error);
    }

    return response.data;
  } catch (error: any) {
    throw error.response.data.error;
  }
};
const useLogin = () => {
  const {
    data: user,
    error,
    isPending: isLoading,
    mutate: loginUser,
  } = useMutation({
    mutationFn: _loginUser,
  });

  return { user, error, isLoading, loginUser };
};

export default useLogin;
