import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Controller } from "react-hook-form";
import { AiOutlineUpload } from "react-icons/ai";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import ReactQuill from "react-quill";
import CreatableSelect from "react-select/creatable";
import { belgiumCitiesOptions, citiesOptions } from "../../constants/Data";
import { IMAGES } from "../../constants/images";
import { DotLoader } from "react-spinners";

const toolbarOptions = [
  [{ font: [] }],
  ["bold", "italic", "underline", "strike"], // toggled buttons
  ["blockquote"],
  ["link", "image"],
  // [{ header: 1 }, { header: 2 }], // custom button values
  [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
  // [{ script: "sub" }, { script: "super" }], // superscript/subscript
  // [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
  // [{ direction: "rtl" }], // text direction
  // [{ size: ["small", false, "large", "huge"] }], // custom dropdown
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  [{ align: [] }],
  ["clean"], // remove formatting button
];

const module = {
  toolbar: toolbarOptions,
};

const FirmStep = ({
  control,
  errors,
  isSubmitting,
  onPrev,
  register,
  watch,
}) => {
  const logoValue = watch("firmLogo");

  const handlePrevStepClick = () => {
    onPrev();
  };

  return (
    <>
      <p className="mb-2">
        Please provide the following information about your company
      </p>
      <Form.Group className="mb-3" controlId="companyName">
        <Form.Label className="font-bold">Firm Name*</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter firm name"
          {...register("firmName")}
        />
        {errors.firmName && (
          <p className="text-red-400">{errors.firmName.message}</p>
        )}
        <Form.Label className="font-bold mt-2">Founded in</Form.Label>
        <Form.Control
          type="number"
          placeholder="Enter the firm founding year"
          {...register("firmFoundedYear", {
            required: "Firm founding year is required",
            validate: {
              positiveInt: (value) =>
                parseInt(value) > 0 || "Please enter a positive integer",
              integer: (value) =>
                Number.isInteger(parseInt(value)) || "Please enter an integer",
            },
          })}
          onKeyPress={(e) => {
            // Prevent '-' key from being entered
            if (e.key === "-" || e.key === "+") {
              e.preventDefault();
            }
          }}
        />
        {errors.firmFoundedYear && (
          <p className="text-red-400">{errors.firmFoundedYear.message}</p>
        )}
      </Form.Group>
      <p className="my-2 font-bold">Firm logo</p>
      <div className="w-100 my-1">
        <label
          htmlFor="profileImg"
          className="flex items-center justify-center px-1 bg-[#D8E0EB] rounded-md text-black  py-2 cursor-pointer w-100"
        >
          {/* <img src={download_icon} className="mr-3" alt="" /> */}
          <AiOutlineUpload size={22} className="mr-3" />
          {logoValue?.length ? (
            logoValue[0].name
          ) : (
            <>Click to Upload company Logo</>
          )}
        </label>
        <Form.Control
          id="profileImg"
          type="file"
          className="hidden w-[10px]"
          accept=".png, .jpg, .jpeg"
          {...register("firmLogo")}
        />
        {errors.firmLogo && (
          <p className="text-red-400">{errors.firmLogo.message}</p>
        )}
      </div>
      {errors.logo && <p className="text-red-400">Please upload firm logo</p>}
      <div className="w-full mt-2">
        <label className="font-bold my-2">Primary Firm Location*</label>
        <Controller
          name="firmPrimaryLocation"
          control={control}
          render={({ field }) => {
            const selectedOption = belgiumCitiesOptions.find(
              (cityOption) => field.value === cityOption.value
            );

            return (
              <CreatableSelect
                {...field}
                onChange={(value) => {
                  field.onChange(value?.value ?? null);
                }}
                value={selectedOption}
                isClearable
                className="basic-single"
                classNamePrefix="select"
                options={belgiumCitiesOptions}
              />
            );
          }}
        />
        {errors.firmPrimaryLocation && (
          <p className="text-red-400">{errors.firmPrimaryLocation.message}</p>
        )}
      </div>
      <div className="w-full mt-2">
        <label className="font-bold my-2">Firm Locations</label>
        <Controller
          name="firmOtherLocations"
          control={control}
          render={({ field }) => {
            const selectedOptions = citiesOptions.filter((cityOption) =>
              field.value.includes(cityOption.value)
            );

            return (
              <CreatableSelect
                {...field}
                onChange={(values) => {
                  field.onChange(values.map((value) => value.value));
                }}
                value={selectedOptions}
                isClearable
                isMulti
                options={citiesOptions}
                className="basic-multi-select"
                classNamePrefix="select"
              />
            );
          }}
        />
      </div>
      <Form.Group className="mb-3" controlId="firmUrl">
        <Form.Label className="font-bold">Firm Website*</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter Firm website URL"
          {...register("firmUrl")}
        />
        {errors.firmUrl && (
          <p className="text-red-400">{errors.firmUrl.message}</p>
        )}
      </Form.Group>
      <Form.Group className="mb-3 mt-1" controlId="firmSize">
        <Form.Label className="font-bold">Company size*</Form.Label>
        <Form.Select {...register("firmSize")}>
          <option value="">Select size</option>
          <option value="0-10">0-10 employees</option>
          <option value="10-100">10-100 employees</option>
          <option value="100-500">100-500 employees</option>
          <option value="500+">500+ employees</option>
        </Form.Select>
        {errors.firmSize && (
          <p className="text-red-400">{errors.firmSize.message}</p>
        )}
      </Form.Group>
      <div
        className="w-full border-gray-400 pt-2 mb-1"
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <p className="font-bold mb-2">Company description* &#8205; </p>
        <OverlayTrigger
          placement="right"
          overlay={
            <Tooltip placement="top" className="in" id="tooltip-top">
              Provide general information about your firm. Candidates will see
              this alongside your job opportunities.
            </Tooltip>
          }
        >
          <img src={IMAGES.QUESTIONMARK} height={17.5} width={17.5} alt="" />
        </OverlayTrigger>
      </div>
      <div className="column">
        <Controller
          name="firmDescription"
          control={control}
          render={({ field }) => {
            return (
              <ReactQuill
                {...field}
                module={module}
                placeholder="Write a description of the company you are recruiting for."
              />
            );
          }}
        />
        {errors.firmDescription && (
          <p className="text-red-400">{errors.firmDescription.message}</p>
        )}
        <div className="flex my-3">
          <button
            type="button"
            className="flex-2 px-4 py-1 rounded-md flex items-center cursor-pointer hover:bg-gray-300 hover:text-white"
            onClick={handlePrevStepClick}
          >
            <MdOutlineKeyboardArrowLeft /> Back
          </button>
          <p className="flex-1 text-center  py-1 ">2/2</p>
          <button
            type="submit"
            className="flex-2 bg-[#868BFF] border-1 border-[#868BFF] px-4 py-1 rounded-md flex items-center cursor-pointer hover:bg-transparent hover:text-[#868BFF]"
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <DotLoader color="#ffffff" size={20} />
            ) : (
              <>Finish</>
            )}
          </button>
        </div>
      </div>
    </>
  );
};

export default FirmStep;
