import { Form } from "react-bootstrap";

const CredentialsStep = ({ errors, onNext, register, trigger }) => {
  const handleNextStepClick = async () => {
    const isValid = await trigger(["email", "password", "passwordConfirm"]);

    if (isValid) {
      onNext();
    }
  };

  return (
    <>
      <Form.Group className="mb-3" controlId="email">
        <Form.Label>Email</Form.Label>
        <Form.Control
          type="email"
          placeholder="Enter your email"
          {...register("email")}
        />
        {errors.email && <p className="text-red-400">{errors.email.message}</p>}
      </Form.Group>
      <Form.Group className="mb-3" controlId="password">
        <Form.Label>Password</Form.Label>
        <Form.Control
          type="password"
          placeholder="Enter your password"
          {...register("password")}
        />
        {errors.password && (
          <p className="text-red-400">{errors.password.message}</p>
        )}
      </Form.Group>
      <Form.Group className="mb-3" controlId="passwordConfirm">
        <Form.Label>Confirm password</Form.Label>
        <Form.Control
          type="password"
          placeholder="Confirm your password"
          {...register("passwordConfirm")}
        />
        {errors.passwordConfirm && (
          <p className="text-red-400">{errors.passwordConfirm.message}</p>
        )}
      </Form.Group>
      <div className="flex flex-col items-center justify-center my-4 text-center">
        <button
          type="button"
          className="bg-[#46D6EA] border border-[#46D6EA]  py-1 rounded-md flex justify-center cursor-pointer hover:bg-transparent hover:text-[#46D6EA] w-75"
          onClick={handleNextStepClick}
        >
          <p className="text-center font-semibold">Sign up</p>
        </button>
      </div>
    </>
  );
};

export default CredentialsStep;
