import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import { IMAGES } from "../../constants/images";
import { useSelector } from "react-redux";

const ApplicationFinished = ({ handleClose }) => {
  const initialData = useSelector((state) => state.initialData);

  return (
    <>
      <Row className="justify-center text-black text-center mt-3">
        <Image src={IMAGES.CONE} rounded className="w-25 h-25 mb-2" />
        <p className="font-bold text-2xl mt-1">Hooray !</p>
      </Row>
      {/* Text */}
      <Row className="flex flex-column items-center text-center my-3 text-sm">
        <Row>
          <p>
            You’ve applied for the position {initialData?.selectedJob?.jobTitle}{" "}
            at {initialData?.selectedJob?.companyName}! Expect a message from
            them soon.
          </p>
        </Row>
      </Row>

      {/* buttons */}
      <Row className="flex flex-column items-center gap-y-3 mb-3">
        <button
          className="bg-[#46D6EA] w-75 py-2 rounded-md font-bold  border-1 border-[#46D6EA] cursor-pointer hover:bg-transparent"
          onClick={handleClose}
        >
          Continue Browsing
        </button>
      </Row>
    </>
  );
};

export default ApplicationFinished;
