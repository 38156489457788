import { css } from "@emotion/react";
import React, { useState } from "react";
import { PiCaretCircleLeftFill } from "react-icons/pi";
import { Link, useNavigate } from "react-router-dom";
import { DotLoader } from "react-spinners";
import { IMAGES } from "../../constants/images.jsx";
import styles from "./Register.module.css";
import useRegister from "../../hooks/useRegister";

const Register = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const navigate = useNavigate();
  const { registerUser } = useRegister();

  const nextWindow = async (event) => {
    event.preventDefault();

    setLoading(true);

    if (password === confirmPassword) {
      registerUser(
        { email, password },
        {
          onSuccess: async (credentials) => {
            navigate("/candidateSignupVerify", {
              state: {
                email: email,
              },
            });
          },
          onError: (error) => {
            setError({ message: error });
            setLoading(false);
          },
        }
      );
    } else {
      setError({ message: "The passwords don't match" });
      setLoading(false);
    }
  };

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

  return (
    <div className={styles.container}>
      {/* Box Container */}
      <div className={styles.boxcontainerBtn}>
        <Link to="/" className="flex items-center text-white">
          <PiCaretCircleLeftFill
            size={38}
            className="mr-2 text-blue-400 cursor-pointer duration-75 hover:scale-110"
          />
          <p className=""> Back to landing page</p>
        </Link>
      </div>
      <div className={styles.boxcontainer}>
        <img
          src={IMAGES.JLFULL}
          className="w-18 h-10 mb-0"
          alt="JustLawyers logo"
        />

        <h3 className="w-[40%] text-left font-bold uppercase text-[#738397]">
          Candidate
        </h3>
        {/* UserSignUp */}
        <div className={styles.innercontainer}>
          <div className={styles.littletittle}>
            <p>Register</p>
          </div>
          <form onSubmit={nextWindow}>
            <input
              className={styles.input}
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              className={styles.input}
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <input
              className={styles.input}
              type="password"
              placeholder="Confirm password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            {error && (
              <div className="bg-red-500 mt-2 rounded-md">
                <p>{error?.message}</p>
              </div>
            )}
            <button className={styles.button}>
              {loading ? (
                <DotLoader
                  color={"#ffffff"}
                  loading={loading}
                  css={override}
                  size={20}
                />
              ) : (
                "Create an account"
              )}
            </button>
          </form>
          {/* <div className="flex items-center justify-between my-3">
            <div className="w-36 h-[1px] bg-black"></div>
            <div className="mx-3">or</div>
            <div className="w-36 h-[1px] bg-black"></div>
          </div> */}

          {/* <button className={styles.buttonlinkedin}>
            Sign up with Linkedin
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default Register;
