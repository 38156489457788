import { useState, useEffect } from "react";
import { db } from "../config/firebase";
import {
  collection,
  doc,
  onSnapshot,
  query,
  where,
  getDocs,
} from "firebase/firestore";

const useCheckIfAnonymous = (employer_id, candidate_id, job_id) => {
  const [incognito, setIncognito] = useState(true);

  useEffect(() => {
    const checkUserAndFetchConversations = async () => {
      if (!candidate_id || !employer_id || !job_id) {
        setIncognito(true);
        return;
      }

      let userExists = false;

      try {
        // Check if user exists in "applications" collection
        const userRef = doc(db, "users", candidate_id);
        const jobsRef = doc(db, "jobs", job_id);
        const applicationsQuery = query(
          collection(db, "applications"),
          where("user_id", "==", userRef),
          where("job_id", "==", jobsRef)
        );
        const applicationsSnapshot = await getDocs(applicationsQuery);

        if (!applicationsSnapshot.empty) {
          userExists = true;
          setIncognito(false);
        } else {
          userExists = false;
          setIncognito(true);
        }
      } catch (error) {
        console.error("Error checking user existence in applications:", error);
        setIncognito(true);
        return;
      }

      if (!userExists) {
        const employerRef = doc(db, "employers", employer_id);
        const userRef = doc(db, "users", candidate_id);
        const unsubscribe = onSnapshot(
          query(
            collection(db, "conversations"),
            where("employer", "==", employerRef),
            where("candidate", "==", userRef)
          ),
          (snapshot) => {
            let hasIncognitoDocuments = false; // Flag to track if any incognito documents are found
            snapshot.docs.forEach((applicationDocument) => {
              const data = applicationDocument.data();
              if (data && data.incognito === false) {
                hasIncognitoDocuments = true;
              }
            });
            setIncognito(!hasIncognitoDocuments); // Update incognito state based on findings
          },
          (error) => {
            // Handle any errors here
            console.error("Error fetching conversations:", error);
          }
        );

        // Clean up the listener when component unmounts
        return () => unsubscribe();
      }
    };

    checkUserAndFetchConversations();
  }, [employer_id, candidate_id, job_id]); // Add any dependencies here

  return { incognito };
};

export default useCheckIfAnonymous;
