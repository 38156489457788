import React, { useState } from "react";
import { IMAGES } from "../constants/images.jsx";
import { useSelector } from "react-redux";
import { LuDot } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import { useCollectionData } from "react-firebase-hooks/firestore";
import {
  collection,
  query,
  where,
  doc,
  updateDoc,
  orderBy,
} from "firebase/firestore";
import { db } from "../config/firebase";
import NotificationComponent from "./NotificationComponent.jsx";

const NotificationDropdown = () => {
  //   const user = useSelector((state) => state.data.value)
  const [showDropdown, setShowDropdown] = useState(false);
  const navigate = useNavigate();
  const data = useSelector((state) => state.data.value);
  const user = useSelector((state) => state.user.userData);

  const [notifications, loadingNotifications] = useCollectionData(
    query(
      collection(db, "notifications"),
      where("user_id", "==", user.uid)
      // orderBy("date","desc")
      // where("read", "==", false)
    )
  );

  function filterUnreadNotifications(notifications) {
    return notifications?.filter((notification) => !notification.read);
  }
  const unreadNotifications = filterUnreadNotifications(notifications);

  return (
    <div
      //   onMouseEnter={handleMouseEnter}
      //   onMouseLeave={handleMouseLeave}
      //   style={{
      //     position: "relative",
      //     display: "inline-block",

      //   }}
      onClick={() => setShowDropdown(!showDropdown)}
      className="relative inline-block rounded-md mr-2"
    >
      <button
        style={{
          backgroundColor: "transparent",
          border: "none",
          cursor: "pointer",
        }}
        className="flex items-center relative"
      >
        <img src={IMAGES.BELL} alt="user image" className="w-5 h-6" />
        {unreadNotifications?.length !== 0 && (
          <LuDot
            size={42}
            className=" text-[#7B80E9]  absolute -top-4 -right-5 "
          />

          //   <Badge
          //     bg=""
          //     className="flex items-center justify-center bg-[#7B80E9] rounded-full w-[5px] h-[5px] absolute -top-1 -right-1 p-2"
          //   ></Badge>
        )}
      </button>

      {showDropdown && (
        <div
          className="absolute top-7 right-0 w-[334px] bg-[#293A50] text-[#868BFF] px-3 py-1 max-h-[600px] overflow-y-auto"
          style={{ zIndex: "60" }}
        >
          {notifications?.length === 0 && (
            <div
              className={`flex items-center justify-between cursor-pointer h-[42px] text-white`}
            >
              No notifications yet
            </div>
          )}
          {notifications?.slice(0, 5).map((notification, index) => (
            <NotificationComponent
              notification={notification}
              key={notification.id}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default NotificationDropdown;
