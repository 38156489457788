import React, { useEffect, useState } from "react";
import { DotLoader } from "react-spinners";
import { fetchFilteredJobs } from "../../app/functions.js";
import CandidateJobBoardCard from "../../components/CandidateJobBoardCard";
import SearchBar from "../../components/SearchBar.jsx";

const SearchPage = () => {
  const [loading, setLoading] = useState(false);
  const [jobsData, setJobsData] = useState([]);

  useEffect(() => {
    getJobsFiltered();
  }, []);

  const getJobsFiltered = async (values) => {
    setLoading(true);

    const data = await fetchFilteredJobs(values);

    setJobsData(data);
    setLoading(false);
  };

  return (
    <div className="flex justify-center text-white overflow-y-auto">
      <div className="flex flex-column items-center my-1 md:my-10 w-screen">
        <div className=" md:inline-block text-center ">
          <p className="font-bold  sm:mt-2 md:mt-4 lg:mt-5">
            Welcome to your personalized{" "}
            <span className="text-[#868BFF]">matchmaking</span> playground!{" "}
          </p>
          <p className="px-3 text-sm">
            Explore potential opportunities crafted just for you. Let the
            matching magic begin!
          </p>
        </div>
        <div className=" md:inline-block mb-2 mt-4 text-start flex-start w-[90%] lg:w-[70%]">
          <h1 className="font-bold">Search for a job</h1>
          <SearchBar onSearch={getJobsFiltered} onClear={getJobsFiltered} />
        </div>
        <div className="flex flex-column  mb-2 mt-1 md:mt-4 w-[90%] lg:w-[70%]">
          <p>
            <span className="font-bold">{jobsData?.length}</span> match your
            criteria
          </p>
          <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-5 mt-1 md:mt-3">
            {loading ? (
              <DotLoader color="#868bff" />
            ) : (
              jobsData?.map((job) => (
                <CandidateJobBoardCard
                  key={job.uid}
                  position={job.position}
                  job={job}
                />
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchPage;
