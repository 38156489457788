import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { IMAGES } from "../../constants/images.jsx";
import styles from "./CandidateVerify.module.css";
import { useSearchParams } from "react-router-dom";

const CandidateVerify = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const isEmailConfirmed = searchParams.get("confirm_email");

  useEffect(() => {
    if (isEmailConfirmed !== "true") {
      navigate("/");
    }
  }, [navigate, isEmailConfirmed]);

  return (
    <div className="flex justify-center items-center h-lvh py-[100px] text-black overflow-y-auto">
      <>
        <div className={styles.boxcontainer}>
          <div className="mt-3">
            <img src={IMAGES.ENVELOPE} className="w-30 h-30" />
          </div>
          <div className="flex flex-column items-center w-[80%] my-3">
            <h1 className="text-5xl font-bold">Verified!</h1>
            <p className="my-3 w-75">
              It's now time to complete your profile to start the matching
              process!
            </p>
            <button
              className="bg-[#46D6EA] py-2 rounded-md font-bold  w-[60%] border-1 border-[#46D6EA] cursor-pointer hover:bg-transparent"
              onClick={() => navigate("/auth/login")}
            >
              Click to Continue
            </button>
          </div>
        </div>
      </>
    </div>
  );
};

export default CandidateVerify;
