import { isToday, isYesterday } from "date-fns";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { checkJobStatus, fetchSavedCandidates } from "../app/functions";
import useFetchConversationsForJob from "../hooks/useFetchConversationsForJob";
import useFetchMyApplications from "../hooks/useFetchMyApplications";

const getJobStatusIndicatorColor = (status) => {
  switch (status) {
    case "draft":
      return "bg-yellow-500"; // yellow
    case "active":
      return "bg-[#6CE96A]"; // green
    case "archive":
      return "bg-red-500"; // red
    default:
      return "bg-gray-500"; // default color if none of the statuses match
  }
};

const RecruiterHomeJobCard = ({
  jobData,
  isLast,
  setParentMatchesLength,
  setParentApplicantsLength,
  setParentLikedCandidatesLength,
  setParentTotalUnreadCount,
}) => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  const user = useSelector((state) => state.user.userData);
  const user_id = user.uid;

  const handelJobOverview = () => {
    navigate(`/joboverview/${jobData.uid}`);
  };

  const [likedCandidatesLength, setLikedCandidatesLength] = useState(null);
  const [usersLength, setUsersLength] = useState(null);

  const { applications, loadingApplications } = useFetchMyApplications(
    jobData.uid
  );
  const { matchesApplications, loadingMatchesApplications } =
    useFetchMyApplications(jobData.uid, "matches");

  const { unreadCounts } = useFetchConversationsForJob(jobData.uid);

  const totalUnreadCount = Object.values(unreadCounts).reduce(
    (acc, count) => acc + count,
    0
  );

  useEffect(() => {
    getLikedCandidatesLength();
    checkJobStatus(jobData.uid, user_id, "users")
      .then((status) => setUsersLength(status))
      .catch((error) => console.error("Error checking job status:", error));
  }, [jobData.uid, user_id]);

  useEffect(() => {
    if (!loadingMatchesApplications) {
      setParentMatchesLength(matchesApplications.length);
    }
    if (!loadingApplications) {
      setParentApplicantsLength(applications.length);
    }
    if (likedCandidatesLength) {
      setParentLikedCandidatesLength(likedCandidatesLength);
    }
    if (totalUnreadCount) {
      setParentTotalUnreadCount(totalUnreadCount);
    }
  }, [
    loadingMatchesApplications,
    loadingApplications,
    likedCandidatesLength,
    totalUnreadCount,
  ]);

  const getLikedCandidatesLength = async () => {
    let data = await fetchSavedCandidates(user_id, "saved");
    setLikedCandidatesLength(data.length);
  };

  const formatDate = (dateString) => {
    const formattedDate = new Date(dateString);

    // Check if the date is today or yesterday
    if (isToday(formattedDate)) {
      return "Today";
    } else if (isYesterday(formattedDate)) {
      return "Yesterday";
    } else {
      // If not today or yesterday, display the actual date
      const options = { year: "numeric", month: "long", day: "numeric" };
      return formattedDate.toLocaleDateString(undefined, options);
    }
  };

  return (
    <div
      className={`flex items-center pb-3 cursor-pointer ${
        isLast ? "" : "border-b-2 border-[#18273A]"
      }`}
      onClick={() => navigate(`/JobBoardRecruiter`)}
    >
      <p className="flex-1">{jobData.jobTitle}</p>
      <p className="flex-1">{formatDate(jobData?.createdAt)}</p>
      <p className="flex-1">
        {applications?.length ? applications?.length : 0}
      </p>
      <p className="flex-1">
        {matchesApplications?.length ? matchesApplications?.length : 0}
      </p>
      <p className="flex-1">{totalUnreadCount}</p>
      <p
        className={`flex-2 ${getJobStatusIndicatorColor(jobData.jobStatus)} w-10 h-2 rounded-md`}
      ></p>
    </div>
  );
};

export default RecruiterHomeJobCard;
