import React, { useState } from "react";
import ModalCandidateData from "./modals/ModalCandidateData1.jsx";
import { IoChevronDownSharp } from "react-icons/io5";
import { IMAGES } from "../constants/images.jsx";
import { useSelector } from "react-redux";

function UserDropdown({ handleEditUser, handleSignout, navigate, type }) {
  const user = useSelector((state) => state.data.value);
  const [showDropdown, setShowDropdown] = useState(false);

  const handleMouseEnter = () => {
    setShowDropdown(true);
  };

  const handleMouseLeave = () => {
    setShowDropdown(false);
  };
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return type !== "responsive" ? (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{ position: "relative", display: "inline-block" }}
    >
      <button
        style={{
          backgroundColor: "transparent",
          border: "none",
          cursor: "pointer",
        }}
        className="flex items-center"
      >
        {user?.profileImg ? (
          <>
            <img
              src={user.profileImg}
              alt="user image"
              className="rounded-full w-10 h-10 border-2 border-[#868BFF]"
            />
          </>
        ) : (
          <>
            <img
              src={IMAGES.U_ICON}
              alt="user image"
              className="rounded-full w-10 h-10 border-2 border-[#868BFF]"
            />
          </>
        )}

        <IoChevronDownSharp />
      </button>

      {showDropdown && (
        <div
          style={{
            position: "absolute",
            top: "100%",
            right: 0,
            minWidth: "100px",
            backgroundColor: "#fff",
            border: "1px solid #ccc",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
            zIndex: 1,
          }}
        >
          <div
            onClick={handleShowModal}
            style={{ padding: "8px", cursor: "pointer" }}
          >
            Edit profile
          </div>
          <div
            onClick={handleSignout}
            style={{ padding: "8px", cursor: "pointer" }}
          >
            Signout
          </div>
        </div>
      )}
      <ModalCandidateData show={showModal} handleClose={handleCloseModal} />
    </div>
  ) : (
    <div
      style={{
        bottom: 30,
        position: "absolute",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      <div
        onClick={handleShowModal}
        style={{ padding: "8px", cursor: "pointer" }}
      >
        Edit profile
      </div>
      <div
        onClick={handleSignout}
        style={{ padding: "8px", cursor: "pointer" }}
      >
        Signout
      </div>
      {user?.profileImg ? (
        <>
          <img
            src={user.profileImg}
            alt="user image"
            className="rounded-full w-10 h-10 border-2 border-[#868BFF]"
          />
        </>
      ) : (
        <>
          <img
            src={IMAGES.U_ICON}
            alt="user image"
            className="rounded-full w-10 h-10 border-2 border-[#868BFF]"
          />
        </>
      )}
    </div>
  );
}

export default UserDropdown;
