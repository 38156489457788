import { useState } from "react";
import { useLocation } from "react-router-dom";

const useActiveConverstationId = (conversations) => {
  const { state } = useLocation();
  const [activeConversationIdState, setActiveConversationId] = useState(
    state?.conversationId ?? null
  );

  const activeConversationId =
    activeConversationIdState ?? conversations?.at(0)?.id ?? null;

  return { activeConversationId, setActiveConversationId };
};

export default useActiveConverstationId;
