import { PiCaretCircleLeftFill } from "react-icons/pi";
import { Link } from "react-router-dom";
import { IMAGES } from "../../../constants/images.jsx";
import styles from "./RegisterPage.module.css";

const RegisterPage = () => {
  return (
    <div className={styles.landingPage}>
      <div className="flex w-full mt-5 pl-5">
        <img src={IMAGES.JL_MIXED} className="ml-3" />
      </div>
      <div className={styles.landingPage}>
        <div className={styles.boxcontainerBtn}>
          <Link to="/" className="flex items-center text-white">
            <PiCaretCircleLeftFill
              size={38}
              className="mr-2 text-blue-400 cursor-pointer duration-75 hover:scale-110"
            />
            <p className=""> Back to landing page</p>
          </Link>
        </div>
        <div className={styles.boxcontainer}>
          <img
            src={IMAGES.JLFULL}
            className="w-18 h-10 mb-8"
            alt="JustLawyers logo"
          />
          {/* <h1 className={styles.boxcontainertext}>Welcome to DEX</h1> */}
          <div className={styles.innercontainer}>
            <p className="text-black">Who are you?</p>
          </div>
          <div className={styles.buttoncontainer}>
            <Link to="/register" className={styles.button1}>
              I'm a candidate
            </Link>
            <div className="flex items-center justify-between my-3">
              <div className="w-36 h-[1px] bg-black"></div>
              <div className="mx-3">or</div>
              <div className="w-36 h-[1px] bg-black"></div>
            </div>

            <Link to="/auth/register/firm" className={styles.button2}>
              I'm an employer
            </Link>
            <br />
            {/* <Link to="/auth/login">I already have an account</Link> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterPage;
