import MultiRangeSlider from "multi-range-slider-react";
import React, { useState } from "react";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FiChevronLeft } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CreatableSelect from "react-select/creatable";
import { saveData } from "../../app/dataSlice.js";
import { signInOrLogin } from "../../app/functions";
import { updateInitialData } from "../../app/initialDataSlice";
import { login } from "../../app/userRedux/userSlice.js";
import {
  belgiumCitiesOptions,
  expertiseOptions,
  firmsOptions,
  languagesOptions,
} from "../../constants/Data.js";
import { IMAGES } from "../../constants/images.jsx";
import styles from "./CandidateData.module.css";

const CandidateData4 = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const initialData = useSelector((state) => state.initialData);
  const userData = useSelector((state) => state.user.userData);

  const [salaryRange, setSalaryRange] = useState("");
  const [expertise, setExpertise] = useState(false);
  const [languages, setLanguages] = useState(false);
  const [experience, setExperience] = useState(false);
  const [citiesList, setCitiesList] = useState(false);
  const [minRemunerationValue, setMinRemunerationValue] = useState(0);
  const [maxRemunerationValue, setMaxRemunerationValue] = useState(250);
  const [isDisclose, setIsDisclose] = useState();
  const [pluse, setPluse] = useState();
  const [RemunerationRange, setRemunerationRange] = useState();
  const [minExpYears, setMinExpYears] = useState(0);
  const [maxExpYears, setMaxExpYears] = useState(7);

  const [yearsExp, setYearsExp] = useState("");

  const handleInputExperience = (e) => {
    setMinExpYears(e.minValue);
    setMaxExpYears(e.maxValue);
    setYearsExp(`${minExpYears}-${maxExpYears - 1} years`);

    dispatch(updateInitialData({ experience: yearsExp }));
  };
  // console.log(yearsExp)
  const handleChangeLanguages = (selectedOptions) => {
    const newLanguages = selectedOptions
      ? selectedOptions.map((option) => option.label)
      : [];
    dispatch(updateInitialData({ selectedLanguages: newLanguages }));
  };
  const handleChangeBonusLanguages = (selectedOptions) => {
    const newLanguages = selectedOptions
      ? selectedOptions.map((option) => option.label)
      : [];
    dispatch(updateInitialData({ selectedBonusLanguages: newLanguages }));
  };
  const handleInputRemuneration = (e) => {
    setMinRemunerationValue(e.minValue);
    setMaxRemunerationValue(e.maxValue);
    if (isDisclose) {
      setRemunerationRange("Prefer not to disclose");
    } else if (pluse) {
      setRemunerationRange("More than 250 K€");
    } else {
      setRemunerationRange(
        `${minRemunerationValue} K€ - ${maxRemunerationValue} K€`
      );
    }
    dispatch(updateInitialData({ annualRemuneration: RemunerationRange }));
    dispatch(
      updateInitialData({ annualRemunerationMin: minRemunerationValue })
    );
    dispatch(
      updateInitialData({ annualRemunerationMax: maxRemunerationValue })
    );
  };

  const handleSelectionExpertise = (expertiseOptions) => {
    const arrayOfLabels = expertiseOptions.map((obj) => obj.label);
    dispatch(updateInitialData({ expertise: arrayOfLabels }));
  };

  const handleSelectionCities = (selectedOptions) => {
    const newCities = selectedOptions
      ? selectedOptions.map((option) => option.label)
      : [];
    dispatch(updateInitialData({ cities: newCities }));
  };

  const handleSelectionTypeOfFirems = (selectedOptions) => {
    const newTypes = selectedOptions
      ? selectedOptions.map((option) => option.label)
      : [];
    dispatch(updateInitialData({ typeOfFirm: newTypes }));
  };

  const handleSelectionFirms = (firmsOptions) => {
    const arrayOfLabels = firmsOptions.map((obj) => obj.label);

    dispatch(updateInitialData({ nottomatch: arrayOfLabels }));
  };
  const handleSelectionFirmsToMatch = (firmsOptions) => {
    const arrayOfLabels = firmsOptions.map((obj) => obj.label);

    dispatch(updateInitialData({ tomatch: arrayOfLabels }));
  };

  const handleChangeExperience = (e) => {
    e.preventDefault();
    setExperience(e.target.value);
  };

  const handelJobSearch = async () => {
    if (initialData.cities.length === 0) {
      setCitiesList(true);
      setTimeout(() => {
        setCitiesList(false);
      }, 3000);
      return;
    }
    if (initialData.expertise.length === 0) {
      setExpertise(true);
      setTimeout(() => {
        setExpertise(false);
      }, 3000);
      return;
    }
    if (initialData.selectedLanguages.length === 0) {
      setLanguages(true);
      setTimeout(() => {
        setLanguages(false);
      }, 3000);
      return;
    }
    if (!initialData.annualRemuneration) {
      setSalaryRange(true);
      setTimeout(() => {
        setSalaryRange(false);
      }, 3000);
      return;
    }

    const uid = userData.uid;

    const data = {
      ...initialData,
      email: userData.email,
      uid: uid,
      isEmployer: false,
      isIncognito: true,
    };

    saveData(uid, data);

    await signInOrLogin({ ...data }, dispatch, saveData);

    dispatch(
      login({
        authData: { ...userData, isEmployer: false },
        userData: {
          ...data,
        },
      })
    );

    navigate("/jobboard");
  };

  const prevWindow = (e) => {
    navigate("/candidatedata2");
  };

  const prevWindow1 = (e) => {
    navigate("/candidatedata0");
  };

  const tooltipContent1 =
    "Your remuneration will not be shared with law firms. It will only be used to improve your matching results.";

  const tooltipContent =
    "If yes, just let us know, and we'll make sure to exclude them from your updates.";

  return (
    <div className={`${styles.container} `}>
      <div className="flex flex-column justify-center items-center bg-white text-black border-5 border-t-purple-400 rounded-md w-[90%] md:w-[50%] p-3 ">
        <div className="flex flex-column items-center w-full  md:w-[80%] my-3">
          <h1 className="text-3xl font-bold">
            Let's find you your perfect job
          </h1>
          <p className="my-3 w-full md:w-75">
            Your input fuels our AI-driven algorithm, creating perfect matches
            just for you. The more we know, the better your opportunities. Let's
            begin!
          </p>
          <div className="items-start my-2 mt-0 w-full">
            {/* location */}
            <div className="w-full mt-2">
              <label className="font-bold">Location*</label>

              <CreatableSelect
                isClearable
                placeholder="Select cities..."
                defaultValue={[]}
                isMulti
                name="cities"
                options={belgiumCitiesOptions}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={handleSelectionCities}
                value={initialData.cities.map((city) => ({
                  label: city,
                  value: city,
                }))}
                required
              />

              {citiesList && (
                <>
                  <p className="text-red-400">Please enter the cities</p>
                </>
              )}
            </div>

            {/* Experience */}
            <div className="w-full mt-2">
              <label className="font-bold">Experience*</label>

              <MultiRangeSlider
                // disabled={isDisclose}
                min={0}
                max={7}
                step={1}
                label={false}
                ruler={false}
                minValue={minExpYears}
                maxValue={maxExpYears}
                className="border-none shadow-none z-0"
                thumbLeftColor="blue"
                thumbRightColor="blue"
                barInnerColor="blue"
                onInput={(e) => {
                  handleInputExperience(e);
                }}
              />
              <div className="flex flex-col md:flex-row items-start md:items-center justify-between">
                <p className="text-xs">
                  Minimum{" "}
                  <span className="font-bold">
                    {/* {selectedJob?.jobExperienceMin} */}
                    {minExpYears} years
                  </span>
                </p>
                <p className="text-xs">
                  Maximum{" "}
                  <span className="font-bold">
                    {/* {selectedJob?.jobExperienceMax} */}
                    {maxExpYears} years
                  </span>
                </p>
              </div>
              {experience && (
                <>
                  <p className="text-red-400">
                    Please select the years of experience
                  </p>
                </>
              )}
            </div>

            {/* Expertise */}
            <div>
              <div className=" my-2">
                <p className="font-bold">Expertise*</p>
                <CreatableSelect
                  isClearable
                  placeholder="Select Type..."
                  defaultValue={[]}
                  isMulti
                  name="type"
                  options={expertiseOptions}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={handleSelectionExpertise}
                  value={initialData.expertise?.map((exp) => ({
                    label: exp,
                    value: exp,
                  }))}
                />
                {expertise && (
                  <>
                    <p className="text-red-400">Please enter the expertise</p>
                  </>
                )}
              </div>
            </div>
            {/* Languages */}
            <div className="w-full mt-2">
              <p className="font-bold">Languages*</p>
              <CreatableSelect
                isClearable
                defaultValue={[]}
                isMulti
                name="languages"
                options={languagesOptions}
                className="basic-multi-select"
                classNamePrefix="select"
                value={initialData.selectedLanguages.map((lang) => ({
                  label: lang,
                  value: lang,
                }))}
                onChange={handleChangeLanguages}
              />
              {languages && (
                <>
                  <p className="text-red-400">Please select the languages</p>
                </>
              )}
            </div>

            {/* Languages */}
            <div className="w-full mt-2">
              <p className="font-bold">Additional Languages</p>
              <CreatableSelect
                isClearable
                className="basic-multi-select"
                classNamePrefix="select"
                defaultValue={[]}
                isMulti
                placeholder="Select Languages"
                name="languages"
                options={languagesOptions}
                value={
                  initialData.selectedBonusLanguages
                    ? initialData.selectedBonusLanguages.map((lang) => ({
                        label: lang,
                        value: lang,
                      }))
                    : null
                }
                onChange={handleChangeBonusLanguages}
              />
            </div>

            {/* compensation */}
            <div className="w-full mt-2">
              <div
                className="w-full border-t-2 border-gray-400 pt-2 mt-4"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <p className="font-bold">Annual remuneration* &#8205; </p>
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip placement="top" className="in" id="tooltip-top">
                      {tooltipContent1}
                    </Tooltip>
                  }
                >
                  <img src={IMAGES.QUESTIONMARK} height={17.5} width={17.5} />
                </OverlayTrigger>
              </div>

              <Form.Group className="flex flex-column w-100 mt-2">
                <div className="flex flex-col md:flex-row items-start md:items-center justify-between">
                  <Form.Check // prettier-ignore
                    type="switch"
                    id="custom-switch"
                    label="Prefer not to disclose"
                    checked={isDisclose}
                    onChange={(e) => setIsDisclose(e.target.checked)}
                  />
                  <Form.Check // prettier-ignore
                    type="switch"
                    id="custom-switch"
                    label="More than 250 K€"
                    checked={pluse}
                    onChange={(e) => setPluse(e.target.checked)}
                  />
                </div>
                <div>
                  <MultiRangeSlider
                    disabled={isDisclose || pluse}
                    min={0}
                    max={250}
                    step={10}
                    label={false}
                    ruler={false}
                    minValue={minRemunerationValue}
                    maxValue={maxRemunerationValue}
                    className="border-none shadow-none z-0"
                    thumbLeftColor="blue"
                    thumbRightColor="blue"
                    barInnerColor="blue"
                    onInput={(e) => {
                      handleInputRemuneration(e);
                    }}
                  />
                  <div className="flex flex-col md:flex-row items-start md:items-center justify-between">
                    <p className="text-xs">
                      Minimum Remuneration{" "}
                      <span className="font-bold">
                        {/* {selectedJob?.jobExperienceMin} */}
                        {minRemunerationValue} K€
                      </span>
                    </p>
                    <p className="text-xs">
                      Maximum Remuneration{" "}
                      <span className="font-bold">
                        {/* {selectedJob?.jobExperienceMax} */}
                        {maxRemunerationValue} K€
                      </span>
                    </p>
                  </div>
                </div>
              </Form.Group>
              {salaryRange && (
                <>
                  <p className="text-red-400">Please select the Salary Range</p>
                </>
              )}
            </div>
            <div
              className="w-full border-t-2 border-gray-400 pt-2 mt-4"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <p>Any specific law firms you'd like to follow? &#8205; </p>
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip placement="top" className="in" id="tooltip-top">
                    {tooltipContent1}
                  </Tooltip>
                }
              >
                <img src={IMAGES.QUESTIONMARK} height={17.5} width={17.5} />
              </OverlayTrigger>
            </div>
            <div className="w-full">
              {/* low firm list candidate doesn't want to work with */}
              <div className=" my-2 bg-green-100 px-2 py-1 rounded-md">
                <p className="font-bold">Law Firm </p>
                <CreatableSelect
                  isClearable
                  placeholder="Select Law Firm..."
                  defaultValue={[]}
                  isMulti
                  name="type"
                  options={firmsOptions}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={handleSelectionFirmsToMatch}
                  value={initialData.tomatch.map((name) => ({
                    label: name,
                    value: name,
                  }))}
                />
              </div>
            </div>
            <div
              className="w-full border-t-2 border-gray-400 pt-2 "
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <p>
                Any specific law firms you'd rather not be matched with? &#8205;{" "}
              </p>
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip placement="top" className="in" id="tooltip-top">
                    {tooltipContent}
                  </Tooltip>
                }
              >
                <img src={IMAGES.QUESTIONMARK} height={17.5} width={17.5} />
              </OverlayTrigger>
            </div>
            <div className="w-full">
              {/* low firm list candidate doesn't want to work with */}
              <div className=" my-2 bg-red-100 px-2 py-1 rounded-md">
                <p className="font-bold">Law Firm </p>
                <CreatableSelect
                  isClearable
                  placeholder="Select Law Firm..."
                  defaultValue={[]}
                  isMulti
                  name="type"
                  options={firmsOptions}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={handleSelectionFirms}
                  value={initialData.nottomatch.map((name) => ({
                    label: name,
                    value: name,
                  }))}
                />
              </div>
            </div>
          </div>
          <div className="flex items-center justify-between w-full">
            <button
              className=" flex items-center px-3 py-2 rounded-md cursor-pointer"
              onClick={initialData.name ? prevWindow : prevWindow1}
            >
              <FiChevronLeft size={22} />
              back
            </button>

            <button
              className="bg-[#46D6EA]  py-2 rounded-md font-bold  w-[60%] border-1 border-[#46D6EA] cursor-pointer hover:bg-transparent"
              onClick={() => handelJobSearch()}
            >
              Show me Jobs
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CandidateData4;
