import { useMutation } from "@tanstack/react-query";
// @ts-ignore
import app from "../config/axiosConfig";

const _registerUser = async (credential: {
  email: string;
  password: string;
}) => {
  try {
    const response = await app.post(`api/register`, credential);

    if (response.status < 200 || response.status > 299) {
      throw new Error(response.data.error);
    }
    return response.data;
  } catch (error: any) {
    throw error.response.data.error;
  }
};
const useRegister = () => {
  const {
    data,
    error,
    isPending: isLoading,
    mutate: registerUser,
  } = useMutation({
    mutationFn: _registerUser,
  });

  return { data, error, isLoading, registerUser };
};

export default useRegister;
