import ProptTypes from "prop-types";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { isGuest, isEmployer } from "../../app/userRedux/userSlice";
import { isUIDSetInitialData } from "../../app/initialDataSlice";

const CandidateAuthGuard = ({ children }) => {
  const userIsGuest = useSelector(isGuest);
  const userIsEmployer = useSelector(isEmployer);
  const isUIDset = useSelector(isUIDSetInitialData);

  if (!isUIDset) {
    return <Navigate to="/candidate-complete" replace />;
  }

  if (userIsGuest || userIsEmployer) {
    return <Navigate to="/" replace />;
  }

  return children;
};

CandidateAuthGuard.propTypes = {
  children: ProptTypes.node,
};

export default CandidateAuthGuard;
