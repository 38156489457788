import {
  collection,
  doc,
  getDoc,
  onSnapshot,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { userSelector } from "../app/userRedux/userSlice";
import { db } from "../config/firebase";

const getUserDataBasesOnIncognito = (userData, isIncognito) => {
  if (isIncognito) {
    return {
      uid: userData.uid,
      expertise: userData.expertise,
      location: userData.location,
      name: userData.expertise + " lawyer",
      selectedLanguages: userData.selectedLanguages,
      description: userData.description,
      employmentHistory:
        userData?.employmentHistory?.map((job) => ({
          employmentend: job.employmentend,
          employmentstart: job.employmentstart,
        })) ?? [],
      university: userData.university,
      grades: userData.grades,
      searchingStatus: userData.searchingStatus,
      experience: userData.experience,
      master: userData.master,
      isIncognito: isIncognito,
    };
  } else {
    return userData;
  }
};

const isUserAppliedToJob = async (candidateId, jobId) => {
  const userRef = doc(db, "users", candidateId);
  const jobRef = doc(db, "jobs", jobId);
  const applicationsQuery = query(
    collection(db, "applications"),
    where("user_id", "==", userRef),
    where("job_id", "==", jobRef)
  );
  const applicationsSnapshot = await getDocs(applicationsQuery);

  return !applicationsSnapshot.empty;
};

const useFetchConversations = () => {
  const user = useSelector(userSelector);
  const [conversations, setConversations] = useState();

  useEffect(() => {
    const whereStatement = user.isEmployer
      ? where("employer", "==", doc(db, "employers", user.uid))
      : where("candidate", "==", doc(db, "users", user.uid));

    const unsubscribe = onSnapshot(
      query(collection(db, "conversations"), whereStatement),
      async (snapshot) => {
        const conversations = await snapshot.docs.reduce(
          async (accPromise, conversationDocument) => {
            const acc = await accPromise;
            const messagesCollectionRef = collection(
              conversationDocument.ref,
              "messages"
            );
            const messagesSnapshot = await getDocs(messagesCollectionRef);
            const conversationData = conversationDocument.data();

            if (
              messagesSnapshot.empty &&
              !conversationData.startedChatByIds.includes(user.uid)
            ) {
              return acc;
            }
            const conversationPartnerDoc = await getDoc(
              user.isEmployer
                ? conversationData.candidate
                : conversationData.employer
            );

            const candidateDoc = await getDoc(conversationData.candidate);
            const jobDoc = await getDoc(conversationData.job);
            const isProfilePublic = await isUserAppliedToJob(
              candidateDoc.data().uid,
              jobDoc.data().uid
            );

            const aboutJob = jobDoc.exists() ? { ...jobDoc.data() } : null;

            const conversationPartner = user.isEmployer
              ? {
                  ...getUserDataBasesOnIncognito(
                    conversationPartnerDoc.data(),
                    isProfilePublic
                      ? !isProfilePublic
                      : conversationData.incognito
                  ),
                }
              : {
                  ...conversationPartnerDoc.data(),
                };

            acc.push({
              ...{
                ...conversationData,
                incognito: isProfilePublic
                  ? !isProfilePublic
                  : conversationData.incognito,
              },
              aboutJob: aboutJob,
              conversationPartner: conversationPartner,
            });

            return acc;
          },
          Promise.resolve([])
        );
        setConversations(conversations);
      }
    );

    return unsubscribe;
  }, [user.uid, user.isEmployer]);

  return { conversations, loadingConversations: conversations === undefined };
};

export default useFetchConversations;
