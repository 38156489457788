import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";
import { BarLoader } from "react-spinners";
import FirmLikeCandidateModal from "./components/FirmLikeCandidateModal.jsx";
import MatchModal from "./components/MatchModal.jsx";
import MessageNotificationModal from "./components/MessageNotificationModal.jsx";
import RecruiterMatchModal from "./components/RecruiterMatchModal.jsx";
import AuthGuard from "./components/routing/AuthGuard.jsx";
import CandidateAuthGuard from "./components/routing/CandidateAuthGuard.jsx";
import EmployerAuthGuard from "./components/routing/EmployerAuthGuard.jsx";
import GuestGuard from "./components/routing/GuestGuard.jsx";
import IncompleteCandidateGuard from "./components/routing/IncompleteCandidateGuard.jsx";
import Providers from "./context/Providers.tsx";
import useInitAuth from "./hooks/useInitAuth.js";
import AuthenticationApp from "./pages/AuthenticationApp.jsx";
import CandidateComplete from "./pages/CandidateComplete/CandidateComplete.jsx";
import CandidateData0 from "./pages/CandidateData/CandidateData0.jsx";
import CandidateData1 from "./pages/CandidateData/CandidateData1.jsx";
import CandidateData2 from "./pages/CandidateData/CandidateData2.jsx";
import CandidateData4 from "./pages/CandidateData/CandidateData4.jsx";
import CandidateJobDetailsPage from "./pages/CandidateJobDetailsPage/CandidateJobDetailsPage.jsx";
import CandidateMatches from "./pages/CandidateMatches/CandidateMatches.jsx";
import CandidateSignupVerify from "./pages/CandidateSignupVerify/CandidateSignupVerify.jsx";
import EmployerSignUpMessage from "./pages/EmployerSignUpMessage/EmployerSignUpMessage.jsx";
import CandidateVerify from "./pages/CandidateVerify/CandidateVerify.jsx";
import ChatRoom from "./pages/ChatRoom/ChatRoom.jsx";
import CreateJob from "./pages/CreateJob";
import HomeCandidate from "./pages/HomeCandidate/HomeCandidate.jsx";
import HomeRecruiter from "./pages/HomeRecruiter/HomeRecruiter.jsx";
import JobBoard from "./pages/JobBoard/JobBoard.jsx";
import JobBoardRecruiter from "./pages/JobBoardRecruiter/JobBoardRecruiter.jsx";
import JobPage from "./pages/JobBoardRecruiter/JobPage.jsx";
import JobOverview from "./pages/JobOverview/JobOverview.jsx";
import LandingPage from "./pages/LandingPage/LandingPage.jsx";
import OurMission from "./pages/LandingPage/OurMission.jsx";
import RecruiterCandidatePagePreview from "./pages/RecruiterCandidatePagePreview/RecruiterCandidatePagePreview.jsx";
import RecruiterCandidatesPage from "./pages/RecruiterCandidatesPage/RecruiterCandidatesPage.jsx";
import RecruiterProfile from "./pages/RecruiterProfile/RecruiterProfile.jsx";
import Register from "./pages/Register/Register.jsx";
import UserProfile from "./pages/UserProfile/UserProfile.jsx";
import Navbar from "./utils/NavBar.jsx";
import "./App.css";

const App = () => {
  const [showMatchModal, setShowMatchModal] = useState(false);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [showFirmLikeModal, setShowFirmLikeModal] = useState(false);
  const [showRecruiterMatchModal, setShowRecruiterMatchModal] = useState(false);

  const handleCloseMatchModal = () => {
    setShowMatchModal(false);
  };

  const handleCloseMessageModal = () => {
    setShowMessageModal(false);
  };

  const handleCloseFirmLikeModal = () => {
    setShowFirmLikeModal(false);
  };

  const handleCloseRecruiterMatchModal = () => {
    setShowRecruiterMatchModal(false);
  };

  const { initialisingAuth } = useInitAuth();

  if (initialisingAuth) {
    return <BarLoader color="#868bff" width="100%" />;
  }

  return (
    <Providers>
      <Routes>
        <Route path="auth/*" element={<AuthenticationApp />} />
        {/* General routes */}
        <Route
          index
          element={
            <GuestGuard>
              <LandingPage />
            </GuestGuard>
          }
        />
        <Route
          path="/ourmission"
          element={
            <GuestGuard>
              <OurMission />
            </GuestGuard>
          }
        />

        {/* Guest routes */}
        <Route
          path="/candidateSignupVerify"
          element={
            <GuestGuard>
              <CandidateSignupVerify />
            </GuestGuard>
          }
        />
        <Route
          path="/employer-registration-complete"
          element={
            <GuestGuard>
              <EmployerSignUpMessage />
            </GuestGuard>
          }
        />
        <Route
          path="/candidate-verify"
          element={
            <GuestGuard>
              <CandidateVerify />
            </GuestGuard>
          }
        />

        <Route
          path="/register"
          element={
            <GuestGuard>
              <Register />
            </GuestGuard>
          }
        />
        <Route
          path="chatroom"
          element={
            <AuthGuard>
              <ChatRoom />
            </AuthGuard>
          }
        />
        {/* Candidate routes */}
        <Route
          path="/candidatedata0"
          element={
            <IncompleteCandidateGuard>
              <CandidateData0 />
            </IncompleteCandidateGuard>
          }
        />

        <Route
          path="/candidatedata1"
          element={
            <IncompleteCandidateGuard>
              <CandidateData1 />
            </IncompleteCandidateGuard>
          }
        />
        <Route
          path="/candidatedata2"
          element={
            <IncompleteCandidateGuard>
              <CandidateData2 />
            </IncompleteCandidateGuard>
          }
        />
        <Route
          path="/candidatedata4"
          element={
            <IncompleteCandidateGuard>
              <CandidateData4 />
            </IncompleteCandidateGuard>
          }
        />
        <Route
          path="/candidate-complete"
          element={
            <IncompleteCandidateGuard>
              <CandidateComplete />
            </IncompleteCandidateGuard>
          }
        />
        <Route
          path="/mymatches"
          element={
            <CandidateAuthGuard>
              <Navbar />
              <CandidateMatches />
            </CandidateAuthGuard>
          }
        />
        <Route
          path="/jobboard"
          element={
            <CandidateAuthGuard>
              <Navbar />
              <JobBoard />
            </CandidateAuthGuard>
          }
        />
        <Route
          path="/userprofile"
          element={
            <CandidateAuthGuard>
              <Navbar />
              <UserProfile />
            </CandidateAuthGuard>
          }
        />
        <Route
          path="/candidatejobdetailspage/:jobUid"
          element={
            <CandidateAuthGuard>
              <CandidateJobDetailsPage />
            </CandidateAuthGuard>
          }
        />
        <Route
          path="/jobboard"
          element={
            <CandidateAuthGuard>
              <JobBoard />
            </CandidateAuthGuard>
          }
        />
        <Route
          path="/incognito-preview/:userUid"
          element={
            <CandidateAuthGuard>
              <RecruiterCandidatePagePreview />
            </CandidateAuthGuard>
          }
        />
        {/* Employer routes */}
        <Route
          path="/HomeRecruiter"
          element={
            <EmployerAuthGuard>
              <HomeRecruiter />
            </EmployerAuthGuard>
          }
        />
        <Route
          path="/JobBoardRecruiter"
          element={
            <EmployerAuthGuard>
              <JobBoardRecruiter />
            </EmployerAuthGuard>
          }
        />
        <Route
          path="/createJob"
          element={
            <EmployerAuthGuard>
              <CreateJob />
            </EmployerAuthGuard>
          }
        />
        <Route
          path="/jobcardpage/:id"
          element={
            <EmployerAuthGuard>
              <JobPage />
            </EmployerAuthGuard>
          }
        />
        <Route
          path="/joboverview/:id"
          element={
            <EmployerAuthGuard>
              <JobOverview />
            </EmployerAuthGuard>
          }
        />
        <Route
          path="/RecruiterProfile"
          element={
            <EmployerAuthGuard>
              <RecruiterProfile />
            </EmployerAuthGuard>
          }
        />
        <Route
          path="/candidate-board"
          element={
            <EmployerAuthGuard>
              <RecruiterCandidatesPage />
            </EmployerAuthGuard>
          }
        />
        <Route
          path="/candidate-preview/:userUid/:jobUid?"
          element={
            <EmployerAuthGuard>
              <RecruiterCandidatePagePreview />
            </EmployerAuthGuard>
          }
        />

        {/* Unused routes */}
        <Route
          path="/HomeCandidate"
          element={
            <CandidateAuthGuard>
              <HomeCandidate />
            </CandidateAuthGuard>
          }
        />
      </Routes>

      <MatchModal show={showMatchModal} handleClose={handleCloseMatchModal} />
      <MessageNotificationModal
        show={showMessageModal}
        handleClose={handleCloseMessageModal}
      />
      <FirmLikeCandidateModal
        show={showFirmLikeModal}
        handleClose={handleCloseFirmLikeModal}
      />
      <RecruiterMatchModal
        show={showRecruiterMatchModal}
        handleClose={handleCloseRecruiterMatchModal}
      />
    </Providers>
  );
};

export default App;
