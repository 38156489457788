import MultiRangeSlider from "multi-range-slider-react";
import { Form } from "react-bootstrap";
import { Controller } from "react-hook-form";
import "react-quill/dist/quill.snow.css"; // Import the styles for the editor
import CreatableSelect from "react-select/creatable";
import {
  belgiumCitiesOptions,
  expertiseOptions,
  languagesOptions,
} from "../../constants/Data.js";

const Content1 = ({ control, errors, register, watch }) => {
  const [jobExperience, jobBudget] = watch(["jobExperience", "jobBudget"]);

  return (
    <div className="flex flex-column  bg-white  rounded-md w-full p-2 ">
      <div className="flex flex-column items-start my-3 px-4">
        <p className="font-bold">Add a New Job</p>
        <p>
          Please specify the details of the open position that will be visible
          to candidate
        </p>
        {/* creation form */}
        <div className="w-100">
          {/* Title */}
          <Form.Group className="flex flex-column w-100">
            <Form.Label className="font-bold">
              Title of the position*
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Job Title"
              className="mb-2"
              {...register("jobTitle")}
            />
            {errors.jobTitle && (
              <p className="text-red-600">{errors.jobTitle.message}</p>
            )}
          </Form.Group>

          {/* City */}
          <div className="flex flex-column w-100">
            <p className="font-bold">City*</p>
            <div className="w-full mb-2">
              <Controller
                name="jobCities"
                control={control}
                render={({ field }) => {
                  const selectedOptions = belgiumCitiesOptions.filter(
                    (cityOption) => field.value.includes(cityOption.value)
                  );
                  return (
                    <CreatableSelect
                      {...field}
                      onChange={(values) => {
                        field.onChange(values.map((value) => value.value));
                      }}
                      value={selectedOptions}
                      isClearable
                      isMulti
                      options={belgiumCitiesOptions}
                      className="basic-multi-select"
                      classNamePrefix="select"
                    />
                  );
                }}
              />
              {errors.jobCities && (
                <p className="text-red-600">{errors.jobCities.message}</p>
              )}
            </div>
          </div>
          {/* Languages */}
          <div className="flex flex-column w-100">
            <p className="font-bold">Required Languages*</p>
            <div className="w-full mb-2">
              <Controller
                name="jobLanguages"
                control={control}
                render={({ field }) => {
                  const selectedOptions = languagesOptions.filter(
                    (languageOption) =>
                      field.value.includes(languageOption.value)
                  );

                  return (
                    <CreatableSelect
                      {...field}
                      onChange={(values) => {
                        field.onChange(values.map((value) => value.value));
                      }}
                      value={selectedOptions}
                      isClearable
                      isMulti
                      options={languagesOptions}
                      className="basic-multi-select"
                      classNamePrefix="select"
                    />
                  );
                }}
              />
              {errors.jobLanguages && (
                <p className="text-red-600">{errors.jobLanguages.message}</p>
              )}
            </div>
          </div>
          {/* Optional languages */}
          <div className="flex flex-column w-100">
            <p className="font-bold">Bonus Languages</p>
            <div className="w-full mb-2">
              <Controller
                name="jobBonusLanguages"
                control={control}
                render={({ field }) => {
                  const selectedOptions = languagesOptions.filter(
                    (languageOption) =>
                      field.value.includes(languageOption.value)
                  );

                  return (
                    <CreatableSelect
                      {...field}
                      onChange={(values) => {
                        field.onChange(values.map((value) => value.value));
                      }}
                      value={selectedOptions}
                      isClearable
                      isMulti
                      options={languagesOptions}
                      className="basic-multi-select"
                      classNamePrefix="select"
                    />
                  );
                }}
              />
            </div>
          </div>
          {/* Expertise */}
          <div>
            <div className=" my-2">
              <p className="font-bold">Expertise*</p>
              <Controller
                name="jobExpertise"
                control={control}
                render={({ field }) => {
                  const selectedOptions = expertiseOptions.filter(
                    (expertiseOption) =>
                      field.value.includes(expertiseOption.value)
                  );

                  return (
                    <CreatableSelect
                      {...field}
                      onChange={(values) => {
                        field.onChange(values.map((value) => value.value));
                      }}
                      value={selectedOptions}
                      isClearable
                      isMulti
                      options={expertiseOptions}
                      className="basic-multi-select"
                      classNamePrefix="select"
                    />
                  );
                }}
              />
              {errors.jobExpertise && (
                <p className="text-red-600">{errors.jobExpertise.message}</p>
              )}
            </div>
          </div>
          {/* required experience */}
          <div>
            <p className="font-bold">Experience*</p>
            <Controller
              name="jobExperience"
              control={control}
              render={({ field: { onChange, value, ...field } }) => {
                const handleChange = (value) => {
                  onChange({ min: value.minValue, max: value.maxValue });
                };

                return (
                  <MultiRangeSlider
                    {...field}
                    onInput={handleChange}
                    min={0}
                    max={7}
                    step={1}
                    label={false}
                    ruler={false}
                    minValue={value.min}
                    maxValue={value.max}
                    className="border-none shadow-none z-0"
                    thumbLeftColor="blue"
                    thumbRightColor="blue"
                    barInnerColor="blue"
                  />
                );
              }}
            />
            <div className="flex items-center justify-between">
              <p>
                Minimum <span className="font-bold">{jobExperience.min}</span>{" "}
                years of experience
              </p>
              <p>
                Maximum <span className="font-bold">{jobExperience.max}</span>{" "}
                years of experience
              </p>
            </div>
          </div>

          {/* Budget */}
          <Form.Group className="flex flex-column w-100 mt-2">
            <p className="font-bold mb-2">Budget*</p>
            <div>
              <Controller
                name="jobBudget"
                control={control}
                render={({ field: { onChange, value } }) => {
                  const handleChange = (value) => {
                    onChange({ min: value.minValue, max: value.maxValue });
                  };

                  return (
                    <MultiRangeSlider
                      onInput={handleChange}
                      min={0}
                      max={250}
                      step={10}
                      stepOnly
                      label={false}
                      ruler={false}
                      minValue={value.min}
                      maxValue={value.max}
                      className="border-none shadow-none z-0"
                      thumbLeftColor="blue"
                      thumbRightColor="blue"
                      barInnerColor="blue"
                    />
                  );
                }}
              />
              <div className="flex items-center justify-between">
                <p className="text-xs">
                  Minimum Budget{" "}
                  <span className="font-bold">{jobBudget.min} K€</span>
                </p>
                <p className="text-xs">
                  Maximum Budget{" "}
                  <span className="font-bold">{jobBudget.max} K€</span>
                </p>
              </div>
            </div>
          </Form.Group>
        </div>
      </div>
    </div>
  );
};

export default Content1;
