import { Controller } from "react-hook-form";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, false] }],
    ["bold", "italic", "underline", "strike"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image"],
    ["clean"],
  ],
};

const JobDescriptionStep = ({ control, errors, onPrev }) => {
  const handlePrevStepClick = () => {
    onPrev();
  };

  return (
    <div className="w-100 h-full">
      <p className="font-bold">Job Description</p>
      {/* the rest of the form */}
      <div className="w-100">
        {/* Description */}
        <div className="flex flex-column w-100">
          <label className="my-2">
            Please provide a job description that will be useful for the
            candidates
          </label>
          <Controller
            name="jobDescription"
            control={control}
            render={({ field }) => {
              return (
                <ReactQuill
                  {...field}
                  modules={modules}
                  placeholder="Write a description of the job which might help the candidates decide whether they are the right fit"
                />
              );
            }}
          />
          {errors.jobDescription && (
            <p className="text-red-400">{errors.jobDescription.message}</p>
          )}
        </div>

        <br />
        {/* Additional Description */}
        <p className="font-bold mb-2">Job Description</p>

        <div className="flex flex-column w-100">
          <Controller
            name="jobAdditionalDescription"
            control={control}
            render={({ field }) => {
              return (
                <ReactQuill
                  {...field}
                  modules={modules}
                  placeholder="Write an additional description of the job which might help the candidates decide whether they are the right fit"
                />
              );
            }}
          />
          {errors.jobAdditionalDescription && (
            <p className="text-red-400">
              {errors.jobAdditionalDescription.message}
            </p>
          )}
        </div>
      </div>
      {/* button */}
      <div className="flex items-center justify-between w-100 my-3">
        <button
          className="  py-2 rounded-md font-bold  w-[20%] border-1  cursor-pointer "
          onClick={handlePrevStepClick}
        >
          Back
        </button>
        <p className=" text-center">2/2</p>
        <button
          type="submit"
          className="bg-[#868BFF]  py-2 rounded-md font-bold  w-[20%] border-1 border-[#868BFF] cursor-pointer hover:bg-transparent"
          // onClick={handleFinish}
          //   onClick={handleNextStepClick}
        >
          Finish
        </button>
      </div>
    </div>
  );
};

export default JobDescriptionStep;
